import axios from "axios";
import React from "react";
import Dispatcher from '../../../api/Dispatcher';
import ClientEndpoint from './ClientEndpoint';
import KeycloakAPI from './KeycloakAPI';
import CookieNotification from "../CookieNotification/CookieNotification";
import Dialog from "../Dialog/Dialog";
import { TranslatedTexts } from "../Locale/TranslatedTexts";
// import TranslatedComponent from "../Locale/TranslatedComponent";
// const Mousetrap = require('mousetrap');

/*const _kc = new Keycloak({
    "realm": "225d8806", // TODO: Replace with name of configuration
    "auth-server-url": "https://keycloak.petricore.systems/auth",
    "ssl-required": "external",
    "resource": "exhibio",
    "public-client": true,
    "confidential-port": 0,
    "url": "https://keycloak.petricore.systems/auth",
    "clientId": "exhibio",
    //"enable-cors": true, // War mal an
});*/

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

var onConnectedObservers = [];

const SessionData = {
    clientInstance: null,
    currentUser: null,
    realmHash: null,
    isLoggedIn: () => {
        return SessionData.clientInstance != null && SessionData.clientInstance.state.info != null
    },
    isLoggedOut: () => {
        return SessionData.clientInstance != null && SessionData.clientInstance.state.info == null
    },
    login: (user, password) => {        
        return new Promise((resolve, reject) => {
            if (SessionData.isLoggedOut()) {
                if(SessionData.clientInstance != null){
                    if(SessionData.clientInstance.login != null && typeof SessionData.clientInstance.login === 'function'){
                        SessionData.clientInstance.login(user, password).then((res) => {
                            resolve(res)
                        }).catch((err) => {
                            reject(err)
                        });
                    }else{
                        reject("No proper login-function available in clientInstance.");
                    }
                }else{
                    reject("No clientInstance available.");
                }
            } else {
                reject("Already logged in or no session available.");
            }
        })
    },
    getRoles: () => {
        if (SessionData.isLoggedIn()) {
            if (SessionData.clientInstance.state.info.realmroles != null) {
                return SessionData.clientInstance.state.info.realmroles;
            } else {
                return [];
            }
        }
    },
    doLogout: () => {
        return new Promise((resolve, reject) => {
            if (SessionData.isLoggedIn()) {
                SessionData.clientInstance.logout().then((res) => {                    
                    SessionData.clientInstance = null;
                    SessionData.currentUser = null;   
                    SessionData.init(null);
                    resolve()
                });
            } else {
                resolve()
            }
        })
    },

    addUsernameChangeListener: (func) => {
        if (SessionData.clientInstance.state.changeListeners.indexOf(func) == -1)
        SessionData.clientInstance.state.changeListeners.push(func);
    },

    getUsername: () => {
        if (SessionData.isLoggedIn()) {
            return SessionData.clientInstance.state.info.preferred_username;
        }
        return null;
    },

    getUserID: () => {
        if (SessionData.isLoggedIn()) {
            return SessionData.clientInstance.state.info.sub;
        }
        return "";
    },

    init: (realm) => {
        if (realm != null)
            SessionData.realmHash = realm
        else 
        {
            realm = SessionData.realmHash;
        }
        //Most likely not needed anymore!
        // Dispatcher.on('keycloak_auth_update_request', function (payload) { 
        //     if (SessionData.clientInstance != null && SessionData.clientInstance.state.info != null) {
        //         AdminData.getUserData(SessionData.clientInstance.state.info.sub, (user) => {
        //             // window.FireUIEvent("keycloak_auth_update_response", {
        //             //     Username: SessionData.getUsername(),
        //             //     Roles: SessionData.getRoles(),
        //             //     FirstName: user.firstName,
        //             //     LastName: user.lastName,
        //             //     UserID: SessionData.clientInstance.state.info.sub, // Sendet Clientinformationen an Unity
        //             //     LoginTime: new Date().getHours().toString(),
        //             // });
        //         })
        //     }
        // });

        const self = this;

        return new Promise((resolve, reject) => {
            var client = new ClientEndpoint.Endpoint(realm, 'exhibio', 'testuser', 'password')
            client.construct(realm, 'exhibio', 'testuser', 'password')
                .then((res) => {
                    SessionData.clientInstance = res;
                    resolve(self);
                }).catch((res) => {
                    SessionData.clientInstance = res;
                    resolve(self);
                })
        })
    }
}

const AdminData = {
    adminInstance: null,
    isLoggedIn: () => {
        return AdminData.adminInstance != null && AdminData.adminInstance.state.auth != null
    },
    isLoggedOut: () => {
        return AdminData.adminInstance != null && AdminData.adminInstance.state.auth == null
    },
    login: () => {
        
        return new Promise((resolve, reject) => {
            // This seems to have caused the bug.
            //if (AdminData.isLoggedOut()) {
                AdminData.adminInstance.login().then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                });
            //} else {
            //    resolve("Already admin-logged in or no session available.");
            //}
        })
    },

    init: (realm) => {        
        CookieNotification.registerCookieCategory(
            {
                en: "Account management cookies",
                de: "Account management cookies"
            },
            {
                en: "Stay logged in on future visits.",
                de: "Eingeloggt bleiben.",
            },
            "keycloak",
            false,
            false,
            [{
                en: CookieNotification.makeCookie("refresh_token", null, "1 hour", "Reauthentication cookie"),
                de: CookieNotification.makeCookie("refresh_token", null, "1 Stunde", "Authentifizierungs-Cookie")
            }]
        )

        var client = new ClientEndpoint.Endpoint(realm, 'admin-cli', 'iClyVKSQOePiPHxie9awvGO8vWNyqNa1')
        client.construct(realm, 'admin-cli', 'iClyVKSQOePiPHxie9awvGO8vWNyqNa1')
            .then((res) => {
                AdminData.adminInstance = res;
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has('verify')) {
                    let userId = urlParams.get('verify');
                    let sub = userId.substring(0, 5);
                    if (sub == "RESET") {
                        userId = userId.substring(5);
                   }
                    AdminData.getUserData(userId, (user) => {
                        user.emailVerified = true
                        if (user.attributes == null) {
                            user.attributes = []
                        }
                     
                       user.attributes['sentresetemail'] = "false"
                       user.requiredActions = []
                       AdminData.postUserData(user, userId)
                    })
                }
            })
            .catch((err) => {
                //console.error(err)
            })

            //Most likely not needed anymore!
            // Dispatcher.on("keycloak_get_users_request", function (payload){
            //     AdminData.getUserData("", (response) => {
            //         // window.FireUIEvent("keycloak_get_users_response", {
            //         //     users: response
            //         // });
            //     })
            // })
    },

    getCurrentUser: (overwrite) => {
        if (SessionData.currentUser != null && !overwrite) {
            return new Promise((resolve, reject) => { resolve(SessionData.currentUser) })
        }
        return new Promise((resolve, reject) => {
            AdminData.getUserData(SessionData.clientInstance.state.info.sub, function (user) {
                SessionData.currentUser = user;
                if (SessionData.currentUser.attributes == null){
                    SessionData.currentUser.attributes = {}
                }
                if (SessionData.currentUser.groups == null){
                    SessionData.currentUser.groups = []
                }                
                resolve(user);
            })
        })
    },
    
    getAttribute: (data, key) => {
        return data.attributes[key]
    },
    setAttribute: (key, value) => {
        AdminData.getCurrentUser().then((user) => {
            user.attributes[key] = value
        })
    },
    addToGroup: (rolename) => {
        AdminData.getCurrentUser().then((user) => {
            user.groups.push(rolename);
        })
    },
    addToAttribute: (key, value) => {
        AdminData.getCurrentUser().then((user) => {
            user.attributes[key].push(value);
        })
    },
    removeFromAttribute: (key, value) => {
        AdminData.getCurrentUser().then((user) => {
            user.attributes[key].splice(user.attributes[key].indexOf(value), 1);
        })
    },
    getUserData: (user, callback, query) => {
        if (query == null) {
            query = ""
        }
        AdminData.login().then (() => {
            KeycloakAPI.ToAdminConsole(
                AdminData.adminInstance.state.realm,
                ClientEndpoint.EP_ADMIN.users + "/" + user + query,
                'GET',
                [], {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token
                }
            ).then(function (response) {
                if (response.length != null && response.length > 0) {
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].attributes == null || response[i].attributes.length == 0) {
                            response[i].attributes = {
                                scenebundles: [
                                    /*'2d500d42aa1144088c6603f5758fa1bc',
                                    '2d400d42aa1144088c6603f5758fa1bc',
                                    '2d300d42aa1144088c6603f5758fa1bc',
                                    '2d200d42aa1144088c6603f5758fa1bc',
                                    '2d100d42aa1144088c6603f5758fa1bc',*/
                                ]
                            }
                        }
                    }
                } else {
                    if (response.attributes == null || response.attributes.length == 0) {
                        response.attributes = {
                            scenebundles: [
                                /*'2d500d42aa1144088c6603f5758fa1bc',
                                '2d400d42aa1144088c6603f5758fa1bc',
                                '2d300d42aa1144088c6603f5758fa1bc',
                                '2d200d42aa1144088c6603f5758fa1bc',
                                '2d100d42aa1144088c6603f5758fa1bc',*/
                            ]
                        }
                    }
                }
                callback(response);
            }).catch((e) => {
                console.error(e);
            })
        }).catch((res) => {
            console.error(res);
        })
    },
    postUserData: (user, id) => {
        let skipGet = id != null
        if (id == null) {
            id = SessionData.clientInstance.state.info.sub
        }
        return new Promise((resolve, reject) => {
            AdminData.login().then (() => {
                axios
                    .put(`${KeycloakAPI.rootURL}/admin/realms/${AdminData.adminInstance.state.realm}/users/` + id,
                        user
                        , {
                            headers: {
                                'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token
                            }
                        }).then(() => {
                            if (skipGet) {
                                resolve()
                            } else {
                                AdminData.getCurrentUser(true).then((a) => {
                                    resolve()
                                })
                            }
                        })
                    })
        })
    },
    
    resetUserPassword: (user, password, callback) => {
        AdminData.login().then (() => {
            axios
                .put(`${KeycloakAPI.rootURL}/admin/realms/${AdminData.adminInstance.state.realm}/users/` + user + "/reset-password",
                    {
                        value: password,
                        type: "password",
                        temporary: false,
                    }
                    , {
                        headers: {
                            //'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token
                        }
                    }
            ).then(function (response) {
                callback(response.success);
            }).catch((e) => {
                console.error(e);
            })
        }).catch((res) => {
            console.error(res);
        })
    },

    createNewUser: (username, password, email, Username, UserLocale,UserRole, enabled, CompanyGuid, CompanyName, loginUser = false, verified = false) => {        
        return new Promise((resolve, reject) => {

            if (enabled == null) {
                enabled = true;
            }

            if (verified == null) {
                verified = true;
            }
            
            const user = {
                credentials: [
                    {
                        type: "password",
                        value: password,
                        temporary: true
                    }
                ],
                username: username,
                email: email,
                enabled: enabled,
                emailVerified: verified,
                firstName: Username,                
            
                attributes: {"Company": CompanyName, "CompanyID": CompanyGuid, "locale": UserLocale, "UserRole": UserRole}
            }
    
            AdminData.login().then (() => {
                axios
                    .post(`${KeycloakAPI.rootURL}/admin/realms/${AdminData.adminInstance.state.realm}/users`,
                        user
                        , {
                            headers: {
                                'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token
                            }
                        }).then((resp) => {
                            if(resp.statusText === "Created"){
                                if(loginUser){
                                    AdminData.getUserByUsername(username)
                                    .then((user) =>{
                                        if(user != null){
                                            AdminData.sendMail(user.id)
                                            .then((reps) => {})
                                            .catch((err) => {                                                 
                                                Dialog.Alert({
                                                    title: TranslatedTexts.LoadError,
                                                    description: "Konnte keine EMail-Benachrichtigung senden.",
                                                    options: [
                                                        {label: "OK", className:""},
                                                    ],
                                                    panelOptions: {className: "messebau oneButtonDialog"}
                                                })
                                                });
                                        }else{
                                            console.error("Created user could not be accessed");
                                            reject(resp);
                                        }
                                    })
                                }else{
                                    AdminData.getUserByUsername(username)
                                    .then((user) =>{
                                        if(user != null){
                                            AdminData.sendMail(user.id, "VERIFY_EMAIL")
                                            .then((reps) => {
                                                resolve(user);
                                            })
                                            .catch((err) => {                                                
                                                Dialog.Alert({
                                                    title: TranslatedTexts.LoadError,
                                                    description: TranslatedTexts.EMailError,
                                                    options: [
                                                        {label: "OK", className:""},
                                                    ],
                                                    panelOptions: {className: "messebau oneButtonDialog"}
                                                })
                                                resolve(user);
                                                });                                            
                                        }else{
                                            console.error("User has no valid values.");
                                            reject(user);
                                        }
                                    })
                                    .catch((err)=>{
                                        console.error("Name of User could not be accessed.\n" + err);
                                        reject(err);
                                    })
                                }
                            }else{
                                console.error("User could not be created.\n" + resp)
                                reject(resp);
                            }
                        }).catch((err) => {
                            reject(err)
                        })
                    })
        })
    },
    deleteUser: (keycloakUserID) => {
        return new Promise((resolve, reject) => {
            AdminData.login().then (() => {
            axios
                .delete(`${KeycloakAPI.rootURL}/admin/realms/${AdminData.adminInstance.state.realm}/users/${keycloakUserID}`,
                {headers: {'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token}}
                    ).then((res) => {
                        resolve(res);
                    }).catch((err) => {
                        reject(err);
                    })
            })
        })
    },
    sendMail: (keycloakUserID, action) => {
        if(action == null){
            action = "";
        }

        const body = [action];

        return new Promise((resolve, reject) => {
            if(!keycloakUserID){
                reject("No UserID!");
            }            
            AdminData.login()
            .then ((res) => {
                axios
                .put(`${KeycloakAPI.rootURL}/admin/realms/${AdminData.adminInstance.state.realm}/users/${keycloakUserID}/execute-actions-email`, 
                body,
                    { 
                        headers: { 'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token}
                    }).then((resp) => {
                        resolve(resp);
                    }).catch((err) => {
                        console.log(err)
                        reject(err);
                    })
                })
            .catch((err) => {
                console.log("Could not access admin data. Send mail failed.\n" + err)
                reject(err);
            })
        })
    },
  subscribe: (f) => {
    onConnectedObservers.push(f);
  },
  getUserByUsername(userName){
    let url =  `${KeycloakAPI.rootURL}/admin/realms/${AdminData.adminInstance.state.realm}/users?search=${encodeURIComponent(userName)}`;
    return new Promise((resolve, reject) =>{
        axios
            .get(url,
                {headers: {'Authorization': 'Bearer ' + AdminData.adminInstance.state.auth.access_token}})
            .then((resp) => {
                if(resp.data){
                    let data = null
                    resp.data.forEach(userData => {
                        if(userData.username === userName){
                            data = userData
                        }
                    });
                    resolve(data);
                }else{
                    console.error(resp);
                    reject();
                }
            })
            .catch((err) => {
                console.error(err);
                reject();
            })
        })
    }
}

export default {
    SessionData,
    AdminData,
}
