import React from 'react';
import {Translate} from '../Locale/TranslatedComponent';
import CloseIcon from '../../images/close_icon.png'
import PlayIcon from '../../images/play_icon.png'
import PauseIcon from '../../images/pause_icon.png'
import ZoomInIcon from '../../images/close_icon.png'
import ZoomOutIcon from '../../images/close_icon.png'
import MoveIcon from '../../images/close_icon.png'
import RotateIcon from '../../images/close_icon.png'
import CopyIcon from '../../images/copyLink_icon.png'
import RefreshIcon from '../../images/refresh_icon.png'
import DeleteIcon from '../../images/delete_icon.png'
import QRDownloadIcon from '../../images/qrDownload_icon.png';
import {QRCodeSVG} from 'qrcode.react';
import './ModelviewerPopup.less'
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import { Uploader } from '../Uploader/Uploader';

class ModelviewerPopup extends React.Component {

    modelviewerIframe = null;
    modelviewerBody = null;
    animationProgressChecker = null;

    static defaultProps = {
        onClose: function(){},
        modelviewerModel: {},
        canEdit: false,
        downloadQRCode: function(){},
        copyLink: function(){},
        onModelUpdate: function(){},
    };

    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            modelviewerUrl: "",
            hasAnimation: false,
            progressBarWidth: 0,
            viewerBackgroundColors: ["#eeeeee","#cacacb","#3d3e3f"]
        };
    }

    componentDidUpdate(prevProps){
        if (this.props.modelviewerModel !== prevProps.modelviewerModel) {
            let link = this.getLink();
            this.setState({modelviewerUrl: link}, () => this.updateModelviewer())
          }
    }

    getLink(){
        let model = this.props.modelviewerModel
        return model ? this.props.companyID ? this.generateUserLink(this.props.companyID) : this.generateModelLink(model.ItemID) : ""
    }

    generateUserLink(id){
        if(id){
            if (window.config.host.cdn.includes("vithrough"))
          return "https://viewer.vithrough.com/?userID=" + id;
        else
          return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?userID=" + id;
        }else{
          return null
        }
    }

    generateModelLink(id){
        if(id){
            if (window.config.host.cdn.includes("vithrough"))
          return "https://viewer.vithrough.com/?id=" + id;
        else
          return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?id=" + id;
        }else{
          return null
        }
    }

    closePopup(e){
        e.preventDefault();
        this.props.onClose();
        e.stopPropagation();
    }

    formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    updateModelDataCheckbox(e, param){
        if(e.target){
            this.updateModelData(e.target.checked ? 1 : 0, param);
        }
    }

    updateModelData(value, param){
        this.props.modelviewerModel[param] = value;
        let model = this.props.modelviewerModel;
        Uploader.updateJobParameter(model.ItemID, model.Animate, model.Position, model.BackgroundColor).then(() => {
            this.updateModelviewer();
            this.props.onModelUpdate(model.ItemID);
        })
    }

    updateModelviewer(){
        this.modelviewerIframe = document.getElementById("modelviewer_iframe");        
        this.modelviewerBody = this.modelviewerIframe ? this.modelviewerIframe.contentWindow : null;        
        let model = this.props.modelviewerModel;
        
        if(this.modelviewerBody){            
            try{                
                this.modelviewerBody.loadModel(model.ItemID); //TODO: this causes a cross-site error in localhost! Currently no time to fix this, but should be fixed later!                
                // this.setState({hasAnimation: this.modelviewerBody.hasAnimation ? this.modelviewerBody.hasAnimation() : false}, this.togglePlaying());
                // console.log("INIT end")
            }catch{
                //console.error("Function call in localhost not possible due to cross-side issues")
                this.setState({hasAnimation: false});
            }
        }
    }

    checkModelAnimationProgress(){
        let duration = this.modelviewerBody.getDuration();        
        let currentTime = this.modelviewerBody.getCurrentTime();    
        let percent = duration/currentTime;        
        this.setState({progressBarWidth: percent})
    }

    togglePlaying(){
        try{
            if(this.modelviewerBody && this.state.hasAnimation){
                if(this.modelviewerBody.getPaused()){
                    this.modelviewerBody.togglePlayAnimation(true);
                    this.animationProgressChecker = setInterval(() => {
                        this.checkModelAnimationProgress();
                      }, 1000);
                }else{
                    clearInterval(this.animationProgressChecker)
                    this.animationProgressChecker = null;
                    this.modelviewerBody.togglePlayAnimation(false);
                }
            }
        }catch{
            //console.error("Function call in localhost not possible due to cross-side issues")
            this.setState({hasAnimation: false});
        }
    }

    render() {
        if(this.props.modelviewerModel && this.state.modelviewerUrl){
            const model = this.props.modelviewerModel;
            const modelUrl = this.state.modelviewerUrl;
            const bColors = this.state.viewerBackgroundColors;

            return (
                <div className="modelviewer_popup">
                    <div className='modelviewer_popup_overlay' >
                        <div className='modelviewer_popup_container'>
                            <div className='modelviewer_popup_header'>
                                <div className='modelviewer_popup_title'>
                                    <div className='user_list_circle'/>
                                    <label className='bold'>{model.Description}</label>
                                </div>
                                <button onClick={(e) => this.closePopup(e)}>
                                    <img src={CloseIcon}/>
                                </button>
                            </div>
                            <div className='modelviewer_popup_body'>
                                <div className='modelviewer_popup_model'>
                                    <div className='modelviewer_popup_model_body'>
                                        <iframe id='modelviewer_iframe' src={modelUrl.replace("modelviewer","modelviewer_preview").replace("viewer.vithrough.com/", "portal.vithrough.com/modelviewer_preview/")}></iframe>
                                    </div>
                                    <div className='modelviewer_popup_model_colorpicker'>
                                        <button style={{backgroundColor: bColors[0], borderColor: model.BackgroundColor == bColors[0] ? "#638484" : "transparent"}} onClick={() => this.updateModelData(bColors[0],"BackgroundColor")}></button>
                                        <button style={{backgroundColor: bColors[1], borderColor: model.BackgroundColor == bColors[1] ? "#638484" : "transparent"}} onClick={() => this.updateModelData(bColors[1],"BackgroundColor")}></button>
                                        <button style={{backgroundColor: bColors[2], borderColor: model.BackgroundColor == bColors[2] ? "#638484" : "transparent"}} onClick={() => this.updateModelData(bColors[2],"BackgroundColor")}></button>
                                    </div>
                                    {this.state.hasAnimation && false && 
                                    <div className='modelviewer_popup_model_footer'>
                                        <button onClick={() => this.togglePlaying()}><img src={this.state.playing ? PauseIcon : PlayIcon}/></button>
                                        <div id="progressbar">
                                            <div id="progressbar_bar" style={{width: this.state.progressBarWidth + "%"}}></div>
                                        </div>
                                    </div>}
                                </div>
                                <div className='modelviewer_popup_details'>
                                    <div className='modelviewer_popup_details_element'>
                                        <label className='bold'>Name</label>
                                        <label>{model.Description}</label>
                                    </div>
                                    <div className='modelviewer_popup_details_element'>
                                        <label className='bold'>{TranslatedTexts.ModelSettingsSize}</label>
                                        <label>{TranslatedTexts.ModelSettingsSizeRaw + this.formatBytes(model.RawSize)}</label>
                                        <label>{TranslatedTexts.ModelSettingsSizeGltf + this.formatBytes(model.GltfSize)}</label>
                                        <label>{TranslatedTexts.ModelSettingsSizeUsdz + this.formatBytes(model.UsdzSize)}</label>
                                    </div>
                                    <div className='modelviewer_popup_details_element'>
                                        <label className='bold'>{TranslatedTexts.ModelSettingsLastChange}</label>
                                        <label>{model.Updated}</label>
                                    </div>
                                    <div className='modelviewer_popup_details_element'>                                        
                                        <label className='bold'>{TranslatedTexts.ModelSettingsPlacement}</label>                                                                  
                                    </div>
                                    <div className='modelviewer_popup_details_element'>
                                        <div className='modelviewer_popup_details_subelement'>
                                            <div className='modelposition_container'>
                                                <input disabled={!this.props.canEdit} id="modelposition" type='checkbox' className='hidden' checked={model.Position} onChange={(e) => this.updateModelDataCheckbox(e, "Position")}/>
                                                <label className='inputLabel_left' htmlFor="modelposition">{TranslatedTexts.ModelSettingsPlacementWall}</label>
                                            </div>
                                            <div>
                                                <input disabled={!this.props.canEdit} id="modelposition" type='checkbox' className='hidden' checked={!model.Position} onChange={(e) => this.updateModelDataCheckbox(e, "Position")}/>
                                                <label className='inputLabel_right' htmlFor="modelposition">{TranslatedTexts.ModelSettingsPlacementFloor}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='modelviewer_popup_details_element_horizontal'>
                                        <label className='bold'>{TranslatedTexts.ModelSettingsAnimation}</label>
                                        <label className="switch">
                                            <input disabled={!this.props.canEdit} type="checkbox"></input>
                                            <span className="slider round"></span>
                                        </label>
                                        {
                                            /*
                                            <label>{TranslatedTexts.ModelSettingsAnimationPlaying}</label>
                                        <input type='checkbox' checked={model.Animate} onChange={(e) => this.updateModelData(e, "Animate")}></input>
                                            */
                                        }
                                        
                                    </div>
                                    <div className='modelviewer_popup_details_element'>
                                        <label className='bold'>{TranslatedTexts.ModelSettingsQRCode}</label>
                                        <button title={TranslatedTexts.Download} className='smallButton' onClick={() => this.props.downloadQRCode(this.state.modelviewerUrl,model.FileName+"_qr.png")}><img src={QRDownloadIcon}/></button>
                                        <div className='qr_code_container'>
                                            <QRCodeSVG className='qr_code' value={this.state.modelviewerUrl} />
                                        </div>
                                        <label className='bold'>{TranslatedTexts.ModelSettingsLink}</label>
                                        <div className='modelviewer_popup_details_element_horizontal'>
                                            <button title={TranslatedTexts.ModelLinkTitle} className='smallButton' onClick={() => this.props.copyLink(this.state.modelviewerUrl)}><img src={CopyIcon}/></button>
                                            {/* <button className='smallButton'><img src={RefreshIcon}/></button> NOTE: currently not needed*/}                                            
                                        </div>
                                    </div>
                                    {/* <div className='modelviewer_popup_details_element' id='row'> ---> no longer handeled in the popup itself
                                        <button className='armodelviewer_button'>{TranslatedTexts.Save}</button>
                                        <button><img src={DeleteIcon}/></button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else{
            return null;
        }
    }
}

export default ModelviewerPopup;
