
import React from 'react';
import '../MainPage/MainPage.less'
import './PrivacyInformation.less'
import { TranslatedTexts } from '../Locale/TranslatedTexts';
class PrivacyInformation extends React.Component {
    render() {
        return (
            <div className='main_page privacyInformation'>
                <div className='user_files_container'>
                    <div className='user_files_list_header'>
                        <div className='user_files_list_header_left'>                        
                            <label id='Models'>{TranslatedTexts.DataSecurityTitle}</label>                      
                        </div>
                    </div>
                    <div className='user_files_list_container'>
                        <div className='user_files_list'>    
                            <p>{TranslatedTexts.DataSecurityText}</p>
                            <br/>
                            <h3>{TranslatedTexts.DataSecurityResponsibilityTitle}</h3>
                            <br/>
                            <p>{TranslatedTexts.DataSecurityResponsibilityText}</p>
                            <br/>
                            <h3>{TranslatedTexts.DataSecurityRightsTitle}</h3>    
                            <br/>
                            <p>{TranslatedTexts.DataSecurityRightsText}</p>
                            <br/>
                            <h3>{TranslatedTexts.DataSecurityProcessingTitle}</h3>    
                            <br/>
                            <p>{TranslatedTexts.DataSecurityProcessingText}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}
export default PrivacyInformation;
