import React from 'react';
import './ProfilePage.less';
import CloseIcon from '../../images/close_icon.png'
import BinIcon from '../../images/bin_icon.png'
import PlusIcon from '../../images/+_icon.png'
import AccountIcon from '../../../base/images/account_icon.png';
import Dialog from '../Dialog/Dialog';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import { AdminHandler } from '../AdminHandler/AdminHandler';

class ProfilePage extends React.Component {
    static defaultProps = {
        profilePageActive: false,
        closeProfilePage: function(){},
        handlePasswortReset: function(){},
        remUser: function(){},
        userInfo: null,
        coworkers: null,
        UpdateUserRole: function(newVal, userMail){}
    };

    hasStarted = false;

    constructor(props) {
        super(props);

        this.state = {
            coworkers: this.props.coworkers
        };        
    }

    remUser(idx)
    {
        this.props.remUser(idx);
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.coworkers !== this.props.coworkers) {          
          this.setState({coworkers: this.props.coworkers});
        }                
      }


      
    onSave()
    {        
        Dialog.Alert({
            title: "Produkt löschen",
            description: "Wollen Sie das Produkt mit der ID wirklich löschen?",
            options: [
                {label: "LÖSCHEN", className:"positiveConfirm"},
                {label: "Abbrechen", className:"cancel"},
            ]
        })
    }    

    closePage()
    {        
        this.props.closeProfilePage();
    }

    resetPassword()
    {
        this.props.handlePasswortReset();
    }    

    UpdateUserRole(newVal, userMail)
    {            
      var newUsers = this.state.coworkers;
      newUsers.find((e) => e.UserEmail == userMail).UserRole = newVal;
      this.setState({coworkers: newUsers});      
      AdminHandler.updateUserRole(newVal, userMail)
      .then((res) => {})
      .catch((err) =>{})
    }

    getCoworkerList()
    {        
        if (this.props.coworkers == null) return;
        var myself = this.props.coworkers.find((w) => w.UserEmail == this.props.userInfo[0]);
        let rows = [];
        if (this.props.coworkers == null) return rows;
        this.props.coworkers.map((coworker,index) => {        
            rows.push(
                <div key={index+1}>
                    <div className='company_worker' title={coworker.UserEmail} style={{display: "flex", flexDirection: "Row"}}>
                        <p style={{width: "60%"}} >{coworker.UserName}</p>
                        {
                            myself != null && myself.UserRole == 1 &&
                            <select value={coworker.UserRole} disabled={coworker.UserEmail == myself.UserEmail} onChange={(e) => this.UpdateUserRole(e.target.value, coworker.UserEmail)} type="select" style={{width: "20%"}}>
                                <option value="1">Admin</option>
                                <option value="2">Editor</option>
                                <option value="3">User</option>
                            </select>
                        }
                        {/*<img src={KeyIcon}></img>*/}
                        {
                            myself != null && myself.UserRole == 1 &&
                            <button disabled={coworker.UserEmail == myself.UserEmail} onClick={() => this.remUser(index)}><img src={BinIcon}></img></button>
                        }                                                
                    </div>
                    
                    <div style={{height: "0.1vh", backgroundColor: "white"}}>&nbsp;</div>                            
                </div>);
        }); 
        return rows;       
    }

    render() {
        var myself = (this.state.coworkers != null && this.props.userInfo != null && this.props.userInfo[0] != null) ? this.state.coworkers.find((w) => w.UserEmail == this.props.userInfo[0]) : null;
        var isCompanyAdmin = myself != null ? myself.UserRole < 2 : false;
        return (
            <div className={this.props.profilePageActive?'profilePage profilePageActive' : 'profilePage profilePageInactive'}>
                <div style={{margin: "10vh 3vw"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <img src={AccountIcon}></img>
                        <button title="close" onClick={() => this.closePage()}><img src={CloseIcon}></img></button>
                    </div>
                    <div>
                        <h1>{TranslatedTexts.AccountSettings}</h1>
                    </div>
                    {
                        this.props.userInfo != null &&
                        <div>                        
                        <p className='no_edit'>{this.props.userInfo[1]}</p>
                        <p className='no_edit'>{this.props.userInfo[0]}</p>
                        <p className='no_edit'></p>
                        <p className='no_edit'>{this.props.userInfo[2]}</p>
                        </div>
                    }
                    
                    <div>
                        <button className='passwort_reset' onClick={() => this.resetPassword()} title={TranslatedTexts.ResetPassword}>{TranslatedTexts.ResetPassword}</button>
                    </div>
                    <div style={{height: "0.1vh", backgroundColor: "white", margin: "2em 0"}}>&nbsp;</div>
                    <div>
                        <p className='no_edit'>{TranslatedTexts.Coworkers}</p>
                        <div className='company_list'>
                            <div>
                            {
                                this.getCoworkerList()                                
                            }
                            </div>
                            <div style={{display: "flex", flexDirection: "Row", justifySelf: "end", justifyContent: "flex-start"}}>
                                {
                                    this.props.coworkers != null && isCompanyAdmin &&
                                    <button className='add_worker' title="Add User"><img src={PlusIcon}></img></button>
                                }                                
                            </div>
                        </div>
                    </div>
                    {
                        
                        <div style={{display: "flex", flexDirection: "Row", justifyContent: "flex-end"}}>
                        {
                            //<button className='armodelviewer_button' title="speichen" onClick={() => this.testKeycloakFunctions()}>{TranslatedTexts.Save</button>
                        }
                        </div>
                    }
                </div>
            </div>
        );            
    }
}

export default ProfilePage;
