
import React from 'react';
import '../MainPage/MainPage.less'
import './LegalInformation.less'
import { TranslatedTexts } from '../Locale/TranslatedTexts';
class LegalInformation extends React.Component {
    render() {
        return (
            <div className='main_page legalInformation'>
                <div className='user_files_container'>
                    <div className='user_files_list_header'>
                        <div className='user_files_list_header_left'>                        
                            <label id='Models'>{TranslatedTexts.CorporateInfoTitle}</label>                      
                        </div>
                    </div>
                    <div className='user_files_list_container'>
                        <div className='user_files_list'>   
                            <h3>{TranslatedTexts.CorporateInfoText}</h3> 
                            <br/>
                            <h3>{TranslatedTexts.CorporateInfoResponsibilityTitle}</h3>  
                            <br/>               
                            <p>{TranslatedTexts.CorporateInfoResponsibilityText}</p>
                            <br/>
                            <h3>{TranslatedTexts.CorporateInfoEditorialContentTitle}</h3> 
                            <br/>                
                            <p>{TranslatedTexts.CorporateInfoEditorialContentText}</p>
                            <br/>
                            <h3>{TranslatedTexts.CorporateInfoResolutionTitle}</h3>      
                            <br/>           
                            <p>{TranslatedTexts.CorporateInfoResolutionText}</p>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    };
}
export default LegalInformation;
