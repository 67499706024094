import React, { isValidElement } from 'react';
import { Uploader } from '../Uploader/Uploader';
import FileReqIcon from '../../images/anforderungen_icon.png';
import CloseIcon from '../../images/x_icon.png';
import ScaleIcon from '../../images/x_icon.png';
import BinIcon from '../../images/bin_icon.png';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import Dialog from '../Dialog/Dialog';
import './FilePicker.less'
import ProgressView from '../ProgressView/ProgressView';

class FilePicker extends React.Component {

  uploadChecker = null
  uploadCheckerTimeout = null
  fileUploadRef = null

  static defaultProps = {
      realm: null,
      companyID: null,
      canEdit: false,
      uploadPath: "/Files",
      outputFormat: 'gltf',
      inputFormats: ['fbx', 'obj', 'dae', 'stl', 'glb', 'gltf' , 'zip', 'dxf'],
      minFileSize: 1,
      maxFileSize: 100000000,//500MB
      maxConvertedFileSize: 100000000, //100MB
      minFilenameSize: 4,
      maxFilenameSize: 50,
      allowedFilenameCharacters: /^([a-zA-Z0-9 ._-]+)$/, // allows spaces inside a string and restricts special characters.It Only allows a-z, A-Z, 0-9, Space, Underscore and dash
      onUploadEnd: function(){},
      deleteModel: function(){},      
      spaceLeft: 0,
      timeout: 120000 // ca. 120000 -> 2min
  };

  constructor(props) {
      super(props);

      this.state = {
        dragOver: false,
        selectedFiles: [],
        uploadingFiles: [],
        errorFiles: [],
        outputFormat: this.props.outputFormat, //'gltf','glb'
        uploading: false, //'dropData','uploading','restrictions', 
        progressBarState: 0,
        showingFileReq: false,
        adjustingScale: false        
      };

      this.fileUploadRef = React.createRef();
      this.FilePositionWall = React.createRef();   
      this.FilePositionFloor= React.createRef();   
      this.FileKeepOffsetKeep= React.createRef();   
      this.FileKeepOffsetCenter= React.createRef();   
      this.originalScaleCheckbox= React.createRef();   
  }

  componentDidMount() {
    if (this.uploadChecker == null)
    {
      this.uploadChecker = setInterval(() => {
        this.checkUploadProgress();
      }, 2000);
    }    
    }

  onFileChange(files, e){    
    //NOTE:currently allowing only one file at a time! Later, a system with multiple files is planed    
    if(files && files.length > 1){
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.OnlyOneFileError,
        options: [
            {label: TranslatedTexts.OK, className:"cancel"},
        ]
      })

      return;
    }    
    let validFiles = [];
    if(files && files.length > 0){
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if(this.fileIsValid(file)){
          validFiles.push({"File": file, "KeepOffset":false, "Scale":"1.0", "Position": false, "Animate": false})
        }
      }
    }

    this.setState({ selectedFiles: validFiles, dragOver: false, showingFileReq: false});    
    e.stopPropagation();
  };

  onFileUpload = (e) => {    
    if (this.state.selectedFiles == null || this.state.selectedFiles.length == 0) {
      
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.NoFileError,
        options: [
            {label: TranslatedTexts.OK, className:"cancel"},
        ]
      })
      return;
    }
    else {
        this.setState({uploading: true})
        this.state.selectedFiles.forEach(file => {
            this.handleUploadFile(file, e.target);
        });           
        /*
        this.uploadCheckerTimeout = setTimeout(() => {
          this.uploadTimeout();
        }, this.props.timeout);
        */
    }

    e.stopPropagation();
  };

  handleUploadFile(file, htmlElem){
    const selectedFile = file;
    if(this.props.realm && this.props.companyID && this.fileIsValid(file.File)){ //for security reasons, this is checked on file change and on file upload
      Uploader.addJob(
        this.props.realm,
        this.props.uploadPath, 
        this.props.companyID, 
        this.props.outputFormat,
        selectedFile.File.name.split(".")[0],
        file.KeepOffset ? "1" : "0", 
        file.Scale,
        file.Position ? "1" : "0",
        file.Animate ? "1" : "0")
        .then((resp) => {
          if (this.fileUploadRef != null && this.fileUploadRef.current != null)
          {
            this.fileUploadRef.current.value = null;
            this.setState({selectedFiles: []});
          }
          if (this.uploadChecker == null)
          {
            this.uploadChecker = setInterval(() => {
              this.checkUploadProgress();
            }, 2000);        
          }
          this.checkUploadProgress();
          Uploader.uploadFile(
            this.props.realm,
            selectedFile.File,
            this.props.uploadPath, 
            this.props.companyID,
            resp.ItemID
          );
        })
        .catch((err) => {
          console.log(err)
          Dialog.Alert({
            title: TranslatedTexts.ThereWasAnError,
            description: err,
            options: [
                {label: TranslatedTexts.OK, className:"cancel"},
            ]
          })
          this.state.errorFiles.push({"File" : file, "Response": err, "Success" : false, "Reason": "Upload Failed"})
        })        
    }else{
      this.state.errorFiles.push({"File" : file, "Response": null, "Success" : false, "Reason": "Values Not Valid"})
      htmlElem.dragOver = false;
    }
  }

  uploadTimeout(){
    if(this.uploadChecker){
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.UploadError,
        options: [
            {label: TranslatedTexts.OK, className:"cancel"},
        ]
      })
      this.resetUpload();
    }
  }

  resetUpload(full = false){    
    /*
    
    */        
    if (full)
    {
      clearInterval(this.uploadChecker);
      this.uploadCheckerTimeout = null;    
      this.uploadChecker = null;
      this.setState({uploading: false, errorFiles: [], progressBarState: 0, uploadingFiles: []})
    }
    else
    {
      if (this.fileUploadRef != null && this.fileUploadRef.current != null)
        this.fileUploadRef.current.value = null;
      this.setState({uploading: false, selectedFiles: [], errorFiles: [], progressBarState: 0})
    }
  }

  updateJobProgess(newState)
  {
    if(newState >= 0){
      this.setState({progressBarState: newState})
    }
  }

  checkIfFileFinished(file)
  {    
    let uploaded = [];    
    Uploader.getJobState(file.ItemID,this.props.companyID)
    .then((resp) => {
    if (resp.Job["State"] == 1)
    {
      uploaded.push(resp.Job)      
      uploaded.forEach(file => {
        this.checkConvertedFileSize(file);
      });

      this.props.onUploadEnd(uploaded)
    }else if (resp.Job["State"] < 0)
    {
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.UploadErrorFailedFiles + resp.Job["FileName"] + resp.Job["FileType"],
        options: [
            {label: TranslatedTexts.OK, className:"cancel"},
        ]
      })      
      this.props.onUploadEnd(uploaded)
    }})
    .catch((err) => {})
  }

  checkUploadProgress(){    
    Uploader.getOngoingJobs(this.props.companyID)
    .then((resp) => {
      if(resp && resp.Jobs){
        this.state.uploadingFiles.forEach ((file)=>
        
        {
          if(resp.Jobs.find((f) => f.ItemID == file.ItemID) == null)
          {
            this.checkIfFileFinished(file);
          }
        });
        this.setState({uploadingFiles: resp.Jobs});
      }
      else
      {        
        this.state.uploadingFiles.forEach ((file)=>        
        {          
          this.checkIfFileFinished(file);
          
        });
        this.resetUpload(true);        
      }
    })
    .catch((err) => {
      this.state.uploadingFiles.forEach ((file)=>        
        {          
          this.checkIfFileFinished(file);
          
        });        
        this.resetUpload(true);      
    })
  }

  fileIsValid(file){
    if(!file || !file.name){
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.FileNotValidError,
        options: [
            {label: TranslatedTexts.OK, className:"cancel"},
        ]
      })
      return false;
    }

    //TODO: In order to have the following messages translatable, we should think about a system which takes inputs in the dialog.alert function
    if(this.props.minFileSize != null && this.props.maxFileSize != null){
        if (!this.checkFileSize(file)) {
          Dialog.Alert({
            title: TranslatedTexts.ThereWasAnError,
            description: TranslatedTexts.parse(TranslatedTexts.FileBadError, [file.name, this.formatFileSize(this.props.minFileSize), this.formatFileSize(this.props.maxFileSize)]),
            options: [
                {label: TranslatedTexts.OK},
            ]
          })            
            return false;
        }
    }

    if(this.props.minFilenameSize != null && this.props.maxFileSize != null){
        if(!this.checkFileName(file.name)){
          Dialog.Alert({
            title: TranslatedTexts.ThereWasAnError,
            description: TranslatedTexts.parse(TranslatedTexts.FilenameBadError, [file.name, this.props.minFilenameSize, this.props.maxFilenameSize]),
            options: [
                {label: TranslatedTexts.OK},
            ]
          })            
            return false;
        }
    }
    
    if(!this.checkFileFormat(file.name)){
      Dialog.Alert({
        title: TranslatedTexts.ThereWasAnError,
        description: TranslatedTexts.parse(TranslatedTexts.FileformatBadError, [file.name, this.props.inputFormats]),
        options: [
            {label: TranslatedTexts.OK},
        ]
      })       
      return false;
    }
    
    return true;
  }

formatFileSize(bytes, decimalPoint) {
  if(bytes == 0) return '0 Bytes';
  var k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Byte(s)', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

checkFileFormat(filename){
  let extension = filename.split('.').pop();
  if(this.props.inputFormats.includes(extension)){
    return true;
  }

  return false;
}


checkFileSize(file){
    if(file.size > this.props.maxFileSize || file.size < this.props.minFileSize){
        return false;
    }

    return true;
}

checkConvertedFileSize(file){
  if(file.GltfSize > this.props.maxConvertedFileSize){
    Dialog.Alert({
      title: TranslatedTexts.ThereWasAnError,
      description: file.Description +": "+TranslatedTexts.GltfTooBigError + " (" + this.formatFileSize(this.props.maxConvertedFileSize) + ")",
      options: [
          {label: TranslatedTexts.OK, className:"cancel"},
      ]
    })
  }

  if(file.UsdzSize > this.props.maxConvertedFileSize){
    Dialog.Alert({
      title: TranslatedTexts.ThereWasAnError,
      description: file.Description +": "+TranslatedTexts.UsdzTooBigError + " (" + this.formatFileSize(this.props.maxConvertedFileSize) + ")",
      options: [
          {label: TranslatedTexts.OK, className:"cancel"},
      ]
    })
  }
}

checkFileName(filename){
    if(filename == null || filename == "" || !this.checkFileNamePoints(filename)){
        return false;
    }

    let nameParts = filename.split(".");
    let firstNamePart = nameParts[0];

    if(firstNamePart.length > this.props.maxFilenameSize || firstNamePart.length < this.props.minFilenameSize || !this.checkFilenameCharacters(firstNamePart)){
        return false;
    }

    return true;
}

checkFileNamePoints(filename){
    let filenameParts = filename.split(".");
    if(filenameParts.length > 2){
        return false;
    }

    return true;
}

checkFilenameCharacters(filename){
    if(!this.props.allowedFilenameCharacters.exec(filename)){
        return false;
    }
    return true;
}

  getPendingJobs = (self) => {
    Uploader.getPendingJobs((data) => {      
      self.setState({ itemID: data.data[0].ItemID })
      
      self.getJob(self);

    })
  }

  getJob = (self) => {
    Uploader.getJob(self.state.itemID);
  }

  dropHandler(ev) {
    ev.preventDefault();
    const files = [];
  
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          files.push(item.getAsFile());
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach((file, i) => {
        files.push(file);
      });
    }

    this.onFileChange(files, ev);
  }

  dragOverHandler(ev) {
    if(!this.state.dragOver){
      this.setState({dragOver: true});
    }    
    ev.preventDefault();
    ev.stopPropagation();
  }

  dragEnterHandler(e){
    this.setState({dragOver: true});
    e.preventDefault();
  }

  dragLeaveHandler(e){
    if(this.state.dragOver){      
      this.setState({dragOver: false});
    }    
    e.preventDefault();
    e.stopPropagation();
  }

  updateFileCheckBoxValue(e, idx, property, value){
    const c = Object.assign({}, this.state);
    if(c.selectedFiles[idx]){
      c.selectedFiles[idx][property] = value;      
      this.setState(c);
    }
    e.stopPropagation();
  }

  setFileScaleValue(e, idx, value){    
    const c = Object.assign({}, this.state);
    if(e && e.target && c.selectedFiles[idx]){
      if(e.target.checked){
        //this.setScaleAdjustment(e, false)
        c.selectedFiles[idx]["Scale"] = (value/100);
      }else{
        c.adjustingScale = true;
      }
      this.setState(c);      
    }
    e.stopPropagation();
  }

  updateFileScaleValue(e, idx){
    const c = Object.assign({}, this.state);
    if(e && e.target && c.selectedFiles[idx]){
      c.selectedFiles[idx]["Scale"] = (e.target.value/100);      
      this.setState(c);
    }
    e.stopPropagation();
  }

  showFileRequirements(e){
    e.preventDefault();    
    this.setState({showingFileReq: !this.state.showingFileReq})    
    e.stopPropagation();
  }

  uploadThroughHtmlElement(e){    
    if(this.state.adjustingScale){
      this.setScaleAdjustment(e, false)
    }else if(this.state.selectedFiles.length == 0){      
      this.fileUploadRef.current.click()
      e.stopPropagation();
    }
  }

  setScaleAdjustment(e, active){    
    this.setState({adjustingScale: active})     
  }
  
  getStateDesciption(loadState){
    if(loadState < 0){
      return TranslatedTexts.LoadError;
    }else{
      switch(loadState){ 
        case 0:
          return TranslatedTexts.Uploading;
        case 1:          
          return TranslatedTexts.LoadUploadFinished;
        case 2:
          return TranslatedTexts.LoadQueued;
        case 3:
          return TranslatedTexts.LoadServerGetFiles;
        case 4:
          return TranslatedTexts.LoadConvert;
        case 5:
          return TranslatedTexts.LoadDeploy;
        default:
          return TranslatedTexts.LoadError;
      }
    }
  }

  render() {    
    const filesSelected = this.state.selectedFiles && this.state.selectedFiles.length != 0;
    return (
      <div className='filepicker_main'>
      <div id='file_picker' className='file_picker'>
        <div id='pickerContent' className='pickerContent'>
          <div 
            id='drop_zone'
            className={this.state.dragOver ? "drop_zone_dragOver" : "drop_zone"} 
            onDrop={(e) => this.dropHandler(e)} 
            onDragOver={(e) => this.dragOverHandler(e)} 
            onDragLeave={(e) => this.dragLeaveHandler(e)}
            onDragEnd={(e) => this.dragLeaveHandler(e)}
            onClick={(e) => {this.uploadThroughHtmlElement(e)}}
            >
            <div className='file_requirements'>
              <button className='file_requirements_button' id='file_requirements_button_id' title={TranslatedTexts.FileReqButton} onClick={(e) => this.showFileRequirements(e)}>
                <img alt="" src={this.state.showingFileReq ? CloseIcon : FileReqIcon}/>
                <div className='file_requirements_tooltip'>
                  {TranslatedTexts.FileReqText}
                </div>
              </button>
            </div>
            {!filesSelected && !this.state.showingFileReq && <label id='description' className='description'>{!(this.props.spaceLeft > this.state.uploadingFiles.length) ? TranslatedTexts.ModelLimit1 : TranslatedTexts.FileReq}</label>}
            {!filesSelected && !this.state.showingFileReq && <label id='drop_zone_hint' className='drop_zone_hint'>{!(this.props.spaceLeft > this.state.uploadingFiles.length) ? TranslatedTexts.ModelLimit2: TranslatedTexts.DropFile}</label>}
            {filesSelected && !this.state.showingFileReq && 
                this.state.selectedFiles.map((file, idx) => {                  
                  const selectedFile = file.File;
                  return (
                    <div className='file_data' key={"file_data-" + idx}>
                      <label style={{fontWeight: 'bold', lineHeight: '1.5em', float: 'none', width: '100%', margin: '0 0 1em 0'}}>{TranslatedTexts.FileName}</label>
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                        <div>                        
                        <label style={{fontWeight: 'bold', lineHeight: '1.5em', float: 'none', width: '100%', margin: '0 0 1em 0'}}>{selectedFile.name}</label>                        
                        </div>
                        <div>
                        <button onClick={(e)=> {this.resetUpload();}} style={{marginLeft: '0.5em'}}><img src={BinIcon}/></button>
                        </div>
                      </div>
                      <div className='file_data_list'>
                        <div className='file_data_element'>
                        <div title={TranslatedTexts.FilePosition_Help}><label>{TranslatedTexts.FilePosition_Headline}</label></div>
                          <div style={{display: "flex", flexDirection: "row"}}>
                          <div onClick={() => this.FilePositionWall.current.click()} style={{width: (Math.max(TranslatedTexts.FilePositionWall.length, TranslatedTexts.FilePositionFloor.length)+3)/2 + "em"}}>
                              <label  htmlFor={"FilePositionWall"+idx} className='file_data_element_option'>{TranslatedTexts.FilePositionWall}</label>
                            </div>
                            <div>
                            <input ref={this.FilePositionWall} id={"FilePositionWall"+idx} type="checkbox" checked={file["Position"]} onChange={(e) => this.updateFileCheckBoxValue(e, idx, "Position", e.target.checked)}></input>
                            </div>
                          </div>
                          <div style={{display: "flex", flexDirection: "row"}}>
                          <div onClick={() => this.FilePositionFloor.current.click()} for="FilePositionFloor" style={{width: (Math.max(TranslatedTexts.FilePositionWall.length, TranslatedTexts.FilePositionFloor.length)+3)/2 + "em"}}>
                            <label htmlFor="FilePositionFloor" className='file_data_element_option'>{TranslatedTexts.FilePositionFloor}</label>
                            </div>
                            <div>
                            <input ref={this.FilePositionFloor} id="FilePositionFloor" type="checkbox" checked={!file["Position"]} onChange={(e) => this.updateFileCheckBoxValue(e, idx, "Position", !e.target.checked)}></input>
                            </div>
                          </div>
                        </div>
                        <div className='file_data_element'>
                        <div title={TranslatedTexts.FileKeepOffset_Help}><label>{TranslatedTexts.FileKeepOffset}</label></div>
                        <div style={{display: "flex", flexDirection: "row"}}>
                        <div onClick={() => this.FileKeepOffsetKeep.current.click()} style={{width: (Math.max(TranslatedTexts.FileKeepOffsetCenter.length, TranslatedTexts.FileKeepOffsetKeep.length)+3)/2 + "em"}}>
                            <label htmlFor="FileKeepOffsetKeep" className='file_data_element_option'>{TranslatedTexts.FileKeepOffsetKeep}</label>
                            </div>
                            <div>
                            <input ref={this.FileKeepOffsetKeep} id="FileKeepOffsetKeep" type="checkbox" checked={file["KeepOffset"]||file["Scale"] != 1.0} onChange={(e) => this.updateFileCheckBoxValue(e, idx, "KeepOffset", e.target.checked)}></input>                            
                            </div>
                          </div>
                          <div style={{display: "flex", flexDirection: "row"}}>
                          <div onClick={(e) => this.FileKeepOffsetCenter.current.click()}style={{width: (Math.max(TranslatedTexts.FileKeepOffsetCenter.length, TranslatedTexts.FileKeepOffsetKeep.length)+3)/2 + "em"}}>
                            <label htmlFor="FileKeepOffsetCenter" className='file_data_element_option'>{TranslatedTexts.FileKeepOffsetCenter}</label>
                            </div>
                            <div>
                            <input ref={this.FileKeepOffsetCenter} id="FileKeepOffsetCenter" type="checkbox" checked={!file["KeepOffset"]&&file["Scale"] == 1.0} onChange={(e) => this.updateFileCheckBoxValue(e, idx, "KeepOffset", !e.target.checked)}></input>
                            </div>
                          </div>
                        </div>
                        <div className='file_data_element'>
                          <div title={TranslatedTexts.FileScale_Help}><label>{TranslatedTexts.FileScale}</label></div>
                          <div>
                            <label onClick={(e) => this.originalScaleCheckbox.current.click()} htmlFor="originalScaleCheckbox" className='file_data_element_option'>{TranslatedTexts.FileScaleOriginal}</label>
                            <input ref={this.originalScaleCheckbox} id="originalScaleCheckbox" style={{marginLeft: '7%'}} type="checkbox" value={(file["Scale"] == 1.0)} checked={(file["Scale"] == 1.0)} onClick={(e) => {e.stopPropagation()}} onChange={(e) => this.setFileScaleValue(e, idx, 100)}></input>
                          </div>
                          <div className='flex_data_element_scaling'>
                            <label className='file_data_element_option'>{TranslatedTexts.FileScaleCustom} (%): </label>                            
                            <input  style={{width: '3em'}} type="number" value={Number(file["Scale"]*100).toString()} min="1" step="1" pattern="\d*" onBlur={() => this.setState({adjustingScale: false})} onClick={(e) => {e.stopPropagation();}} onChange={(e) => this.updateFileScaleValue(e, idx)}></input>
                            {
                              //<button onClick={(e) => this.setScaleAdjustment(e, true)}><img src={ScaleIcon}/></button>
                            }
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  )})
            }
            {this.state.showingFileReq && 
              <div className='file_requirements_tooltip' style={{fontSize: '0.9em', lineHeight: '1.0em', padding: '0 1em 2em 1em', fontWeight: 'normal'}}>
                 {TranslatedTexts.FileReqText}
              </div>}
            {this.state.uploading && !this.state.showingFileReq && false &&
              <div id="progressbar">
                <label>{this.getStateDesciption(this.state.progressBarState)}</label>
                <div id="progressbar_bar" style={{width: (this.state.progressBarState/6) * 100 + "%"}}></div>
              </div>}
          {filesSelected ? 
            <button className='upload_button armodelviewer_button upload' disabled={!this.props.canEdit || !(this.props.spaceLeft > this.state.uploadingFiles.length)} title={!(this.props.spaceLeft > this.state.uploadingFiles.length) ? TranslatedTexts.ModelLimit1 :TranslatedTexts.Upload} onClick={(e) => this.onFileUpload.bind(this)(e)}>
              {TranslatedTexts.Upload}
            </button>:
            <button className='upload_button armodelviewer_button upload' disabled={!this.props.canEdit || !(this.props.spaceLeft > this.state.uploadingFiles.length)} title={!(this.props.spaceLeft > this.state.uploadingFiles.length) ? TranslatedTexts.ModelLimit1 : TranslatedTexts.SelectFile} onClick={(e) => {this.uploadThroughHtmlElement(e)}}>
              {TranslatedTexts.SelectFile}
            </button>}
            <input type="file" disabled={!this.props.canEdit || !(this.props.spaceLeft > this.state.uploadingFiles.length)} onChange={(e)=>this.onFileChange(e.target.files, e)} ref={this.fileUploadRef} hidden/>
          </div>
        </div>
        </div>
        {
          this.state.uploadingFiles.length > 0 &&
          <ProgressView
            deleteModel={(itemID) => this.props.deleteModel(itemID)}
            files = {this.state.uploadingFiles}
          />
        }
      </div>
    );
  }
}

export default FilePicker;
