import React from 'react';

class FooterComponents extends React.Component {
    render() {
        return null;
        // return (
        //     <div className={this.props.className}>
        //         <FullscreenFooter title="" buttonText={
        //             <TranslatedComponent translatable = {[["de", "Vergrößern"],["en", "Extend"],]}></TranslatedComponent>}
        //         />
        //     </div>
        // );
    }
}

export default FooterComponents;
