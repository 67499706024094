
export class TranslatedTexts {  
    static Talsand_Contact = "";
    static Talsand_Adress = "";
    static LegalInformation = "";
    static ProtectionOfPrivacy = "";
    static FAQ = "";
    static Product = "";
    static Hover_LangSwitch = "";
    static Language = "";
    static Hover_Profile =  "";
    static LoginFailed = "";
    static Welcome1 = '';
    static Welcome2 = "";
    static Welcome3 = "";
    static Welcome4 = "";
    static Tutorial = ""
    static NoModel = "";
    static EmptySlot = "";
    static MoreSpace = "";
    static OpenFile = "";
    static QRCode = "";
    static ViewQRCode = "";
    static LinkWasCopied = "";
    static FAQ_Headline = "";
    static FAQ_Q1 = "";
    static FAQ_Q2 = "";
    static FAQ_Q3 = "";
    static FAQ_Q4 = "";
    static FAQ_Q5 = "";
    static FAQ_Q6 = "";
    static FAQ_Q7 = "";
    static FAQ_Q8 = "";
    static FAQ_Q9 = "";
    static FAQ_Q10 = "";
    static FAQ_Q11 = "";
    static FAQ_Q12 = "";
    static FAQ_Q13 = "";
    static FAQ_Q14 = "";
    static FAQ_Q15 = "";
    static FAQ_Q16 = "";
    static FAQ_Q17 = "";
    static FAQ_Q18 = "";
    static FAQ_A1 = "";
    static FAQ_A2 = "";
    static FAQ_A3 = "";
    static FAQ_A4 = "";
    static FAQ_A5 = "";
    static FAQ_A6 = "";
    static FAQ_A7 = "";
    static FAQ_A8 = "";
    static FAQ_A9 = "";
    static FAQ_A10 = "";
    static FAQ_A11 = "";
    static FAQ_A12 = "";
    static FAQ_A13 = "";
    static FAQ_A14 = "";
    static FAQ_A15 = "";
    static FAQ_A16 = "";
    static FAQ_A17 = "";
    static FAQ_A18 = "";
    static FAQ_Button = "";
    static ModelLink = "";
    static ModelLinkTitle = "";
    static Uploaded = "";
    static FileName = "";
    static OpenWebsite = "";
    static DownloadImage = "";
    static AllModels = "";
    static AllModelsInfo ="";
    static AllModelsInApp = "";
    static AllModelsOutApp = "";
    static AllModelsInAppHover = "";
    static AllModelsOutAppHover = "";
    static YourModels = "";
    static Companies = "";
    static AddCompany = "";
    static RemCompany = "";
    static RemCompanySure = ""
    static Users = "";
    static AddUser = "";
    static RemUser = "";
    static RemUserSure = "";
    static SpacesTaken = "";
    static Register = "";
    static NotRegistered = "";
    static Login = "";    
    static ForgotPw = "";
    static Password = "";
    static ResetPassword = "";
    static ResetPasswordSure = "";
    static ResetMailMessage = "";
    static Reset = ""
    static BackToLogin = "";        
    static Username = "";
    static Upload = "";
    static Loading = "";
    static SelectFile = "";    
    static FileType = "";
    static DropFile = "";
    static FileReq = "";
    static FileReqText = "";
    static FileReqButton = "";
    static Delete = "";
    static FileKeepOffset = "";
    static FileKeepOffsetKeep = "";
    static FileKeepOffsetCenter = "";
    static FileKeepOffset_Help = "";
    static FileScale = "";
    static FileScaleOriginal = "";
    static FileScaleCustom = "";
    static FileScale_Help = "";
    static FilePosition = "";
    static FilePositionWall = "";
    static FilePositionFloor = "";
    static FilePosition_Headline = "";
    static FilePosition_Help = "";
    static FileAnimate = "";
    static FileAnimateAnimate = "";
    static FileAnimateStatic = "";

    static Back_Button = "";

    static Table_File = "";
    static Table_Model = "";
    static Table_Material = "";
    static Table_Animation = "";
    static Table_Bone = "";
    static Table_LightType = "";
    static Table_LightSource = "";

    static AccountSettings = "";
    static Coworkers = "";
    
    //popup
    static ModelSettingsName = "";
    static ModelSettingsSize = ""    
    static ModelSettingsSizeRaw = ""
    static ModelSettingsSizeGltf = ""
    static ModelSettingsSizeUsdz = ""
    static ModelSettingsLastChange = ""
    static ModelSettingsPlacement = ""
    static ModelSettingsPlacementWall = ""
    static ModelSettingsPlacementFloor = ""
    static ModelSettingsAnimation = ""
    static ModelSettingsAnimationPlaying = ""
    static ModelSettingsQRCode = ""
    static ModelSettingsLink = ""

    //errors
    static ThereWasAnError = ""
    static NoFileError = "" 
    static UploadError = "" 
    static EMailError = ""
    static UploadErrorFailedFiles = "" 
    static FileNotValidError = "" 
    static FileBadError = "The file $[0] does not match the size-guidelines. Minimal file size: $[1]. Maximal file size: $[2]"
    static FilenameBadError = "The filename of $[0] does not match the defined criteria. Minimal file name size: $[1]. Maximal file name size: $[2]. Furthermore, please use only common characters and do not use additional '.' in the file name."
    static FileformatBadError = "The file $[0] does not match the required file format. Please choose a valid format. ($[1])"
    static OnlyOneFileError = ""
    static GltfTooBigError = ""
    static UsdzTooBigError = ""
    static EmailNotFound = ""
    static ModelLimit1 = "";
    static ModelLimit2 = "";    

    //upload    
    static LoadError = ""
    static Uploading = ""
    static LoadQueued = ""
    static LoadUploadFinished = "" 
    static LoadServerGetFiles = ""
    static LoadConvert = ""
    static LoadDeploy = ""
    static WindowCloseWarning = ""
    static WindowCloseAllow = ""
    
    //basic logic
    static Cancel = ""
    static OK = ""
    static Save = ""
    static Close = "";
    static Download ="";
    static SomethingWrong = "";

    //data security
    static DataSecurityTitle = "";
    static DataSecurityText = "";
    static DataSecurityResponsibilityTitle = "";
    static DataSecurityResponsibilityText = "";
    static DataSecurityRightsTitle = "";
    static DataSecurityRightsText = "";
    static DataSecurityProcessingTitle = "";
    static DataSecurityProcessingText = "";

    //corporate information
    static CorporateInfoTitle = "";
    static CorporateInfoText = "";
    static CorporateInfoResponsibilityTitle = "";
    static CorporateInfoResponsibilityText = "";
    static CorporateInfoEditorialContentTitle = "";
    static CorporateInfoEditorialContentText = "";
    static CorporateInfoResolutionTitle = "";
    static CorporateInfoResolutionText = "";

    //LandingPage

    static Header = "";
    static AboutVithroughHeader = "";
    static AboutVithrough = "";
    static WhatIsArHeader = "";
    static WhatIsAr = "";
    static WhatIsVithroughHeader = "";
    static WhatIsVithrough = "";
    static HowTothroughHeader = "";
    static HowTothrough = "";
    static WhyVithroughHeader = "";
    static WhyVithrough = "";
    static BenefitsHeader = "";
    static Benefits1 = "";
    static Benefits2 = "";
    static Benefits3 = "";
    static InterestHeader = "";
    static IntererstC2A = "";
    static InterestHeaderSubmit = "";
    static IntererstC2ASubmit = "";
    static InterestName = "";
    static InterestSurname = "";
    static InterestTel = "";
    static InterestMail = "";
    static InterestConsent = "";
    static InterestSubmit = "";

    static parse (rawstring, args)
    {
        for (var c = 0; c < args. length; c++)
        {
            var replaces = "$["+ c + "]";
            rawstring = rawstring.replace(replaces, args[c]);            
        }
        return rawstring;
    }
}

export class TranslatedTextsEN extends TranslatedTexts {  
    static Talsand_Contact = "Phone: +49 175 5339786 | Mail: contact@vithrough.com";
    static Talsand_Adress = "talsand GmbH & Co. KG | Kesselstraße 3 | 40221 Düsseldorf | Germany";
    static LegalInformation = "Legal Information";
    static ProtectionOfPrivacy = "Protection Of Privacy";
    static FAQ = "FAQ";
    static Product = "A product by";
    static Hover_LangSwitch = "Switch language";
    static Language = "Language";
    static Hover_Profile = "Profile";
    static LoginFailed = "Login failed. Either your input was wrong or this account is inactive."
    static Welcome1 = 'Welcome to Vithrough';
    static Welcome2 = "Here you can prepare your 3D models for augmented reality in just a few minutes. Log in with the access data you received from us, upload your own 3D models and convert and manage them. Share the result with your colleagues and customers directly afterwards.";
    static Welcome3 = "";
    static Welcome4 = "Enjoy!";
    static Tutorial = "Tutorial"
    static NoModel ="You have currently no models";
    static EmptySlot = "Slot for another file";
    static MoreSpace = "Do you need more space? Please contact us.";
    static OpenFile = "Open File";
    static QRCode = "QR-Code";
    static ViewQRCode = "View QR-Code";
    static LinkWasCopied = "Link was copied to clipboard."
    static FAQ_Headline = "FAQ   -   Frequently asked questions and answers";
    static FAQ_Q1 = "Which file formats are supported?";
    static FAQ_Q2 = "What is the maximal file size?";
    static FAQ_Q3 = "Why do I get a warning message even though my file size is below the limit?";
    static FAQ_Q4 = "How do I upload data?";
    static FAQ_Q5 = "What settings do I have when uploading my data?";
    static FAQ_Q6 = "How do I view my models in AR?";
    static FAQ_Q7 = "Who can view my 3D models?";
    static FAQ_Q8 = "How do I edit my models?";
    static FAQ_Q9 = "Do I need a QR code/link for every model, separately?";
    static FAQ_Q10 = "Can I export my own light sources?";
    static FAQ_Q11 = "What should be considered with transparent materials, such as glass?";
    static FAQ_Q12 = "Why can't I see transparent textures?";
    static FAQ_Q13 = "Why does my model take so long to load? (Web version)";
    static FAQ_Q14 = "Do I need internet to show my 3D models in AR?";
    static FAQ_Q15 = "When do I need the app?";
    static FAQ_Q16 = "How can I upload more models?";
    static FAQ_Q17 = "How can I add additional functions, such as the app?";
    static FAQ_Q18 = "I have questions about the bill, who can I contact?";
    static FAQ_A1 = "We recommend the use of .fbx exports for the conversion. However, other data formats are also supported. The following table shows the advantages and disadvantages of the respective file types."
    static FAQ_A2 = "Files may be a maximum of 100Mb in size for upload. \nEven though our AI is working in the background to adapt large models for web preview, you may receive an error message during the conversion process. In this case, the converted formats have become too large to be displayed on the web and could therefore lead to display problems or long loading times.\n" + 
    "Our recommendation is therefore to upload a model version with fewer vertices and to limit the texture images to a maximum edge length of 2048 pixels.\n" +
    "Alternatively, we offer an app that you can use to show your models in AR, e.g. to customers on site. As this solution is not browser-based, it is ideal for larger, more detailed models, such as those used in mechanical engineering.";
    static FAQ_A3 = "Even though our AI is working in the background to adapt large models for web viewing, it can happen in the conversion process that you receive an error message. In this case, the converted formats have become too large to be displayed on the web and could therefore lead to display problems or long loading times.\n" +
    "Our recommendation is therefore to upload a model version with fewer vertices and to limit the texture images to a maximum edge length of 2048 pixels.";
    static FAQ_A4 = "After you have logged in, you will see an area at the top of the page where you can drag and drop your file or select a file using the 'Select file' button.\n" +
    "Important: Only one file can be uploaded at a time. If you have exports with separate texture / material data, such as .OBJ, combine them in a .zip folder.\n" +
    "Now you have various setting options, which are explained in more detail in the next question. Now you are ready to press 'Upload'.";
    static FAQ_A5 = "With our setting options, you can adapt your model to your individual requirements.\n" +
    "\nPosition: You can set whether your 3D model should be placed on the floor or on a wall. Wall placement is suitable for shelves, signs, etc.\n" +
    "\nOffset: If your model’s position is not at the point of origin at 0,0,0, but is deliberately off-centre, then you can keep your individual 'Offset'. This applies for example for floating objects. Selecting 'centre' will place your model centered on the floor.\n" + 
    "\nScaling: Your models are displayed in AR in the same size as they were created in the 3D programme. If you want to view a 5-metre high machine, you will need the corresponding space on site. However, you have the option of scaling your model smaller or larger. Before uploading, simply enter the desired percentage in which your model should be displayed in AR in relation to the original size.";
    static FAQ_A6 = "After uploading, you will receive a link and a QR code. You can copy the link into your browser or scan the QR code with your mobile phone/tablet camera. Now your model will be displayed in the 3D model viewer. On mobile devices (smartphone, tablet) there is a button at the bottom right to start the AR view.";
    static FAQ_A7 = "Only people who have received a link or QR code can view the associated 3D models. Therefore, you have full control over your data. If you delete a model, the associated link is invalid immediately.";
    static FAQ_A8 = "After completing the upload process, a window opens automatically in which you can view and edit the model you have just uploaded. Of course, you also have this option afterwards by selecting your model from the list and clicking the 'Open file' button.\n" +
    "Here you have the option to switch between floor and wall position. If there is an animation on the model, you can also determine whether it should be played in the AR view or not.\n" +
    "Changes are saved automatically.";
    static FAQ_A9 = "No. You can also show all your uploaded models to interested parties with a single link/QR code. To do this, simply click on the 'All models' button on the right hand side under 'My models'.";
    static FAQ_A10 = "Since the model viewer has its own light settings, no imported light sources are considered, with the exception of 'emission lights'.";
    static FAQ_A11 = "Since transparent materials are handled very differently in the numerous 3D programmes and export formats, it is currently necessary that these materials' names contain the word “Glass” before exporting.";
    static FAQ_A12 = "Unfortunately, transparent textures are currently not supported.";
    static FAQ_A13 = "The file size on websites is crucial. Each time a user visits the website, the according model must first be downloaded into the RAM of the mobile device. The smaller the file, the faster this can be done.\n" +
    "Even though our AI works to reduce your model as much as it can, without loss, the final size may still result in slightly longer loading times. In this case, our app may be a suitable solution for you.";
    static FAQ_A14 = "For the web version, a stable internet connection is required to view the model. Users of our app can also download the 3D models in advance and do not need an internet connection afterwards. This is ideal for large models and locations with poor internet reception.";
    static FAQ_A15 = "The app is particularly suitable for large models that would take longer to load on the web. With the help of the app, you can download your model to your mobile phone/tablet in advance and are thus independent of the internet connection and file size, e.g. in customer appointments.\n" +
    "While the web version is intended for people to view the 3D models with their own mobile device, the app is more suitable for equipping your own employees with it.";
    static FAQ_A16 = "Would you like to increase your quota? Feel free to contact us to customise your licence model.\n" + 
    "contact@vithrough.com";
    static FAQ_A17 = "As soon as the development is completed, you are welcome to contact us and we will activate your access for the app.\n" + 
    "contact@vithrough.com";
    static FAQ_A18 = "If you have any questions about invoices, please contact contact@vithrough.com.";
    static FAQ_Button = "open/close";
    static ModelLink = "Model Link"; 
    static ModelLinkTitle = "Copy";
    static Uploaded = "Uploaded on ";
    static FileName = "Upload options ";
    static OpenWebsite = "Open website";
    static DownloadImage = "Download QR-Code";
    static AllModels = "All models";
    static AllModelsInApp = "All in App";    
    static AllModelsOutApp = "All out of App";
    static AllModelsInAppHover = "Enables all models for app use";
    static AllModelsOutAppHover = "Disables all models for app use";
    static AllModelsInfo = "You can view all your models together in a list via this QR code and link.";
    static YourModels = "My models";
    static Companies = "Companies";
    static AddCompany  = "Add company";
    static RemCompany  = "Delete company";
    static RemCompanySure = "Are you sure to delete the company?"
    static Users = "Users";
    static AddUser = "Add user";
    static RemUser = "Delete user";
    static RemUserSure = "Are you sure to delete the user?";
    static SpacesTaken = "Slots used";
    static Register = "Register";
    static NotRegistered = "Not registered yet? Contact us: ";
    static Login = "Login";    
    static ForgotPw = "Forgot your password?";
    static Password = "Password";
    static ResetPassword = "Reset password";
    static ResetPasswordSure = "Are you sure to reset your password?";
    static ResetMailMessage = "We sent you an e-mail with a link to reset your password. Please check your mails (also the spam folder).";
    static Reset = "Reset"
    static BackToLogin = "Back to Login";
    static Username = "Username";
    static Upload = "Upload";
    static Loading = "Loading";
    static SelectFile = "Select File";    
    static FileType = "Filetype: ";
    static DropFile = "Drop file here.";
    static FileReq = "Please follow the subsequent file requirements:\n"
    static FileReqText = 
    "Please follow the subsequent file requirements\n"+
    "\nPossible input formats: 'fbx', 'obj', 'stl', 'glb', 'zip', 'dxf', 'gltf'\n"+
    "Maximal File Size: 100MB\n"+
    "File name: 4 - 50 characters, ordinary letters & numbers\n"+
    "Only a single file can be uploaded. Please combine exports with separate texture/material data in a .zip folder.\n"+
    "\nThe upload can take a few minutes. Please do not refresh the page during upload.";
    static FileReqButton = "File Requirements";
    static Delete = "Delete";
    static FileKeepOffset = "Keep Offset";
    static FileKeepOffsetKeep = "Keep";
    static FileKeepOffsetCenter = "Center";
    static FileKeepOffset_Help = "Has an exact offset to elements been defined?";
    static FileScale = "Scaling";
    static FileScaleOriginal = "Original";
    static FileScaleCustom = "Custom";
    static FileScale_Help = "Select the size for your model in %";
    static FilePosition = "Wall";
    static FilePositionWall = "Wall";
    static FilePositionFloor = "Floor";
    static FilePosition_Headline = "Position";
    static FilePosition_Help = "What should the model align with";
    static FileAnimate = "Animation";
    static FileAnimateAnimate = "Animate";
    static FileAnimateStatic = "Static";

    static Back_Button = "Back";

    static Table_File = "File type";
    static Table_Model = "Model";
    static Table_Material = "Material / Texture";
    static Table_Animation = "Animation";
    static Table_Bone = "Bone Animation";
    static Table_LightType = "Light type: emissive";
    static Table_LightSource = "Light sources";

    static AccountSettings = "Account Settings";
    static Coworkers = "Coworkers";    

    //popup
    static ModelSettingsName = "Name";
    static ModelSettingsSize = "File size"
    static ModelSettingsSizeRaw = "Raw-File: "
    static ModelSettingsSizeGltf = "Gltf: "
    static ModelSettingsSizeUsdz = "Usdz: "
    static ModelSettingsLastChange = "Last changes"
    static ModelSettingsPlacement = "Position "
    static ModelSettingsPlacementWall = "Wall"
    static ModelSettingsPlacementFloor = "Floor"
    static ModelSettingsAnimation = "Animation"
    static ModelSettingsAnimationPlaying = "Activate/Deactivate: "
    static ModelSettingsQRCode = "QR-Code "
    static ModelSettingsLink = "Link: "

    //errors
    static ThereWasAnError = "An error occured"
    static NoFileError = "No file was selected." 
    static UploadError = "Something went wrong during the upload process." 
    static UploadErrorFailedFiles = "Upload for the following files failed: " 
    static FileNotValidError = "One of the selected files is not valid. Please make sure all files are valid and have proper names."  
    static FileBadError = "The file $[0] does not match the size-guidelines. Minimal file size: $[1]. Maximal file size: $[2]"
    static FilenameBadError = "The filename of $[0] does not match the defined criteria. Minimal file name size: $[1]. Maximal file name size: $[2]. Furthermore, please use only common characters and do not use additional '.' in the file name."
    static FileformatBadError = "The file $[0] does not match the required file format. Please choose a valid format. ($[1])"
    static OnlyOneFileError = "Please upload only one file at a time."
    static GltfTooBigError = "The conversion was successful. However, the resulting .gltf file is bigger than the recommended file-size. Issues and long loading times are possible."
    static UsdzTooBigError = "The conversion was successful. However, the resulting .usdz file is bigger than the recommended file-size. Issues and long loading times are possible."
    static EmailNotFound = "The provided adress was not found."
    static ModelLimit1 = "You have reached your allowed amount of models.";
    static ModelLimit2 = "Please contact us if you need more space or delete existing models.";
    
    //upload
    static LoadError = "An error occured"
    static EMailError = "Could not send EMail."
    static Uploading = "Uploading file"
    static LoadQueued = "Waiting for server"
    static LoadUploadFinished = "Success"
    static LoadServerGetFiles = "Preparing"
    static LoadConvert = "Converting"
    static LoadDeploy = "Finalizing"
    static WindowCloseWarning = "Please do not close the window or tab while files are uploading."
    static WindowCloseAllow = "Upload successfull. You can close the window while your models are being processed."

    //basic logic
    static Cancel = "Cancel"
    static OK = "OK"
    static Save = "Save"
    static Close = "Close";
    static Download ="Download"
    static SomethingWrong = "Something went wrong..."    
    
    //data security
    static DataSecurityTitle = "Privacy policy.";
    static DataSecurityText = "On this page we would like to give you, as a person affected by data protection law, an overview of the processing of your personal data by us and your rights under data protection law.\n"
    + "The processing of personal data, for example your name or your E-mail address, is always carried out in accordance with the General Data Protection Regulation (GDPR/DSGVO) and in compliance with the national data protection regulations applicable to us, such as the Federal Data Protection Act (BDSG).";
    static DataSecurityResponsibilityTitle = "I. Responsible";
    static DataSecurityResponsibilityText = 
    "Responsible for the processing of your data is the following company:\n"
    + "talsand GmbH & Co. KG\n"
    + "Kesselstraße 3\n"
    + "40221 Düsseldorf\n"
    + "\n"
    + "Mr. Simon Schleifnig\n"
    + "Tel.: +49 175 5339786\n"
    + "E-Mail: info@talsand.eu\n"
    + "\n"
    + "Please feel free to ask us questions regarding data collection and processiong at any time.";
    static DataSecurityRightsTitle = "II. Your right as data subject";
    static DataSecurityRightsText = 
    "1. you have the right,\n"
    + "– pursuant to Art. 15 DSGVO to request information about the personal data processed by us concerning you and to the information listed in Art. 15 para. 1 lit. a)-h) DSGVO. "
    + "In particular, you can request information about the processing purposes, the category of personal data, the categories of recipients to whom your data have been or will be disclosed, the planned storage period, the existence of a right to rectification, erasure, restriction of processing or objection, the existence of a right of complaint, the origin of your data if it has not been collected by us, as well as the existence of automated decision-making including profiling and, if applicable, meaningful information about its details (“right of information”);\n"
    + "\n"
    + "– to demand the rectification of inaccurate personal data relating to you and the completion of incomplete personal data without delay (“right to rectification”) in accordance with Art. 16 DSGVO;\n"
    + "\n"
    + "– pursuant to Art. 17 DSGVO, to request the erasure of personal data concerning you, unless the processing is necessary for the exercise of the right to freedom of expression and information, for compliance with a legal obligation, for reasons of public interest or for the establishment, exercise or defence of legal claims (“right to erasure”);\n"
    + "\n"
    + "– to request the restriction of the processing of your personal data in accordance with Art. 18 DSGVO, insofar as the accuracy of the data is disputed by you, the processing is unlawful, but you object to its erasure and we no longer require the data, but you need it for the assertion, exercise or defence of legal claims or you have objected to the processing in accordance with Art. 21 DSGVO (“right to restriction of processing”);\n"
    + "\n"
    + "– pursuant to Art. 20 DSGVO to receive your personal data, which you have provided to us in the course of giving your consent or for the performance of a contract, in a structured, commonly used and machine-readable format or to request that it be transferred to another controller, insofar as this is technically feasible and does not adversely affect the rights and freedoms of other persons (“right to data portability”);\n"
    + "\n"
    + "– revoke your consent at any time in accordance with Art. 7 (3) DSGVO. In this case, we may no longer process the data based on this consent in the future, whereby the lawfulness of the processing carried out on the basis of your consent until the revocation is not affected (“right to revoke consent”);\n"
    + "\n"
    + "– not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you, in accordance with Article 22 of the GDPR, unless the decision is necessary for the conclusion or performance of a contract between you and us, is permitted by law or is based on your explicit consent.\n"
    + "\n"
    + "2. right of objection\n"
    + "If data processing is carried out on the basis of legitimate interests pursuant to Art. 6 (1) (f) DSGVO, you also have the right to object to us processing at any time pursuant to Art. 21 DSGVO, insofar as there are grounds for doing so which arise from your particular situation or the objection is directed against direct advertising or associated profiling. "
    + "If you object, we may no longer process your personal data unless there are demonstrably compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves the purpose of asserting, exercising or defending legal claims. This exception does not apply insofar as your objection is directed against direct marketing or related profiling. In that case, we may no longer process your personal data for this purpose under any circumstances. To exercise your right of objection, you can, for example, send us an appropriate e-mail.\n"
    + "\n"
    + "3. right of appeal\n"
    + "Finally, in accordance with Art. 77 DSGVO, you also have the right to complain to a supervisory authority. As a rule, you can contact the supervisory authority of your usual place of residence or workplace or our company headquarters for this purpose.\n"
    + "\n"
    + "4. storage of data\n"
    + "We store your data for the period corresponding to the regular statute of limitations (3 years beginning with the end of the year in which you made the data known to us), taking into account the legitimate interests. After that, your data will be deleted unless we are obliged by law to retain it for a longer period, e.g. to retain invoices (10 years) or business letters (6 years) in accordance with § 147 AO. In such cases, your data will be blocked for use for the duration of further storage and then deleted.\n";
    static DataSecurityProcessingTitle = "III. Processing of your personal data and purpose of processing";
    static DataSecurityProcessingText = 
    "1.  your visit to our website\n"
    + "When you access our website, the browser you use automatically sends information to our website server. This information is temporarily stored in a so-called log file. The data collection and processing is carried out on the basis of our legitimate interest in accordance with Art. 6 para. 1 p. 1 lit. f) DSGVO for the purpose of a smooth connection setup of the website, a comfortable use of our website, the evaluation of system security and stability as well as for further administrative purposes.\n"
    + "\n"
    + "This is the following information, which is collected without your intervention and stored until automated deletion:\n"
    + "\n"
    + "– IP address of the requesting computer,\n"
    + "– Date and time of access,\n"
    + "– Name and URL of the retrieved file,\n"
    + "– Website from which the access is made (so-called referrer URL),\n"
    + "– The browser used and, if applicable, the operating system of your computer as well as the name of your access provider.\n"
    + "\n"
    + "2. contacting\n"
    + "You can send us a message using the contact options provided. By sending us your message, you consent in accordance with Art. 6 (1) a) DSGVO to the processing of your personal data transmitted with your message in such a way that we may use it for the purpose of answering or processing your request and communicating with you. The processing is therefore also based on Art. 6 para. 1 lit. b) DSGVO.\n"
    + "\n"
    + "3. Cookies\n"
    + "We use cookies on our website to optimise the user-friendliness of our website and to enable the use of certain functions. These are small files that your browser automatically creates and that are stored on your terminal device when you visit our site. Most of the cookies we use serve the purpose of recognising that you have already visited individual pages of our website – so-called session cookies – and are deleted from your hard drive at the end of the browser session." 
    + "Other cookies – so-called temporary cookies – remain on your terminal device for a certain fixed period of time and enable us to recognise your terminal device on your next visit. If you visit our website again, it will automatically recognise that you have already been with us and which entries and settings you have made, so that you do not have to enter them again."
    + "Furthermore, we use cookies to statistically record the use of our website and to create and evaluate user profiles using pseudonyms for the purpose of optimising our offer, advertising and the design of our website in line with requirements (see section 6 for details).\n"
    + "\n"
    + "The data processed by cookies is necessary for the aforementioned purposes to protect our legitimate interests pursuant to Art. 6 (1) p. 1 lit. f) DSGVO.\n"
    + "\n"
    + "Note: You can set your browser so that no cookies are stored on your computer or a message always appears before a new cookie is created. As a precaution, we would like to point out that the complete deactivation of cookies may mean that not all functions of our website can be used.\n"
    + "\n"
    + "4. website service\n"
    + "\n"
    + "a) Google Analytics\n"
    + "\n"
    + "For analysis purposes, we use the “Google (Universal) Analytics” tool on our website, a web analysis service provided by Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland (“Google”)."
    + "This serves to protect our legitimate interests, which outweigh our interests in the context of a balancing of interests, in the optimal marketing, ongoing optimisation and needs-based design of our offer. The processing of your data described below is therefore carried out in accordance with Art. 6 (1) f) DSGVO.\n"
    + "\n"
    + "We use Google (Universal) Analytics exclusively with anonymisation of the IP address. A direct reference to a person is excluded through an abbreviation. Through the extension, your IP address is shortened beforehand by Google within member states of the European Union or in other contracting states of the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google LLC. server in the USA and shortened there.\n"
    + "\n"
    + "Google will use this information on our behalf for the purpose of evaluating your use of the website, compiling reports on website activity and providing other services relating to website activity and internet usage.\n"
    + "\n"
    + "The IP address transmitted by your browser as part of Google (Universal) Analytics is not merged with other Google data.\n"
    + 'Google Analytics also enables the creation of statistics with statements about the age, gender and interests of site visitors on the basis of an evaluation of interest-based advertising and with the involvement of third-party information via a special function, the so-called “demographic characteristics”.\n'
    + "This allows the definition and differentiation of user groups of the website for the purpose of targeting marketing measures. However, data records collected via the “demographic characteristics” cannot be assigned to a specific person. All of the processing described above, in particular the setting of Google Analytics cookies for reading out information on the end device used, will only be carried out if you have given us your express consent to do so in accordance with Art. 6 Para. 1 lit. a DSGVO.\n"
    + "\n"
    + "Without this consent, Google Analytics will not be used during your visit to the site. You can revoke your consent at any time with effect for the future.\n"
    + "\n"
    + "We have concluded an order processing agreement with Google for the use of Google Analytics, which obliges Google to protect the data of our site visitors and not to pass it on to third parties.\n"
    + "\n"
    + "For the transfer of data from the EU to the USA, Google refers to so-called standard data protection clauses of the European Commission, which are supposed to ensure compliance with the European level of data protection in the USA.\n"
    + "\n"
    + "Further information on Google (Universal) Analytics can be found here: https://policies.google.com/privacy?hl=de&gl=de\n"
    + "\n"
    + "b) MonsterInsights\n"
    + "\n"
    + "Our website uses MonsterInsights, a cookie-based tool for evaluating visitor access. The provider is MonsterInsights, LLC. , 7732 Maywood Crest Dr, West Palm Beach, Florida, 33412, United States.\n"
    + "\n"
    + "MonsterInsights uses cookies, which are stored on your computer and which allow an analysis of the use of the website. The information generated by the cookies about the use of our website is stored on servers in the USA. Your IP address is anonymised after processing and before storage. The cookies remain on your terminal device until you delete them.\n"
    + "\n"
    + "MonsterInsights cookies are stored on the basis of Art. 6 para. 1 lit. f) DSGVO. We have a legitimate interest in the anonymised analysis of your user behaviour in order to optimise our website.\n"
    + "\n"
    + "You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic deletion of cookies when closing the browser. If you deactivate cookies, the functionality of our website may be limited.\n"
    + "\n"
    + "c) Wordfence\n"
    + "\n"
    + "Wir We use the tool “Wordfence” on our website. Wordfence is used to protect the website from attacks, malware and other unauthorised access. The provider is Defiant, Inc, 800 5th Ave Ste 4100, Seattle, WA 98104, USA (hereinafter Wordfence).\n"
    + "\n"
    + "Wordfence establishes a permanent connection to our website in order to be able to block access to our website by comparing it with a database if necessary.\n"
    + "\n"
    + "The use of Wordfence by us is based on Art. 6 para. 1 lit. f DSGVO. We have a legitimate interest in protecting its website as comprehensively as possible from unauthorised access and attacks.\n"
    + "\n"
    + "For the transfer of data from the EU to the USA, Wordfence relies on so-called standard data protection clauses of the European Commission, which are supposed to ensure compliance with the European level of data protection in the USA.\n"
    + "\n"
    + "More information can be found here: https://www.wordfence.com/help/general-data-protection-regulation/.";
    
    //corporate information
    static CorporateInfoTitle = "Legal notice and provider identification";
    static CorporateInfoText = 
    "The following information (Impressum) is required under German law.\n"
    + "\n"
    + "The owner of this website and provider of services mentioned:\n";
    static CorporateInfoResponsibilityTitle = "talsand GmbH & Co. KG\n"
    static CorporateInfoResponsibilityText = 
    "Kesselstraße 3\n"
    + "40221 Düsseldorf\n"
    + "Tel.: +49 175 5339786\n"
    + "E-Mail: info@talsand.eu\n"
    + "\n"
    + "Commercial Register: HRA 26368\n"
    + "Register Court: Amtsgericht Düsseldorf\n"
    + "Tax Identification Number: DE343700366\n"
    + "\n"
    + "\n"
    + "represented by the Genral Partner (personally liable):\n"
    + "\n"
    + "talsand Verwaltungs GmbH\n"
    + "Herderstraße 20F\n"
    + "40237 Düsseldorf\n"
    + "\n"
    + "Commercial Register: HRB 92815\n"
    + "Register Court: Amtsgericht Düsseldorf\n"
    + "\n"
    + "Represented by the Chief Executive Officer Mr. Simon Schleifnig\n";;
    static CorporateInfoEditorialContentTitle = "Editorial Content";
    static CorporateInfoEditorialContentText = "Person responsible as per § 18 Abs. 2 MStV (Medienstaatsvertrag): Mr. Simon Schleifnig";
    static CorporateInfoResolutionTitle = "Online Dispute Resolution:";
    static CorporateInfoResolutionText = "Information on online dispute resolution: The EU Commission has created an Internet plattform for online dispute resolution. The plattform serves as contact point for  the out-of-court settlement of disputes concerning obligations arising from contracts concluded online. The costumer can access the plattform under the following link: http://ec.europa.eu/o";

    //LandingPage

    static Header = "Try a free demo";
    static AboutVithroughHeader = "About Vithrough";
    static AboutVithrough = "Vithrough - the platform with which you can transform 3D models into augmented reality applications completely automatically.\n\nFast. Intuitively. And without additional software or hardware.\n\nSimply load the existing 3D models from your CAD or 3D modelling program directly onto our platform, which automatically converts, optimises and creates the AR application.\n\nStart the applications easily using QR codes on any smartphone or tablet - without additional software.\n\nOr opt for our augmented reality app, which allows you and your team to use the models in higher quality and even offline.";
    static WhatIsArHeader = "What is AR?";
    static WhatIsAr = "Imagine a car navigation system that uses its camera to realistically display the road you are currently driving on - but also provides you with a direction arrow so that you know where to go. A virtual element has been added to reality to make driving easier for you. We take this technology of augmented sensory perception through virtual elements (augmented reality) and connect it to an industrial context.";
    static WhatIsVithroughHeader = "What is Vithrough?";
    static WhatIsVithrough = "Our platform enables you to convert your own 3D data into augmented reality applications in a fully automated process. Upload your data and get started.";
    static HowTothroughHeader = "How to use Vithrough?";
    static HowTothrough = "Simply upload your data to our platform - Vithrough takes care of the rest.\n\nYour data is automatically optimised, converted and prepared for use in augmented reality.\n\nAfter this process, the AR application is available to you via QR code or in our app. You have full control over the active models in your own dashboard.";
    static WhyVithroughHeader = "Why Vithrough?";
    static WhyVithrough = "Vithrough allows you to create augmented reality applications intuitively and without prior knowledge.\n\nExport your files from all common CAD and 3D modelling programs - numerous file formats are supported.\n\nImprove the customer experience with our functions: for example, scale the model so that it is optimally suited to the intended use. Whether in original size or scaled - the choice is yours.";
    static BenefitsHeader = "Benefits";
    static Benefits1 = "Enhance the customer experience within your marketing and sales process.\n\nAlways have your entire portfolio to hand. Digitally and in real size.\n\nUnderstandable and tangible using augmented reality.";
    static Benefits2 = "Save valuable resources.\n\nWith Vithrough, you reduce your resource requirements and promote sustainability.";
    static Benefits3 = "Data security is our top priority.\n\nAll data is stored in a German and certified data centre.";
    static InterestHeader = "Are you interested?";
    static IntererstC2A = "Please leave us your contact details and we will get back to you as soon as possible.";
    static InterestHeaderSubmit = "Thank you!";
    static IntererstC2ASubmit = "We will get back to you as soon as possible!";
    static InterestName = "First name";
    static InterestSurname = "Surname";
    static InterestTel = "Telephone number";
    static InterestMail = "E-mail address";
    static InterestConsent = "I hereby confirm that my data may be processed for the purpose of contacting me.";
    static InterestSubmit = "Send.";

}

export class TranslatedTextsDE extends TranslatedTexts {  
    static Talsand_Contact = "Tel: +49 175 5339786 | Mail: contact@vithrough.com";
    static Talsand_Adress = "talsand GmbH & Co. KG | Kesselstraße 3 | 40221 Düsseldorf";
    static LegalInformation = "Impressum";
    static ProtectionOfPrivacy = "Datenschutz";
    static FAQ = "FAQ";
    static Product = "Ein Produkt von";
    static Hover_LangSwitch = "Sprache wechseln";
    static Language = "Sprache";
    static Hover_Profile =  "Profil";
    static LoginFailed = "Login fehlgeschlagen. Entweder war die Eingabe fehlerhaft oder der Account ist deaktiviert."
    static Welcome1 = 'Willkommen bei Vithrough';
    static Welcome2 = "Hier können Sie in wenigen Minuten Ihre 3D Modelle für die Augmented Reality Ansicht vorbereiten. Melden Sie sich mit den von uns erhaltenen Zugangsdaten an, laden eigene 3D Modelle hoch und konvertieren sowie verwalten diese. Teilen Sie direkt im Anschluss das Ergebnis mit Ihren Kolleg*innen und Kund*innen.";
    static Welcome3 = "";
    static Welcome4 = "Viel Spaß!";
    static Tutorial = "Tutorial"
    static NoModel = "Sie haben noch keine Modelle hochgeladen";
    static EmptySlot = "Platz für weitere Datei";
    static MoreSpace = "Benötigen Sie noch mehr Plätze? Kontaktieren Sie uns gerne.";
    static OpenFile = "Datei öffnen";
    static QRCode = "QR-Code";
    static ViewQRCode = "QR-Code anzeigen";
    static LinkWasCopied = "Der Link wurde in die Zwischenablage kopiert.";
    static FAQ_Headline = "FAQ   -   Häufig gestellte Fragen und Antworten";
    static FAQ_Q1 = "Welche Dateiformate werden unterstützt?";
    static FAQ_Q2 = "Wie groß darf meine Datei sein?";
    static FAQ_Q3 = "Wieso bekomme ich eine Warnmeldung, obwohl meine Dateigröße unter dem Limit ist?";
    static FAQ_Q4 = "Wie lade ich Daten hoch?";
    static FAQ_Q5 = "Was für Einstellungsmöglichkeiten habe ich beim Hochladen meiner Daten?";
    static FAQ_Q6 = "Wie schaue ich mir meine Modelle in AR an?";
    static FAQ_Q7 = "Wer kann meine 3D Modelle ansehen?";
    static FAQ_Q8 = "Wie bearbeite ich meine Modelle?";
    static FAQ_Q9 = "Brauche ich für alle Modelle einen separaten QR Code/Link?";
    static FAQ_Q10 = "Kann ich eigene Lichtquellen mit exportieren?";
    static FAQ_Q11 = "Was ist bei durchsichtigen Materialien, wie bspw. Glas, zu beachten?";
    static FAQ_Q12 = "Warum sehe ich keine transparenten Texturen?";
    static FAQ_Q13 = "Warum lädt mein Modell so lange? (Webversion)";
    static FAQ_Q14 = "Benötige ich Internet, um meine Modelle zu zeigen?";
    static FAQ_Q15 = "Wann benötige ich die App?";
    static FAQ_Q16 = "Wie kann ich mehr Modelle hochladen?";
    static FAQ_Q17 = "Wie kann ich Zusatzfunktionen, wie die App, hinzubuchen?";
    static FAQ_Q18 = "Ich habe Fragen zur Rechnung, wen kann ich kontaktieren?";
    static FAQ_A1 = "Wir empfehlen die Verwendung von .fbx Exporten für die Konvertierung und Darstellung im Web. Jedoch werden auch andere Datenformate unterstützt. Folgende Tabelle zeigt die Vor- und Nachteile der jeweiligen Dateitypen auf.";
    static FAQ_A2 = "Die Dateien dürfen für den Upload maximal 100Mb groß sein.\nAuch wenn unsere KI im Hintergrund daran arbeitet große Modelle für die Webansicht anzupassen, kann es im Konvertierungsprozess passieren, dass Sie eine Fehlermeldung erhalten. In diesem Fall sind die konvertierten Formate zu groß für eine Darstellung im Web geworden und könnten daher zu Problemen in der Anzeige oder zu langen Ladezeiten führen.\n" +
    "Unsere Empfehlung lautet daher, im Idealfall eine Modellversion mit weniger Vertices hochzuladen und die Texturbilder auf eine maximale Kantenlänge von 2048 Pixeln zu beschränken.\n" +
    "Alternativ bieten wir eine App an, womit Sie Ihre Modelle bspw. bei Kunden vor Ort in AR zeigen können. Da dies nicht browserbasiert ist, ist dieser Weg ideal geeignet für größere, detailliertere Modelle, wie sie u.a. im Maschinenbau genutzt werden.";
    static FAQ_A3 = "Auch wenn unsere KI im Hintergrund daran arbeitet große Modelle für die Webansicht anzupassen, kann es im Konvertierungsprozess passieren, dass Sie eine Fehlermeldung erhalten. In diesem Fall sind die konvertierten Formate zu groß für eine Darstellung im Web geworden und könnten daher zu Problemen in der Anzeige oder zu langen Ladezeiten führen.\n" +
    "Unsere Empfehlung lautet daher, im Idealfall eine Modellversion mit weniger Vertices hochzuladen und die Texturbilder auf eine maximale Kantenlänge von 2048 Pixeln zu beschränken.";
    static FAQ_A4 = "Nachdem Sie sich angemeldet haben, sehen Sie im oberen Bereich der Seite einen Bereich, wo Sie die Möglichkeit haben, ihre Datei per “drag and drop” hineinzuziehen, oder über den “Datei auswählen” Button eine Datei auszuwählen.\n" +
    "Wichtig: Es kann nur eine Datei zur Zeit hochgeladen werden. Sollten Sie Exporte mit gesonderten Textur- / Materialdaten haben, wie beispielsweise bei .OBJ, fassen Sie diese in einem .zip Ordner zusammen.\n" +
    "Nun haben Sie noch verschiedene Einstellungsmöglichkeiten, welche in der nächsten Frage näher erläutert werden, und können danach auf “Hochladen” drücken.";
    static FAQ_A5 = "Mit unseren Einstellungsmöglichkeiten können Sie Ihr Modell an Ihre individuellen Anforderungen anpassen.\n" +
    "\nPlatzierung: Sie können einstellen, ob Ihr 3D Modell auf dem Fußboden oder an einer Wand platziert werden soll. Die Wandplatzierung eignet sich für Regale, Schilder, etc.\n" +
    "\nOffset: Liegt Ihr Modell nicht im Ursprungspunkt auf 0,0,0, sondern gewollt abseits, dann können Sie Ihren eingestellten “Offset” beibehalten. Beispielsweise bei schwebenden Objekten. Mit der Auswahl “zentrieren” wird Ihr Modell mittig auf dem Fußboden platziert.\n" +
    "\nSkalierung: Ihre Modelle werden in AR in derselben Größe dargestellt, wie sie im 3D Programm angelegt wurden. Möchten Sie sich also eine 5 Meter hohe Maschine ansehen, wird auch der dementsprechende Platz vor Ort benötigt. Sie haben jedoch die Möglichkeit Ihr Modell kleiner oder größer zu skalieren. Geben Sie einfach vor dem Upload die gewünschte Prozentzahl ein, in welchem Verhältnis zur Originalgröße Ihr Modell in AR dargestellt werden soll.";
    static FAQ_A6 = "Nach dem Hochladen erhalten Sie einen Link sowie einen QR Code. Sie können den Link in Ihren Browser kopieren oder den QR Code mit Ihrer Handy-/Tabletkamera scannen. Nun wird Ihnen Ihr Modell im 3D Modelviewer angezeigt. Auf Mobilgeräten (Smartphone, Tablet) befindet sich unten rechts ein Button mit dem Sie die AR Ansicht starten können.";
    static FAQ_A7 = "Nur Personen, die einen Link oder QR Code erhalten haben, können die dazugehörigen 3D Modelle ansehen. Sie haben also die volle Kontrolle über Ihre Daten. Wenn Sie ein Modell löschen, ist die dazugehörige Verlinkung augenblicklich ungültig.";
    static FAQ_A8 = "Nach Abschluss des Hochladevorgangs öffnet sich automatisch ein Fenster, in dem Sie Ihr soeben hochgeladenes Modell betrachten und verändern können. Natürlich haben Sie auch nachträglich diese Möglichkeit, indem Sie Ihr Modell aus der Liste auswählen und auf die Schaltfläche “Datei öffnen” klicken.\n" +
    "Hier haben Sie die Möglichkeit zwischen Boden- und Wandplatzierung zu wechseln. Auch können Sie, sofern eine Animation auf dem Modell liegt, bestimmen, ob diese in der AR Ansicht abgespielt werden soll oder nicht.\n" +
    "Änderungen speichern sich automatisch.";
    static FAQ_A9 = "Nein. Sie können Interessent*innen auch alle Ihre hochgeladenen Modelle mit einem einzigen Link/QR Code zeigen. Hierzu gehen Sie einfach rechts bei “Meine Modelle” auf die Schaltfläche “Modellliste ansehen”.";
    static FAQ_A10 = "Da der Modelviewer eigene Lichteinstellungen hat, werden keine importierten Lichtquellen berücksichtigt, mit Ausnahme von “Emissionslichtern”.";
    static FAQ_A11 = "Da transparente Materialien sehr unterschiedlich in den zahlreichen 3D Programmen und Exportformaten gehandhabt werden, ist es aktuell zwingend notwendig, dass diese Materialien vor dem Export im Editor so benannt werden, dass sie “Glass” im Namen stehen haben.";
    static FAQ_A12 = "Transparente Texturen werden aktuell leider nicht unterstützt.";
    static FAQ_A13 = "Da wir uns im Web befinden ist die Dateigröße ausschlaggebend. Bei jedem Aufruf muss das Modell erst in den RAM des Mobilgeräts heruntergeladen werden. Je kleiner die Datei ist, desto schneller ist dies möglich.\n" +
    "Auch wenn unsere KI beim Hochladen daran arbeitet Ihr Modell verlustfrei so weit zu reduzieren, wie es geht, so kann es sein, dass die Endgröße dennoch zu etwas längeren Ladezeiten führen kann. Eventuell ist in diesem Falle unsere App eine passende Lösung für Sie.";
    static FAQ_A14 = "Für die Webvariante wird eine stabile Internetverbindung für das Aufrufen des Modells benötigt. Nutzer*innen unserer App können 3D Modelle auch vorab herunterladen und benötigen danach keine Internetverbindung mehr. Dies ist für große Modelle sowie Orte mit schlechtem Empfang ideal geeignet.";
    static FAQ_A15 = "Die App ist gerade für große Modelle gut geeignet, die im Web zu längeren Ladezeiten führen würden. Mit Hilfe der App können Sie sich vorab Ihr Modell auf das Handy/Tablet laden und sind somit in Kundenterminen unabhängig von der Internetverbindung und der Dateigröße.\n" +
    "Während die Webversion dafür gedacht ist, dass Personen sich die 3D Modelle mit Ihrem eigenen Mobilgerät ansehen können, so eignet sich die App eher dafür, die eigenen Mitarbeitenden damit auszustatten. ";
    static FAQ_A16 = "Möchten Sie Ihr Kontingent erhöhen? Kontaktieren Sie uns gerne, um Ihr Lizenzmodell anzupassen.\n" + 
    "contact@vithrough.com";
    static FAQ_A17 = "Sobald die Entwicklung abgeschlossen ist, können Sie uns gerne kontaktieren und wir schalten Ihren Zugang für die App frei.\n" + 
    "contact@vithrough.com";
    static FAQ_A18 = "Bei Fragen rund um das Thema Rechnungen, kontaktieren Sie bitte contact@vithrough.com";
    static FAQ_Button = "öffnen/schließen";
    static ModelLink = "Link zum Modell"; 
    static ModelLinkTitle = "Kopieren";
    static Uploaded = "Hochgeladen am ";
    static FileName = "Upload Einstellungen\n";
    static OpenWebsite = "Website öffnen";
    static DownloadImage = "QR-Code herunterladen";
    static AllModels = "Modelliste ansehen";
    static AllModelsInApp = "Alle in App übertragen";
    static AllModelsOutApp = "Alle aus App entfernen";
    static AllModelsInAppHover = "Macht alle Modelle in der App darstellbar";
    static AllModelsOutAppHover = "Deaktiviert alle Modelle für Darstellung in der App";
    static AllModelsInfo = "Über diesen QR-Code bzw. Link können Sie all ihre Modelle in einer Liste betrachten.";
    static YourModels = "Meine Modelle";
    static Companies = "Unternehmen";
    static AddCompany  = "Unternehmen hinzufügen";
    static RemCompany  = "Unternehmen löschen";
    static RemCompanySure = "Wollen Sie das Unternehmen wirklich löschen?"
    static Users = "Benutzer";
    static AddUser = "Benutzer hinzufügen";
    static RemUser = "Benutzer löschen";  
    static RemUserSure = "Wollen Sie den Nutzeraccount wirklich löschen?";
    static SpacesTaken = "Slots genutzt";
    static Register = "Registrieren";
    static NotRegistered = "Noch keinen Account? Schreiben Sie uns an: ";
    static Login = "Anmelden";
    static ForgotPw = "Passwort vergessen?";
    static Password = "Passwort";
    static ResetPassword = "Passwort zurücksetzen";
    static ResetPasswordSure = "Möchten Sie das Passwort wirklich zurücksetzen?";
    static ResetMailMessage = "Der Link zum Ändern des Passworts wurde Ihnen per E-Mail zugeschickt. Bitte überprüfen Sie Ihre Mails (auch den Spam-Ordner).";
    static Reset = "Zurücksetzen"
    static BackToLogin = "Zurück zur Anmeldung";
    static Username = "Benutzername";
    static Upload = "Hochladen";
    static Loading = "Lädt";
    static SelectFile = "Datei auswählen";
    static FileType = "Datei-Typ: ";
    static DropFile = "Dateien hier ablegen";
    static FileReq = "Bitte befolgen Sie die nachfolgenden Dateianforderungen";
    static FileReqText = 
    "Bitte befolgen Sie die nachfolgenden Dateianforderungen:\n"+ 
    "\nMögliche Datei-Formate: 'fbx', 'obj', 'stl', 'glb', 'zip', 'dxf', 'gltf'\n"+
    "Maximale Datei-Größe: 100MB\n"+
    "Dateinamen: 4 - 50 Zeichen, gewöhnliche Buchstaben & Zahlen\n"+
    "Es ist nur der Upload einer einzelnen Datei möglich. Bitte fassen Sie Exporte mit gesonderten Textur- / Materialdaten in einem .zip Ordner zusammen.\n"+
    "\nDer Upload kann ein paar Minuten dauern. Bitte laden Sie die Seite während des Uploadprozesses nicht neu.";
    static FileReqButton = "Dateianforderungen";
    static Delete = "Löschen";
    static FileKeepOffset = "Offset behalten";
    static FileKeepOffset_Help = "Wurde ein genauer Abstand zu Elementen definiert?";
    static FileKeepOffsetKeep = "Beibehalten";
    static FileKeepOffsetCenter = "Zentrieren";
    static FileScale = "Skalierung";
    static FileScaleOriginal = "Original";
    static FileScaleCustom = "Eingabe";
    static FileScale_Help = "Geben Sie die gewünschte Größe für Ihr Modell in % an";
    static FilePosition = "Wand";
    static FilePositionWall = "Wand";
    static FilePositionFloor = "Boden";
    static FilePosition_Headline = "Platzierung";
    static FilePosition_Help = "Woran soll sich das Modell ausrichten";
    static FileAnimate = "Animation";
    static FileAnimateAnimate = "Animieren";
    static FileAnimateStatic = "Statisch";

    static Back_Button = "Zurück";

    static Table_File = "Dateityp";
    static Table_Model = "Modell";
    static Table_Material = "Material / Textur";
    static Table_Animation = "Animation";
    static Table_Bone = "Bone Animation";
    static Table_LightType = "Emissionslicht";
    static Table_LightSource = "Lichtquelle";

    static AccountSettings = "Accounteinstellungen";
    static Coworkers = "Mitarbeitende";

    //popup
    static ModelSettingsName = "Name";
    static ModelSettingsSize = "Dateigröße"
    static ModelSettingsSizeRaw = "Roh-Datei: "
    static ModelSettingsSizeGltf = "Gltf: "
    static ModelSettingsSizeUsdz = "Usdz: "
    static ModelSettingsLastChange = "Zuletzt geändert am"
    static ModelSettingsPlacement = "Platzierung"
    static ModelSettingsPlacementWall = "Wand"
    static ModelSettingsPlacementFloor = "Boden"
    static ModelSettingsAnimation = "Animation"
    static ModelSettingsAnimationPlaying = "An/Aus: "
    static ModelSettingsQRCode = "QR-Code "
    static ModelSettingsLink = "Link:"

    //errors    
    static ThereWasAnError = "Ein Fehler ist aufgetreten."
    static NoFileError = "Keine Datei wurde ausgewählt." 
    static UploadError = "Etwas ist während des Uploads schief gelaufen." 
    static UploadErrorFailedFiles = "Die nachfolgenden Dateien konnten nich hochgeladen werden: " 
    static FileNotValidError = "Eine der ausgewählten Dateien ist nicht valide." 
    static FileBadError = "Die Datei $[0] entspricht nicht den Größenvorgaben. Minimale Dateigröße: $[1]. Maximale Dateigröße: $[2]"
    static FilenameBadError = "Der Dateiname $[0] entspricht nicht den festgelegten Kriterien. Minimale Länge des Dateinamens: $[1]. Maximale Länge des Dateinamens: $[2]. Bitte verwenden Sie außerdem nur gebräuchliche Zeichen und keine zusätzlichen '.' im Dateinamen."
    static FileformatBadError = "Die Datei $[0] entspricht nicht den vorgegebenen Dateiformaten. Bitte wählen Sie ein gültiges Dateiformat aus. ($[1])"
    static OnlyOneFileError = "Laden Sie bitte nur eine Datei gleichzeitig hoch."
    static GltfTooBigError = "Der Vorgang war erfolgreich. Leider übersteigt die resultierende .gltf Datei die empfohlene Dateigröße. Probleme und lange Ladezeiten sind möglich."
    static UsdzTooBigError = "Der Vorgang war erfolgreich. Leider übersteigt die resultierende .usdz Datei die empfohlene Dateigröße. Probleme und lange Ladezeiten sind möglich."
    static EmailNotFound = "Die angegebene E-Mail wird nicht verwendet."
    static ModelLimit1 = "Ihr Modellkontingent ist aufgebraucht."
    static ModelLimit2 = "Kontaktieren Sie uns, wenn Sie mehr Plätze benötigen oder entfernen Sie hochgeladene Modelle."

    //upload
    static LoadError = "Ein Fehler ist aufgetreten"
    static EMailError = "Konnte keine EMail-Benachrichtigung senden."
    static Uploading = "Datei hochladen"
    static LoadQueued = "In Warteschlange"
    static LoadUploadFinished = "Erfolgreich" 
    static LoadServerGetFiles = "Verbereiten"
    static LoadConvert = "Konvertieren"
    static LoadDeploy = "Finalisieren"
    static WindowCloseWarning = "Bitte schließen Sie das Fenster und den Tab nicht, solange noch Dateien hochgeladen werden."
    static WindowCloseAllow = "Hochladen abgeschlossen. Sie können das Fenster schließen, während Ihre Modelle verarbeitet werden."

    //basic logic
    static Cancel = "Abbrechen"
    static OK = "OK"
    static Save = "Speichern"
    static Close = "Schließen";
    static Download ="Herunterladen"
    static SomethingWrong = "Es ist etwas schief gelaufen"
    
    //data security
    static DataSecurityTitle = "Datenschutzerklärung.";
    static DataSecurityText = "Auf dieser Seite möchten wir Ihnen als datenschutzrechtlich betroffener Person einen Überblick über die Verarbeitung Ihrer personenbezogenen Daten durch uns und Ihre datenschutzrechtlichen Rechte geben.\n"
    + "\n"
    + "Die Verarbeitung von personenbezogenen Daten, das sind beispielsweise Ihr Name oder Ihre E-Mail-Adresse, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung (DSGVO) und in Übereinstimmung mit den für uns geltenden nationalen Datenschutzbestimmungen, wie dem Bundesdatenschutzgesetz (BDSG). ";
    static DataSecurityResponsibilityTitle = "I. Verantwortlicher";
    static DataSecurityResponsibilityText = "Verantwortlicher für die Verarbeitung Ihrer Daten ist die\n"
    + "\n"
    + "talsand GmbH & Co. KG\n"
    + "Kesselstraße 3\n"
    + "40221 Düsseldorf\n"
    + "\n"
    + "Herr Simon Schleifnig\n"
    + "Tel.: +49 175 5339786\n"
    + "E-Mail: info@talsand.eu\n"
    + "\n"
    + "Sie können uns jederzeit Fragen zur Datenerhebung und -verarbeitung stellen."
    static DataSecurityRightsTitle = "II. Ihre Rechte als Betroffener der Datenverarbeitung";
    static DataSecurityRightsText = 
    "1. Sie haben das Recht,\n"
    + "– gemäß Art. 15 DSGVO auf Auskunft über die Sie betreffende von uns verarbeiteten personenbezogenen Daten und auf die in Art. 15 Abs. 1 lit. a)-h) DSGVO aufgeführten Informationen."
    + "Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, "
    + "Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen („Auskunftsrecht“);\n"
    + "\n"
    + "– gemäß Art. 16 DSGVO unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten und die Vervollständigung unvollständiger personenbezogener Daten zu verlangen („Recht auf Berichtigung“);\n"
    + "\n"
    + "– gemäß Art. 17 DSGVO die Löschung der Sie betreffenden personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, "
    + "Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist („Recht auf Löschung“);\n"
    + "\n"
    + "– gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, "
    + "Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben („Recht auf Einschränkung der Verarbeitung“);\n"
    + "\n"
    + "– gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns im Zuge einer Einwilligung oder für die Vertragsabwicklung übermittelt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies "
    + "technisch machbar ist und die Rechte und Freiheiten anderer Personen nicht beeinträchtigt werden („Recht auf Datenübertragung“);\n"
    + "\n"
    + "– gemäß Art. 7 Abs. 3 DSGVO Ihre erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. In dem Fall dürfen wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen, wobei die Rechtmäßigkeit der aufgrund Ihrer Einwilligung bis zum Widerruf erfolgten "
    + "Verarbeitung dabei nicht berührt wird („Recht auf Widerruf von Einwilligungen“);\n"
    + "\n"
    + "– gemäß Art. 22 DSGVO nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise "
    + "erheblich beeinträchtigt, sofern die Entscheidung nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich ist, nicht aufgrund von Rechtsvorschriften zulässig ist oder nicht mit Ihrer ausdrücklichen Einwilligung erfolgt.\n"
    + "\n"
    + "2. Widerspruchsrecht\n"
    + "Erfolgt eine Datenverarbeitung auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f) DSGVO, haben Sie ferner das Recht, der Verarbeitung uns gegenüber gemäß Art. 21 DSGVO jederzeit zu widersprechen, soweit dafür Gründe vorliegen, "
    + "die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung oder ein damit in Verbindung stehendes Profiling richtet. Bei Widerspruch durch Sie dürfen wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, es bestehen nachweislich zwingende schutzwürdige "
    + "Gründe für die Verarbeitung, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient. Diese Ausnahme besteht nicht, soweit sich Ihr Widerspruch gegen Direktwerbung oder ein damit in Verbindung stehendes "
    + "Profiling richtet. In dem Fall dürfen wir Ihre personenbezogenen Daten zu diesem Zwecke in keinem Fall mehr verarbeiten. Für die Ausübung Ihres Widerspruchsrechts können Sie uns z.B. eine entsprechende E-Mail senden.\n"
    + "\n"
    + "3. Beschwerderecht\n"
    + "Gemäß Art. 77 DSGVO haben Sie schließlich auch das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.\n"
    + "\n"
    + "4. Speicherung von Daten\n"
    + "Wir speichern Ihre Daten unter Abwägung der berechtigten Interessen für die Dauer, die der regelmäßigen Verjährung entspricht (3 Jahre beginnend mit dem Schluss des Jahres, in dem Sie uns die "
    + "Daten bekannt gemacht haben). Danach werden Ihre Daten gelöscht, es sei denn, wir sind aufgrund gesetzlicher Vorgaben zur längeren Aufbewahrung verpflichtet, wie z.B. zur Aufbewahrung von Rechnungen (10 Jahre) oder von Geschäftsbriefen "
    + "(6 Jahre) gemäß § 147 AO. In solchen Fällen werden Ihre Daten zur Verwendung für die Dauer der weiteren Aufbewahrung gesperrt und anschließend gelöscht. "
    static DataSecurityProcessingTitle = "III. Verarbeitung Ihrer personenbezogenen Daten und Zweck der Verarbeitung";
    static DataSecurityProcessingText = 
    "1.  Ihr Besuch unserer Webseite\n"
    + "Wenn Sie unsere Webseite aufrufen, werden durch den von Ihnen verwendeten Browser automatisch Informationen an den Server unserer Webseite gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Die Datenerhebung und -verarbeitung "
    + "erfolgt aufgrund unseres berechtigten Interesses gemäß Art. 6 Abs. 1 S. 1 lit. f) DSGVO zum Zwecke eines reibungslosen Verbindungsaufbaus der Webseite, einer komfortablen Nutzung unserer Webseite, der Auswertung der Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken.\n"
    + "\n"
    + "Es handelt sich dabei um die folgenden Informationen, die ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert werden:\n"
    + "– IP-Adresse des anfragenden Rechners,\n"
    + "– Datum und Uhrzeit des Zugriffs,\n"
    + "– Name und URL der abgerufenen Datei,\n"
    + "– Webseite, von der aus der Zugriff erfolgt (sog. Referrer-URL),\n"
    + "– verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.\n"
    + "\n"
    + "2. Kontaktaufnahme\n"
    + "Sie können uns über die angegebenen Kontaktmöglichkeiten eine Nachricht übersenden. Mit Übersendung Ihrer Nachricht willigen Sie gemäß Art. 6 Abs. 1 lit. a) DSGVO in die Verarbeitung Ihrer mit Ihrer Nachricht übermittelten personenbezogenen Daten in der Form ein, dass wir "
    + "diese zum Zwecke der Beantwortung bzw. Bearbeitung Ihres Anliegens und zur Kommunikation mit Ihnen verwenden dürfen. Die Verarbeitung erfolgt daher auch auf Grundlage des Art. 6 Abs. 1 lit. b) DSGVO.\n"
    + "\n"
    + "3. Cookies\n"
    + "Auf unserer Webseite verwenden wir Cookies, die der Optimierung der Benutzerfreundlichkeit unserer Webseite dienen und die Nutzung bestimmter Funktionen ermöglichen. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die "
    + "auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite besuchen. Die meisten der von uns verwendeten Cookies dienen dem Zweck, zu erkennen, dass Sie einzelne Seiten unserer Webseite bereits besucht haben – sog. Session-Cookies – und werden nach Ende der Browser-Sitzung wieder von Ihrer Festplatte gelöscht. "
    + "Andere Cookies – sog. temporäre Cookies – verbleiben für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät und ermöglichen uns, Ihr Endgerät bei Ihrem nächsten Besuch wieder zu erkennen. Besuchen Sie unsere Webseite erneut, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben "
    + "und Einstellungen sie getätigt haben, sodass Sie diese nicht noch einmal eingeben müssen. Ferner setzen wir Cookies ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes, der Werbung und der bedarfsgerechten Gestaltung unserer Webseite Nutzungsprofile "
    + "unter Verwendung von Pseudonymen zu erstellen und auszuwerten (siehe im Detail hierzu Ziff. 6).\n"
    + "\n"
    + "Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen nach Art. 6 Abs. 1 S. 1 lit. f) DSGVO erforderlich.\n"
    + "\n"
    + "Hinweis: Sie können Ihren Browser so einstellen, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Wir weisen vorsorglich darauf hin, dass die vollständige "
    + "Deaktivierung von Cookies dazu führen kann, dass nicht alle Funktionen unserer Webseite genutzt werden können.\n"
    + "\n"
    + "4. Webseitendienste\n"
    + "\n"
    + "a) Google Analytics\n"
    + "\n"
    + "Wir verwenden auf unserer Webseite zu Analysezwecken das Tool „Google (Universal) Analytics“, einen Webanalysedienst der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland („Google“). Dies dient der Wahrung unserer im Rahmen einer "
    + "Interessensabwägung überwiegenden berechtigten Interessen an einer optimalen Vermarktung, fortlaufenden Optimierung und bedarfsgerechten Gestaltung unseres Angebots. Die nachfolgend beschriebene Verarbeitung Ihrer Daten erfolgt damit gemäß Art. 6 Abs. 1 f) DSGVO.\n"
    + "\n"
    + "Wir verwenden Google (Universal) Analytics ausschließlich mit einer Anonymisierung der IP-Adresse. Durch eine Kürzung wird eine direkte Personenbeziehbarkeit ausgeschlossen. Durch die Erweiterung wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder "
    + "in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google LLC.in den USA übertragen und dort gekürzt.\n"
    + "\n"
    + "In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen uns gegenüber zu erbringen.\n"
    + "\n"
    + "Dabei wird die im Rahmen von Google (Universal) Analytics von Ihrem Browser übermittelte IP-Adresse nicht mit anderen Daten von Google zusammengeführt.\n"
    + "\n"
    + "Google Analytics ermöglicht über eine spezielle Funktion, die sog. „demografischen Merkmale“, darüber hinaus die Erstellung von Statistiken mit Aussagen über Alter, Geschlecht und Interessen der Seitenbesucher auf Basis einer Auswertung von interessenbezogener Werbung und unter Hinzuziehung von Drittanbieterinformationen.\n"
    + "\n"
    + "Dies erlaubt die Definition und Differenzierung von Nutzerkreisen der Webseite zum Zwecke der zielgruppenoptimierten Ausrichtung von Marketingmaßnahmen. Über die „demografischen Merkmale“ erfasste Datensätze können jedoch keiner bestimmten Person zugeordnet werden. Alle oben beschriebenen Verarbeitungen, insbesondere "
    + "das Setzen von Google Analytics-Cookies für das Auslesen von Informationen auf dem verwendeten Endgerät, werden nur dann vollzogen, wenn Sie uns gemäß Art. 6 Abs. 1 lit. a DSGVO dazu Ihre ausdrückliche Einwilligung erteilt haben.\n"
    + "\n"
    + "Ohne diese Einwilligungserteilung unterbleibt der Einsatz von Google Analytics während Ihres Seitenbesuchs. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.\n"
    + "\n"
    + "Wir haben mit Google für die Nutzung von Google Analytics einen Auftragsverarbeitungsvertrag abgeschlossen, mit dem Google verpflichtet wird, die Daten unserer Seitenbesucher zu schützen und sie nicht an Dritte weiterzugeben\n"
    + "\n"
    + "Für die Übermittlung von Daten aus der EU in die USA beruft sich Google hierbei auf sog. Standarddatenschutzklauseln der Europäischen Kommission, welche die Einhaltung des europäischen Datenschutzniveaus in den USA gewährleisten sollen.\n"
    + "\n"
    + "Weitere Hinweise zu Google (Universal) Analytics finden Sie hier: https://policies.google.com/privacy?hl=de&gl=de\n"
    + "\n"
    + "b) MonsterInsights\n"
    + "\n"
    + "Unsere Webseite nutzt MonsterInsights, ein Cookie-basiertes Tool zur Auswertung von Besucherzugriffen. Anbieter ist die MonsterInsights, LLC., 7732 Maywood Crest Dr, West Palm Beach, Florida, 33412, United States.\n"
    + "\n"
    + "MonsterInsights verwendet Cookies, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website erlauben. Die durch die Cookies generierten Informationen über die Benutzung unserer Website werden auf Servern in den USA gespeichert. Ihre "
    + "IP-Adresse wird nach der Verarbeitung und vor der Speicherung anonymisiert. Die Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen.\n"
    + "\n"
    + "Die Speicherung von MonsterInsights -Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f) DSGVO. Wir haben ein berechtigtes Interesse an der anonymisierten Analyse Ihres Nutzerverhaltens, um unser Webangebot zu optimieren.\n"
    + "\n"
    + "Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. "
    + "Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.\n"
    + "\n"
    + "c) Wordfence\n"
    + "\n"
    + "Wir nutzen auf unserer Webseite das Tool „Wordfence“. Wordfence dient dem Schutz der Website vor Attacken, Schadesoftware und sonstigen unberechtigten Zugriffen. Anbieter ist die Defiant, Inc., 800 5th Ave Ste 4100, Seattle, WA 98104, USA (nachfolgend Wordfence).\n"
    + "\n"
    + "Wordfence stellt zu unserer Webseite eine dauerhafte Verbindung her, um Zugriffe auf unsere Webseite durch den Abgleich mit einer Datenbank ggf. blocken zu können.\n"
    + "\n"
    + "Die Verwendung von Wordfence durch uns erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einem möglichst umfassenden Schutz seiner Webseite vor unberechtigten Zugriffen und Angriffen.\n"
    + "\n"
    + "Für die Übermittlung von Daten aus der EU in die USA beruft sich Wordfence auf sog. Standarddatenschutzklauseln der Europäischen Kommission, welche die Einhaltung des europäischen Datenschutzniveaus in den USA gewährleisten sollen.\n"
    + "\n"
    + "Weitere Details finden Sie hier: https://www.wordfence.com/help/general-data-protection-regulation/.";
    
    //corporate information
    static CorporateInfoTitle = "Impressum und Anbieterkennzeichnung";
    static CorporateInfoText = "Verantwortlicher Betreiber dieser Webseite und Anbieter der Dienstleistungen ist die:";
    static CorporateInfoResponsibilityTitle = "talsand GmbH & Co. KG";
    static CorporateInfoResponsibilityText = 
    "Kesselstraße 3\n"
    + "40221 Düsseldorf\n"
    + "Tel.: +49 175 5339786\n"
    + "E-Mail: info@talsand.eu\n"
    + "\n"
    + "Handelsregister: HRA 26368\n"
    + "Registergericht: Amtsgericht Düsseldorf\n"
    + "Umsatzsteuer-Identifikationsnummer: DE343700366\n"
    + "\n"
    + "\n"
    + "vertreten durch die persönlich haftende Gesellschafterin:\n"
    + "\n"
    + "talsand Verwaltungs GmbH\n"
    + "Herderstraße 20F\n"
    + "40237 Düsseldorf\n"
    + "\n"
    + "Handelsregister: HRB 92815\n"
    + "Registergericht: Amtsgericht Düsseldorf\n"
    + "\n"
    + "Diese vertreten durch den Geschäftsführer Herrn Simon Schleifnig\n";
    static CorporateInfoEditorialContentTitle = "Redaktionelle Beiträge:";
    static CorporateInfoEditorialContentText = "Verantwortlicher i. S. v. § 18 Abs. 2 MStV (Medienstaatsvertrag): Herr Simon Schleifnig";
    static CorporateInfoResolutionTitle = "Online Streitbeilegung:";
    static CorporateInfoResolutionText = "Informationen zur Online-Streitbeilegung: Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten geschaffen. Die Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend Verpflichtungen, die aus online geschlossenen Verträgen erwachsen. Der Kunde kann die Plattform unter dem folgenden Link erreichen: http://ec.europa.eu/odr"

    //LandingPage

    static Header = "Jetzt Demo ausprobieren";
    static AboutVithroughHeader = "Über Vithrough";
    static AboutVithrough = "Vithrough – die Plattform, mit der Sie 3D Modelle vollständig automatisiert in Augmented Reality Anwendungen verwandeln.\n\nSchnell. Intuitiv. Und ohne zusätzliche Soft- oder Hardware.\n\nLaden Sie hierfür die vorhandenen 3D Modelle aus Ihrem CAD- oder 3D Modellierungsprogramm direkt auf unsere Plattform.\nDiese übernimmt automatisch die Konvertierung, Optimierung und Erstellung der AR Anwendung.\n\nStarten Sie die Anwendungen niederschwellig mittels QR Codes auf jeglichen Smartphones und Tablets - ohne zusätzliche Software.\n\nOder entscheiden Sie sich für unsere Augmented Reality App, mit der Sie und Ihr Team die Modelle noch hochwertiger und sogar offline nutzen können.";
    static WhatIsArHeader = "Was ist AR?";
    static WhatIsAr = "Stellen Sie sich ein Auto-Navigationssystem vor, das mittels seiner Kamera die Straße, auf der Sie gerade fahren, realitätsecht anzeigt – aber zusätzlich mit einem Wegpfeil versieht, damit Sie wissen, wo Sie hinfahren müssen. Die Realität wurde mit einem virtuellen Element ergänzt, um Ihnen das Autofahren zu erleichtern. Diese Technologie der erweiterten Sinneswahrnehmung durch virtuelle Elemente (Augmented Reality) nehmen wir auf und setzen sie in den industriellen Kontext.";
    static WhatIsVithroughHeader = "Was ist Vithrough?";
    static WhatIsVithrough = "Unsere Plattform ermöglicht es Ihnen, vollkommen automatisiert, Ihre eigenen 3D Daten in Augmented Reality Anwendung zu wandeln. Laden Sie Ihre Daten hoch und starten durch.";
    static HowTothroughHeader = "Wie benutze ich Vithrough?";
    static HowTothrough = "Laden Sie Ihren Daten ganz einfach auf unserer Plattform hoch - den Rest übernimmt Vithrough.\n\nIhre Daten werden in einem automatisierten Prozess optimiert, konvertiert und für den Einsatz in Augmented Reality vorbereitet.\n\nNach diesem Prozess steht Ihnen die AR Anwendung mittels QR Code oder in unserer App zur Verfügung. In Ihrem eigenen Dashboard haben Sie die volle Kontrolle über die aktiven Modelle.";
    static WhyVithroughHeader = "Warum Vithrough?";
    static WhyVithrough = "Vithrough erlaubt es Ihnen, intuitiv und ohne Vorkenntnisse Augmented Reality Anwendungen zu erstellen.\n\nExportieren Sie Ihre Dateien aus allen gängigen CAD - und 3D Modellierungsprogrammen - zahlreiche Dateiformate werden unterstützt.\n\nVerbessern Sie mittels unserer Funktionen das Kundenerlebnis: skalieren Sie zum Beispiel das Modell, damit es optimal zum Verwendungszweck passt. Ob in Originalgröße oder skaliert - Sie haben die Wahl.";
    static BenefitsHeader = "Ihre Vorteile";
    static Benefits1 = "Erhöhen Sie das Kundenerlebnis innerhalb Ihres Marketing- und Vertriebsprozesses.\nHaben Sie Ihr gesamtes Portfolio stets zur Hand. Digital und in Echtgröße.\nVerständlich und zum Anfassen mittels Augmented Reality.";
    static Benefits2 = "Sparen Sie wertvolle Ressourcen.\nMit Vithrough vermindern Sie Ihren Ressourcenbedarf und fördern die Nachhaltigkeit.";
    static Benefits3 = "Datensicherheit hat bei uns oberste Priorität.\nAlle Daten liegen in einem deutschen und zertifizierten Rechenzentrum.";
    static InterestHeader = "Interesse geweckt?";
    static IntererstC2A = "Hinterlassen Sie uns gerne Ihre Kontaktdaten und wir melden uns schnellstmöglich bei Ihnen.";
    static InterestHeaderSubmit = "Vielen Dank!";
    static IntererstC2ASubmit = "Wir melden uns schnellstmöglich bei Ihnen!";
    static InterestName = "Vorname";
    static InterestSurname = "Nachname";
    static InterestTel = "Telefonnummer";
    static InterestMail = "Mailadresse";
    static InterestConsent = "Hiermit bestätige ich, dass meine Daten im Sinne der Kontaktaufnahme verarbeitet werden dürfen.";
    static InterestSubmit = "Abschicken.";
}
