class DispatchEvent {
	constructor(eventName) {
		this.eventName = eventName;
		this.callbacks = [];
	}

	registerCallback(callback) {
		this.callbacks.push(callback);
	}

	unregisterCallback(callback) {
		const callbackIndex = this.callbacks.indexOf(callback);

		if (callbackIndex > -1) {
			this.callbacks.splice(callbackIndex, 1);
		}
	}

	fire(data) {
		const callbacks = this.callbacks.slice(0);
		callbacks.forEach((callback) => callback(data));
	}
}

class Dispatcher {
	static events = {};

	static dispatch(eventName, data) {
		const event = this.events[eventName];

		if (event) {
			event.fire(data);
		}
	}

	static on(eventName, callback) {
		let event = this.events[eventName];

		if (!event) {
			event = new DispatchEvent(eventName);
			this.events[eventName] = event;
		}

		event.registerCallback(callback);
	}

	static off(eventName, callback) {
		const event = this.events[eventName];

		if (event) {
			event.unregisterCallback(callback);

			if (event.callbacks.length === 0) {
				delete this.events[eventName];
			}
		}
	}
}

export default Dispatcher;