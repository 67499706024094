import React from 'react';
import "./Dialog.less";

class Dialog extends React.Component {
    static popupInstance;
    static chooseOption = function() {};

    constructor(props) {
        super(props)
        Dialog.popupInstance = this;

        this.state = {
            open: false,
            title: "",
            description: "",
            descriptionType: "",
            options: [
                {label: "OK", className:"positiveConfirm"},
                {label: "Abbrechen", className:"cancel"},
            ],
            panelOptions: {className: null},
        }
    }

    static Alert(args) {
        if (Dialog.popupInstance == null) {
            console.error("Please put an instance of the dialog class into the render function of your Overlay Components.")
            return;
        }
        Dialog.popupInstance.setState({
            open: true,
            title: args.title ?? "",
            description: args.description ?? "Sind Sie sicher?",
            descriptionType: args.descriptionType ?? "",
            options: args.options ?? [
                {label: "OK", className:"positiveConfirm"},
                {label: "Abbrechen", className:""},
            ],
            panelOptions: args.panelOptions,
        })
        return new Promise((resolve, reject) => {
            let optionChosen = -1;
            new Promise((resolve) => { Dialog.chooseOption = resolve }).then((result) => {     
                optionChosen = result
            
                Dialog.popupInstance.setState({
                    open: false,
                })
                resolve(optionChosen)
            });
        })
    }

    render() {
        return (
            <div className={"dialogOverlay " + (this.state.open ? "open" : "")}>
                <div className={"panel "+ (this.state.panelOptions ? this.state.panelOptions.className : null)}>
                    <div className="fullWidth">
                            <h3>{this.state.title}</h3>
                        <div className="leftAligned">
                        {this.state.descriptionType === "htmlArray" ? this.state.description.map((desc, k) => {return(desc)}) : <p>{this.state.description}</p>}
                        </div>
                        <div className="buttonFlex">
                            {this.state.options.map((v, k) => {
                                return <button key={k} className={"dialogButton " + v.className} onClick={() => Dialog.chooseOption(k)}>
                                    {v.label}
                                </button>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default Dialog;