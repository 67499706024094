import React from 'react';
import StartScreen from '../../../base/components/StartScreen/StartScreen';
import ProfilePage from '../../../base/components/ProfilePage/ProfilePage';
import UserService from '../../../base/components/Keycloak/UserService';
import ModelConverterLogo from '../../../base/images/Vi_through_Logo.svg'
import MainPage from '../../../base/components/MainPage/MainPage';
import AccountIcon from '../../../base/images/account_icon.png';
import LogoutIcon from '../../../base/images/logout_icon.png';
import GermanIcon from '../../../base/images/DE_icon.png';
import EnglishIcon from '../../../base/images/EN_icon.png';
import TalsandLogo from '../../../base/images/TalsandLogo.png';
import LinkedInIcon from '../../../base/images/linkedin_icon.png';
import YouTubeIcon from '../../../base/images/youtube_icon.png';
import Dialog from '../../../base/components/Dialog/Dialog';
import TranslatedComponent, {Translate} from '../../../../src/base/components/Locale/TranslatedComponent';
import { TranslatedTexts } from '../../../base/components/Locale/TranslatedTexts';
import { HelperFunctions } from '../HelpferFunctions';
import { Uploader } from '../../../base/components/Uploader/Uploader';
import PrivacyInformation from '../../../base/components/Datenschutz/PrivacyInformation';
import LegalInformation from '../../../base/components/Impressum/LegalInformation';
import FAQ from '../../../base/components/faq/faq';
import { AdminHandler } from '../../../base/components/AdminHandler/AdminHandler';
import { Routes, Route, Link } from "react-router-dom";
import withRouter from '../../../with-router';

const RealmHash = "1ee5270c"; 
class OverlayComponents extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            username: null,
            userID: null,
            userInfo: null,
            companyID: null,
            isAdmin: false,
            modelLimit: 0,
            appAccess: false,
            coworkers: null,
            selectedFile: null,
            format: "gltf",
            itemID: "someid",            
            language: "de",
            profilePageVisible: false
        };
    }

    componentDidMount(){
        TranslatedComponent.ChangeLocale("de")
        this.initialize();        
    }

    initialize = () => {
      UserService.SessionData.init(RealmHash)
      .then((res) => {
          this.setState({username: UserService.SessionData.getUsername()});
      });
      
      UserService.AdminData.init(RealmHash);
    }

    getUsername = () => {
        // return this.state.userID ?? this.state.username;
    }

    setUserID = (userID) => {
        // this.setState({ userID: userID });
    }

    generateKeycloakError(err){
      let code = err.message.substring(err.message.length - 3, err.message.length);
      code = parseInt(code);
      let message = 'Keycloak-Fehler: "' + err.message + '"';
      if(code && typeof code === "number"){
          message = HelperFunctions.getKeycloakErrorMessage(code);
      }

      return message;
    }

    onLoginCallbacks = [];

    handleLogin = (username, password) => {
        const self = this;
        this.setState({ username: username });
        return new Promise((resolve, reject) =>
            UserService.SessionData.login(username, password)
                .then((res) => {
                    self.setState({ userID: UserService.SessionData.getUserID()});
                    self.updateCurrentUser();
                    
                    resolve(res);
                }).catch((err) => {
                    reject(err)
                })
        );
    }

    remUser(useridx)
    {
      const self = this;
      var username = this.state.coworkers[useridx].UserEmail;
      Dialog.Alert({
        title: TranslatedTexts.RemUser + " " + username,
        description: TranslatedTexts.RemUserSure,
        options: [
          {label: TranslatedTexts.Delete, className:"dangerConfirm"},
          {label: TranslatedTexts.Cancel, className:""},
        ],
        panelOptions: {className: "messebau"}
        }).then((optionChosen) => {          
          if (optionChosen == 0) {
            var userid = this.state.coworkers[useridx].UserGuid;
            AdminHandler.remUser("userid", userid)
            .then((res) => {
                var currCompanyID = UserService.SessionData.currentUser.attributes['CompanyID'][0];
                Uploader.getCoworkers(RealmHash, currCompanyID).then((res) =>{
                    self.setState({coworkers: res.data});
                })
            })
            .catch((err) =>
            {
            })     
          }
        });       
    }

    updateCurrentUser()
    {        
        //Update Keycloak User
        const self = this;
        UserService.AdminData.getCurrentUser().then((res) =>{            
            
            var currCompanyID = UserService.SessionData.currentUser.attributes['CompanyID'][0];
            var username = UserService.SessionData.currentUser.firstName; //+ " " + UserService.SessionData.currentUser.lastName != null ? UserService.SessionData.currentUser.lastName : ""
            var currUserInfo = [UserService.SessionData.currentUser.email, username, UserService.SessionData.currentUser.attributes['Company'][0]];
            var admin = (UserService.SessionData.currentUser.attributes['Admin'] != null);
            
            self.setState({companyID: currCompanyID, userInfo: currUserInfo, isAdmin: admin});
            //Update FileLimit
            Uploader.getFileLimit(RealmHash, currCompanyID).then((res) =>{                                        
                var newLimit = res.data['ModelLimit'];
                var newAccess = res.data['AppAccess'];
                self.setState({modelLimit: newLimit, appAccess: newAccess});
            })
            .catch((err) =>{

            });
            //Update Coworkers
            Uploader.getCoworkers(RealmHash, currCompanyID).then((res) =>{
                self.setState({coworkers: res.data});
            })
            .catch((err) =>{

            });
        })
        .catch((err) =>{

        });
    }

    handleLogout(){
      Dialog.Alert({
          title: "Log out",
          description: "Wollen Sie sich wirklich ausloggen?",
          options: [
              {label: "Ausloggen", className:"dangerConfirm"},
              {label: "Abbrechen", className:""},
          ],
          panelOptions: {className: "messebau"},
      }).then((optionChosen) => {
          if (optionChosen == 0) {            
            document.body.style.overflowX = "hidden"; document.body.style.overflowY = "auto";
              return new Promise((resolve,reject) =>{
                  UserService.SessionData.doLogout().then((res) => {
                      this.setState({username: null,profilePageVisible: false});
                      //location.reload(); //reload page to refresh server calls
                      resolve(res);
                  }).catch((err) => {
                      reject(err);
                  })
              })
          }
      })
    }

    handlePasswortReset()
    {
      Dialog.Alert({
        title: TranslatedTexts.ResetPassword,
        description: TranslatedTexts.ResetPasswordSure,
        options: [
            {label: TranslatedTexts.Reset, className:"dangerConfirm"},
            {label: TranslatedTexts.Cancel, className:""},
        ],
        panelOptions: {className: "messebau"},
    }).then((optionChosen) => {
        if (optionChosen == 0) {
            UserService.AdminData.sendMail(UserService.SessionData.getUserID(), "UPDATE_PASSWORD")
            .then((resp) => {
                Dialog.Alert({
                    title: TranslatedTexts.ResetPassword,
                    description: TranslatedTexts.ResetMailMessage,
                    options: [
                        {label: TranslatedTexts.OK, className:""},
                    ],
                    panelOptions: {className: "messebau oneButtonDialog"}
                })
            })
            .catch((err) => {
                let message = this.generateKeycloakError(err);
                Dialog.Alert({
                    title: "Fehler beim zurücksetzen des Passworts. Bitte laden Sie die Seite neu und versuchen es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.",
                    description: message,
                    options: [
                        {label: TranslatedTexts.OK, className:""},
                    ],
                    panelOptions: {className: "messebau oneButtonDialog"}
                })
            })
        }
    })
    }

    toggleLanguage = () =>{
        if(TranslatedComponent.locale === "en"){
            TranslatedComponent.ChangeLocale("de")
            this.setState({language: "de"});
        }else{
            TranslatedComponent.ChangeLocale("en")
            this.setState({language: "en"});
        }
    }
    
    toggleProfilePage()
    {
      this.setState({profilePageVisible: false});
    }

    render() {      
        var myself = (this.state.coworkers != null && this.state.userInfo != null && this.state.userInfo[0] != null) ? this.state.coworkers.find((w) => w.UserEmail == this.state.userInfo[0]) : null;
        var canEdit = myself != null ? myself.UserRole < 3 : false;
        var isCompanyAdmin = myself != null ? myself.UserRole < 2 : false;        
        return (          
            <div className='overlay'>
              
              <Dialog/>
                <div className='overlay_header'>
                    <Link to="/"><img alt='logo' src={ModelConverterLogo}/></Link>
                    <div className='overlay_header_buttons'>
                        <div className='menu_bar'>
                          <Link to={this.props.router.location.pathname == "/faq" ? "/" : "/faq"} style={{color: this.props.router.location.pathname == "/faq" ? "#000": "#fff"}}>FAQ</Link>
                        </div>
                      <button className='language_switch' onClick={() => this.toggleLanguage()} title={TranslatedTexts.Hover_LangSwitch}><img src={TranslatedComponent.locale == "en" ? EnglishIcon : GermanIcon}/></button>
                      { UserService.SessionData.currentUser != null && <div className='overlay_vr_line'/> }                                                
                      { UserService.SessionData.currentUser != null &&
                        <button onClick={() => this.setState({profilePageVisible: !this.state.profilePageVisible})} title={TranslatedTexts.Hover_Profile}><img className={this.state.profilePageVisible? "invertedimg" : ""} src={AccountIcon}/></button>
                      }
                      { UserService.SessionData.currentUser != null &&
                        <div className='overlay_vr_line'/>
                      }
                      { UserService.SessionData.currentUser != null &&
                        <button onClick={() => this.handleLogout()} title="Logout"><img src={LogoutIcon}/></button>                        
                      }
                    </div>
                </div>
              {/* {this.state.mode == "land" && 
              <LandingPage
                onLoginClick = {() => this.setState({mode: "login"})}
              />} */}
              { UserService.SessionData.currentUser != null &&
              <ProfilePage
                profilePageActive={this.state.profilePageVisible}                
                userInfo={this.state.userInfo}
                coworkers={this.state.coworkers}
                remUser={(idx) => this.remUser(idx)}
                closeProfilePage={() => this.toggleProfilePage()}
                handlePasswortReset={() => this.handlePasswortReset()}                
              />
                }
              <div className='overlay_body'>
                                          
                <Routes>
                 <Route index path="/"
                 element={
                  UserService.SessionData.currentUser == null ?
                  <StartScreen
                    initialMode="login"
                    
                    initialShow = {true}
                    onDiscard={() => {this.goToMain()}}
                    onLogin={(username, password) => this.handleLogin(username, password)}
                  />
                  :
                  <MainPage
                    realm = {RealmHash}
                    companyID = {this.state.companyID}
                    canEdit = {canEdit}
                    isCompanyAdmin= {canEdit}
                    fileSpaces = {this.state.modelLimit}
                    appAccess = {this.state.appAccess}
                    isAdmin= {this.state.isAdmin}
                  />
                 }/>
                 <Route path="/legal" 
                 element={
                  <LegalInformation 
                  />
                }/> 
                 <Route path="/privacy"
                 element={
                  <PrivacyInformation/>
                  }
                  />
                  <Route path="/faq"
                  element={<FAQ 
                    goToMain={() => this.props.router.navigate("/")}
                  />}
                  />
                </Routes>                           
              
              {
                this.state.mode == "legal" &&
                <LegalInformation/>
              }
              {
                this.state.mode == "privacy" &&
                <PrivacyInformation/>

              }
              </div>
              <div className='overlay_footer' id='Footer_Height'>
                <div className='overlay_hr_line'/>
                <div className='footer_elements'>
                    <div className='footer_element' id='Footer1'>
                        {TranslatedTexts.Product}
                        <a href="https://talsand.eu/" target="_blank"><img src={TalsandLogo}/></a>
                    </div>
                    <div className='footer_element' id='Footer2'>
                        <Link to="/legal">{TranslatedTexts.LegalInformation}</Link>
                        <Link to="/privacy">{TranslatedTexts.ProtectionOfPrivacy}</Link>
                        <Link to="/faq">{TranslatedTexts.FAQ}</Link>
                    </div>
                    <div className='footer_element' id='Footer22'>
                        <label style={{width: '56em'}}>{TranslatedTexts.Talsand_Adress}</label>
                        <label>{TranslatedTexts.Talsand_Contact} <a href="https://talsand.eu/" target="_blank"><label> | Web: talsand.eu</label></a> </label>
                        <div className='overlay_hr_line_impressum'></div>
                        <div className='sm_icons'>
                            <a href="https://www.linkedin.com/company/talsand/" target="_blank"><button title='LinkedIn' className='Footer3'><img src={LinkedInIcon}/></button></a>
                            <a href="https://youtube.com/@Vithrough_AR?si=phF8tmtSK6rq-aQ4" target="_blank"><button title='Youtube' className='Footer3'><img src={YouTubeIcon}/></button></a>
                        </div>
                    </div>
                </div>
              </div>
        </div>        
        );
    }
}

export default withRouter(OverlayComponents);
