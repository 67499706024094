import React from "react";
// import { hot } from "react-hot-loader";
import Cookies from "universal-cookie";
import TranslatedComponent from "../Locale/TranslatedComponent";
import "./cookieconsent";
// const classNames = require("classnames");

class CookieNotification extends React.Component {
    static cookieContext = new Cookies();

    static cookiesMap = {}

    static defaultProps = {
        autorun: true,
        autoclear_cookies: true,
        consent_modal: {
            en: {
                title: 'This site uses cookies',
                // description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only upon approval. <a aria-label="Cookie policy" class="cc-link" href="#">Read more</a>',
                description: 'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only upon approval. <button type="button" data-cc="c-settings" class="cc-link">Settings</button>',
                primary_btn: {
                    text: 'Accept all cookies',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Accept necessary cookies',
                    role: 'accept_necessary'                // 'settings' or 'accept_necessary'
                },
            },
            de: {
                title: 'Diese Seite verwendet Cookies',
                // description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only upon approval. <a aria-label="Cookie policy" class="cc-link" href="#">Read more</a>',
                description: 'Diese Webseite verwendet essenzielle Cookies, um problemfrei zu funktionieren, und Tracking-Cookies, um Interaktionen mit ihnen zu verstehen. Letzteres wird nur mit Ihrer Erlaubnis aktiviert. <button type="button" data-cc="c-settings" class="cc-link">Einstellungen</button>',
                primary_btn: {
                    text: 'Alle Cookies akzeptieren',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Nur essenzielle Cookies akzeptieren',
                    role: 'accept_necessary'                // 'settings' or 'accept_necessary'
                }
            }
        },
        settings_modal: {
            en: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',       // optional, [v.2.5.0 +]
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: "Cookie usage",
                        description: "This app uses cookies to ensure its basic functionalities and to enhance your online experience."
                    }   
                ]
            },
            de: {
                title: 'Cookie Einstellungen',
                save_settings_btn: 'Speichern',
                accept_all_btn: 'Alle akzeptieren',
                reject_all_btn: 'Alle verweigern',       // optional, [v.2.5.0 +]
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Ablaufzeit'},
                    {col4: 'Beschreibung'}
                ],
                blocks: [
                    {
                        title: "Cookie Nutzen",
                        description: "Diese App verwendet Cookies um zu funktionieren und um Ihr Erlebnis zu verbessern."
                    }   
                ]
            }
        },

        gui_options: {
            consent_modal: {
                layout: 'cloud',               // box/cloud/bar
                position: 'bottom center',     // bottom/middle/top + left/right/center
                transition: 'slide',           // zoom/slide
                swap_buttons: false            // enable to invert buttons
            },
            settings_modal: {
                layout: 'box',                 // box/bar
                // position: 'left',           // left/right
                transition: 'slide'            // zoom/slide
            }
        },
    }

    static onAccept() {
        if (CookieNotification.cookieConsent == null) {
            return
        }

        let keys = Object.keys(CookieNotification.cookiesMap)

        keys.forEach((cookieName) => {
            if (CookieNotification.cookieConsent.allowedCategory(CookieNotification.cookiesMap[cookieName])) {
                CookieNotification.cookieContext.set(cookieName, "", { path: "/", sameSite:"Lax" })
            }
        })
    }

    static config = {
        autorun: null,
        current_lang: null,
        autoclear_cookies: null,

        gui_options: null,

        onAccept: CookieNotification.onAccept,

        languages: {}
    }

    static cookieConsent = null

    static appendSettingIntoConfig(setting, rootObject, path, replace) {
        if (CookieNotification.config.languages == null) {
            CookieNotification.config.languages = {}
        }
        // The keys of the setting object should be language keys (en, de)
        let settingKeys = Object.keys(setting)
        settingKeys.forEach((k) => {
            let placeOfInsertion = CookieNotification.config[rootObject]
            // Build the chain to insert into using [rootObject][k][path]
            // But leaving the last key to maintain the reference
            let lastKey = k
            if (path.length > 0) {
                if (placeOfInsertion[k] == null) {
                    placeOfInsertion[k] = {}
                }
                placeOfInsertion = placeOfInsertion[k]
                lastKey = path[path.length - 1]
 
                for (let i = 0; i < path.length - 1; i++) {
                    if (placeOfInsertion[path[i]] == null) {
                        placeOfInsertion[path[i]] = {}
                    }
                    placeOfInsertion = placeOfInsertion[path[i]]
                }
            }
            if (!replace) {
                if (placeOfInsertion[lastKey] == null) {
                    placeOfInsertion[lastKey] = []
                }
                placeOfInsertion[lastKey].push(setting[k])
            } else {
                let blocks = null
                if (placeOfInsertion[lastKey] != null) {
                    blocks = placeOfInsertion[lastKey].blocks
                }
                placeOfInsertion[lastKey] = setting[k]
                if (blocks != null) {
                    placeOfInsertion[lastKey].blocks = blocks // Prevent overwriting of languages
                }
            }
        })
     }

    static makeCookie(name, site, duration, description, isRegex) {
        if (site == null) {
            site = document.location.host
        }
        return {
            col1: name,
            col2: site,
            col3: duration,
            col4: description,
            is_regex: isRegex
        }
    }

    // object with language keys and an entry for the "blocks" array mapped to each
    // https://github.com/orestbida/cookieconsent/blob/master/demo/app.js
    static registerCookieCategory(title, description, category, enabled, readonly, cookiesInfo){
        // CookieNotification.optionalCookies.push({
        //     name: name,
        //     description: description,
        //     id: id
        // })

        let categoryObject = {}

        for (let i = 0; i < cookiesInfo.length; i++) {
            let languages = Object.keys(cookiesInfo[i])

            languages.forEach((lang) => {
                if (categoryObject[lang] == null) {
                    categoryObject[lang] = {}
                    categoryObject[lang].title = title[lang]
                    categoryObject[lang].description = description[lang]
                    categoryObject[lang].toggle = {
                        value: category,
                        enabled: enabled,
                        readonly: readonly
                    }
                    categoryObject[lang].cookie_table = []
                }
                categoryObject[lang].cookie_table.push(cookiesInfo[i][lang])
                CookieNotification.cookiesMap[cookiesInfo[i][lang].col1] = category
            })
        }

        CookieNotification.appendSettingIntoConfig(categoryObject, "languages", ["settings_modal", "blocks"])

        if (CookieNotification.cookieConsent != null) {
            CookieNotification.cookieConsent.run(CookieNotification.config)
        }
    }

    constructor(props) {
        super(props);
        CookieNotification.cookieConsent = window.initCookieConsent();

        TranslatedComponent.translatableList.push(this)
        
        CookieNotification.config.autorun = props.autorun
        CookieNotification.config.autoclear_cookies = props.autoclear_cookies
        CookieNotification.config.gui_options = props.gui_options
        CookieNotification.config.current_lang = TranslatedComponent.locale
        if (CookieNotification.config.languages == null) {
            CookieNotification.config.languages = {}
        }
        CookieNotification.appendSettingIntoConfig(props.settings_modal, "languages", ["settings_modal"], true)
        CookieNotification.appendSettingIntoConfig(props.consent_modal, "languages", ["consent_modal"], true)

        CookieNotification.registerCookieCategory(
            {
                en: "Essential Cookies",
                de: "Notwendige Cookies"
            },
            {
                en: "These cookies are required to run this app.",
                de: "Diese App benötigt diese Cookies, um zu funktionieren.",
            },
            "essential",
            true,
            true,
            [
                {
                    en: CookieNotification.makeCookie("cookieSettings", null, "1 year", "Remembers this setting"),
                    de: CookieNotification.makeCookie("cookieSettings", null, "1 year", "Merkt diese Auswahl")
                },
                {
                    en: CookieNotification.makeCookie("_grecaptcha", "google.com", "Session", "Provies spam protection"),
                    de: CookieNotification.makeCookie("_grecaptcha", "google.com", "Session", "Verhindert spam"),
                }
            ]
        )

        // Iterate over the consent, settings and languages of registered components to build the languages object

        // if not accepted?
        CookieNotification.cookieConsent.run(CookieNotification.config)

        this.state = {
            accepted: false,
            locale: TranslatedComponent.locale,
            woocommerceIsActive: false,
        };
    }

    componentDidUpdate() {
        CookieNotification.cookieConsent.updateLanguage(this.state.locale)
    }

    componentDidMount() {
        this.setState({ accepted: CookieNotification.cookieContext.get("cookieSettings") });
    }

    toggleCookie = (cookieName) => {
        let state = Object.assign({}, this.state)
        state.optionalCookieSettings[cookieName] = state.optionalCookieSettings[cookieName] == true ? false : true //could be undefined
        this.setState(state);
    };

    acceptCookies = () => {
        const key = this.generateGUID();
        this.setState({ accepted: true }, () => { CookieNotification.cookieContext.set("cookieSettings", this.state.accepted, { path: "/", sameSite:"Lax" }) });
        // CookieNotification.cookieContext.set("cartKey", key, { path: "/" });

        let optionalCookieKeys = Object.keys(this.state.optionalCookieSettings)
        for (let i = 0; i < optionalCookieKeys.length; i++) {
            if (this.state.optionalCookieSettings[optionalCookieKeys[i]] == true) {
                CookieNotification.cookieContext.set(optionalCookieKeys[i], "", { path: "/", sameSite:"Lax" });
            }
        }
    };

    generateGUID = () => {
        return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (
            c
        ) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };

    render() {
        return (
            // <div
            //     className={classNames("cookie-notifications", {
            //         accepted: this.state.accepted,
            //     })}
            // >
            //     <div className="body">
            //         <div className="header"><TranslatedComponent translatable={[
            //                     ["de", "Diese Applikation verwendet Cookies"],
            //                     ["en", "This application uses cookies"]
            //                 ]} /></div>
            //         <div className="header-text">
            //             {" "}
            //             <TranslatedComponent translatable={[
            //                     ["de", "Wir speichern Cookies für folgende Nutzen:"],
            //                     ["en", "We use cookies for the following use cases:"]
            //                 ]} />
            //         </div>
            //         <div className="essential-cookies">
            //             <TranslatedComponent translatable={[
            //                     ["de", "Notwendige Cookies"],
            //                     ["en", "Necessary cookies"]
            //                 ]} />
            //             <div className="cookie-settings">
            //                 <button className="toggle">✓</button>
            //                 <div className="text"><TranslatedComponent translatable={[
            //                     ["de", "Cookie-Einstellungen"],
            //                     ["en", "Cookie settings"]
            //                 ]} /></div>
            //             </div>
            //             <div className="description"><TranslatedComponent translatable={[
            //                     ["de", "Speichert diese Auswahl"],
            //                     ["en", "Save this selection for future visits"]
            //                 ]} /></div>
            //             <div className="recaptcha-settings">
            //                 <button className="toggle">✓</button>
            //                 <div className="text">Login</div>
            //             </div>
            //             <div className="description"><TranslatedComponent translatable={[
            //                     ["de", "Speichert das von Google bereitgestellte Authentifizierungs-Captcha"],
            //                     ["en", "Saves the authentification captcha provided by Google"]
            //                 ]} /></div>
            //             {/* {this.props.woocommerceIsActive? <div className="woocommerce-cart">
            //                 <button className="toggle">✓</button>
            //                 <div className="text">Cart Key</div>
            //             </div> : null}
            //             {this.props.woocommerceIsActive? <div className="description">Saves a reference to the shopping cart</div> : null} */}
            //         </div>
            //         <div className="personal-cookies">
            //             <TranslatedComponent translatable={[
            //                     ["de", "Optionale Cookies"],
            //                     ["en", "Optional cookies"]
            //                 ]} />
            //             {CookieNotification.optionalCookies.map((cookie, idx) => {
            //                 return (<div key={idx}><div className="login-reminder">
            //                     <button
            //                         className="btn-secondary toggle"
            //                         onClick={() => this.toggleCookie.bind(this)(cookie.id)}
            //                     >
            //                         {this.state.optionalCookieSettings[cookie.id] == true ? "✓" : ""}
            //                     </button>
            //                     <div className="text">{cookie.name}</div>
            //                 </div>
            //                 <div className="description">{cookie.description}</div></div>)
            //             })}
            //         </div>
            //         <div className="footer">
            //             <button
            //                 className="btn-secondary confirm"
            //                 onClick={() => this.acceptCookies()}
            //             >
            //                 <TranslatedComponent translatable={[
            //                     ["de", "Akzeptieren"],
            //                     ["en", "Accept"]
            //                 ]} />
            //             </button>
            //         </div>
            //     </div>
            // </div>
            <div/>
        );
    }
}

export default CookieNotification;
