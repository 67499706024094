import React from "react";
import Dispatcher from '../../../api/Dispatcher';
import { TranslatedTexts, TranslatedTextsDE, TranslatedTextsEN } from "./TranslatedTexts";

class TranslatedComponent extends React.Component {
    static locale = "en";
    // TODO: Infer this via a unity response upon loading the definition within App.js
    static localeList = ["de", "en"];
    static languageNames = [
        "German",
        "English"
    ]

    static translatableList = [];

    static defaultProps = {
        fallback: null,
        // A list of string as first element and react component as second
        translatable: [],
    }

    static ChangeLocale(newLocale) {        
        if (newLocale !== TranslatedComponent.locale || TranslatedTexts.Talsand_Contact == "") {
            
            var keyNames = Object.keys(TranslatedTexts)
            keyNames.forEach(element => {
                if (newLocale == "de")
                {
                    TranslatedTexts[element] = TranslatedTextsDE[element];
                }
                else
                {
                    TranslatedTexts[element] = TranslatedTextsEN[element];
                }
            });    
            TranslatedComponent.locale = newLocale;
            for (let i = 0; i < this.translatableList.length; i++) {
                this.translatableList[i].setState({locale: newLocale});
            }
        }        
    }

    static ChangeLocaleWithoutNotify(newLocale) {
        if (newLocale !== TranslatedComponent.locale) {
            TranslatedComponent.locale = newLocale;
            for (let i = 0; i < this.translatableList.length; i++) {
                this.translatableList[i].setState({locale: newLocale});
            }
        }
    }

    static GetLanguage() {
        return TranslatedComponent.locale;
    }

    static Initialize() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('lang')) {
            let newLocale = urlParams.get('lang');
            TranslatedComponent.ChangeLocaleWithoutNotify(newLocale);
        }
        else
        {
            if (navigator.languages != undefined) 
            {
                if (navigator.languages[0].includes("de"))
                    TranslatedComponent.ChangeLocaleWithoutNotify("de");
            }                
            else if (navigator.language.includes("de"))
            {
                TranslatedComponent.ChangeLocaleWithoutNotify("de");
            }
        }

        Dispatcher.on('unity_language_changed', function (payload) {
            TranslatedComponent.ChangeLocaleWithoutNotify(payload.Locale);
        })
    }

    // TranslateLiteral called by itself will NOT re-trigger on state change.
    static TranslateLiteral(options, fallback) {
        let usedComponent = fallback ?? null

        if (options.length > 0) {
            if (fallback === null) {
                usedComponent = options[0][1];
            }
            for (let i = 0; i < options.length; i++) {
                if (options[i][0] === TranslatedComponent.locale) {
                    usedComponent = options[i][1];
                    break;
                }
            }
        }

        return usedComponent;
    }

    static TranslateLiteralFree(options, fallback){
        let translated = "";

        if (options.length > 0) {
            if (fallback === null) {
                translated = options[0][1];
            }
            for (let i = 0; i < options.length; i++) {
                if (options[i][0] === TranslatedComponent.locale) {
                    translated = options[i][1];
                    break;
                }
            }
        }

        return translated;
    }

    componentDidMount() {
        TranslatedComponent.translatableList.push(this);
    }

    componentWillUnmount() {
        TranslatedComponent.translatableList.splice(TranslatedComponent.translatableList.indexOf(this), 1);
    }

    constructor(props) {
        super(props);

        this.state = {
            // Although never really used, this variable serves to trigger state updates when the language is changed via ChangeLocale
            locale: TranslatedComponent.locale
        }

        // const self = this;
        // TODO: Add a way to react to unity changing the language
    }

    render() {
        return TranslatedComponent.TranslateLiteral(this.props.translatable, this.props.fallback ?? <div></div>)
    }
}

TranslatedComponent.Initialize();


export function Translate(translatable){
    return "No Text";
}

export default TranslatedComponent;