import React from 'react';
import './ProgressView.less'
import BinIcon from '../../images/bin_icon.png';
import { TranslatedTexts } from '../Locale/TranslatedTexts';

class ProgressView extends React.Component {

    static defaultProps = {
        files: [],
        deleteModel: function(){}
      };
    constructor(props) {
        super(props);

        this.state = {
          files: this.props.files,
        };
    }

    componentDidUpdate(prevProps, prevState){              
        if (prevProps.files.length != this.props.files.length) {
          this.state.files = this.props.files;          
          this.forceUpdate();
        }
        else
        {
          var equal = true;
          prevProps.files.forEach((e) => 
          {
            var finding = this.props.files.find((f) => e.ItemID == f.ItemID && e.State == f.State);
            if (finding == null)
            {
              equal = false;
            }
          })
          if (!equal)
          {
            this.state.files = this.props.files;
            this.forceUpdate();
          }
        }
      }
      
      removeJob(itemID)
      {
        this.props.deleteModel(itemID);
      }

      getStateDesciption(loadState){
        if(loadState < 0){
          return TranslatedTexts.LoadError;
        }else{
          switch(loadState){ 
            case 0:
              return TranslatedTexts.Uploading;
            case 1:          
              return TranslatedTexts.LoadUploadFinished;
            case 2:
              return TranslatedTexts.LoadQueued;
            case 3:
              return TranslatedTexts.LoadServerGetFiles;
            case 4:
              return TranslatedTexts.LoadConvert;
            case 5:
              return TranslatedTexts.LoadDeploy;
            default:
              return TranslatedTexts.LoadError;
          }
        }
      }

    render() {
        var canClose = this.props.files != null ? this.props.files.find((f) => f.State == 0) == null : false;
        return (
            <div className='ProgressViewMain'>
                <div className='ProgressViewHeader'>
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    <div className='ProgressHeaderInner'>
                        <p className='ProgressDescription'>{canClose ? TranslatedTexts.WindowCloseAllow : TranslatedTexts.WindowCloseWarning}</p>
                        <div className='WhiteLine'/>                        
                    </div>
                </div>    
                <div className='ProgressHeaderBody'>
                    { this.state.files != null && this.state.files.map((data, idx) =>
                    {
                        var description = this.getStateDesciption(data["State"])
                        var progress = data["State"]/6*100+'%';//data["Progress"] + "%";                        
                        return (
                        <div className='FileRow' key={idx+1}>
                        <div className='FileRowInner' title={data["FileName"]}>
                            <div className='Filename'>
                                <p>{data["FileName"]}</p>
                            </div>
                            <div className='FileProgress'>
                                <div className='Paragraph'>{description}</div>
                                <div className='ProgressBarOuter'>
                                    <div style={{width: progress}} className='ProgressBarInner'>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className='fileRemButton' onClick={(e)=> {this.removeJob(data["ItemID"]);}}><img src={BinIcon}/></button>
                    </div>);
                    })}
                    
                </div>            
            </div>
        );
    }
}

export default ProgressView;
