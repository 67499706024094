import React from 'react';
import LoginForm from '../LoginForm/LoginForm';
import HeaderGeometry from '../../images/header_geometry_borderless.png'
import SemiCircle from '../../images/semicircle_lighblue.png'
import {Translate} from '../Locale/TranslatedComponent';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import './StartScreen.less'

class StartScreen extends React.Component {
    static defaultProps = {
        initialMode: 'login',
        initialShow: true,
        logoWidth: '300px',
        logoSubTitle: null,
        buttonHeader: null,
        buttonText: 'Start the Experience',
        showExhibioLogo : true,
        showRegisterMessage: true,
        onDiscard: function(){},
    };

    hasStarted = false;

    constructor(props) {
        super(props);

        this.state = {
            show: props.initialShow,
            mode: props.initialMode,
            username: '',
            password: '',
        };

        this.loggedIn = false;

        if (props.register) {
            props.register(this);
        }
    }

    show = () => {
        this.setState({show: true, mode: this.props.initialMode})
    }

    hide = () => {
        this.setState({show: false})
    }

    start = (username) => {
        this.setState({ show: false });

        if (typeof this.props.onUsernameEntered === 'function') {
            this.props.onUsernameEntered(username);
        }

        if (!this.hasStarted) {
            if (typeof this.props.onStart === 'function') {
                this.props.onStart();
                this.hasStarted = true;
            }
        }
    };

    discard = () => {
        this.setState({ show: false });
        this.props.onDiscard();
    };

    render() {
        if (this.state.show) {
            return (
                <div style={this.props.style} className="start-screen" ref={this.userloginRef}>
                    <div className='header_image' src={HeaderGeometry}/>
                    <div className="container" id='welcome'>
                        <div className="container__body">
                        <h1>{TranslatedTexts.Welcome1}</h1>
                        <label className='container_text'>
                            {TranslatedTexts.Welcome2}                            
                            {TranslatedTexts.Welcome3}
                            <br/>
                            {TranslatedTexts.Welcome4}
                            </label>
                        </div>
                        {/* <div className="container__header">
                            {this.props.logo && <img src={this.props.logo} alt={this.props.logoAlt} width={this.props.logoWidth} />}
                            {!this.props.logo && <h1>{this.props.logoAlt}</h1>}
                            {this.props.logoSubTitle && <p>{this.props.logoSubTitle}</p>}
                        </div> */}

                        {/* {this.props.buttonHeader && <p className="subtitle">{this.props.buttonHeader}</p>} */}
                        <div className="container__body__background"/>
                        <div className="container__body">
                            <img src={SemiCircle} className='container__decor'/>
                            {/* {this.state.mode === 'enter' && <EnterForm onEnter={this.start} buttonText={this.props.buttonText} />} */}
                            {this.state.mode === 'login' && (
                                <LoginForm
                                    onStart={this.start}
                                    onDiscard={this.discard}
                                    onShowRegistration={() => {
                                        this.setState({ mode: 'registration' });
                                    }}
                                    buttonText={this.props.buttonText}
                                    onLogin={this.props.onLogin}
                                    showRegisterMessage = {false}
                                />
                            )}
                            {/* {this.state.mode === 'registration' && (
                                <RegistrationForm
                                    onStart={this.start}
                                    onShowLogin={() => {
                                        this.setState({ mode: 'login' });
                                    }}
                                    buttonText={this.props.buttonText}
                                    onRegister={this.props.onRegister}
                                />
                            )} */}
                        </div>

                        {/* {this.props.showExhibioLogo ? 
                        <div className="container__footer">
                            <p>Powered by</p> */}
                            {/* <img src={ExhibioLogo} alt="Exhib.io Logo" /> */}
                        {/* </div> : null} */}
                    </div>
                    <button title="close" className="close" style={{display: this.hasStarted ? "block" : "none", fontWeight: "normal", fontSize: "medium", marginTop: "15px"}} onClick={this.hide}>
                        {/* <img src={IconChevronLeft} alt="<" width="16" height="16" /> Cancel */}
                    </button>
                </div>
            );
        }

        return null;
    }
}

export default StartScreen;
