import React from 'react';
import axios from 'axios';
import ModelConverterLogo from '../../../base/images/Vi_through_Logo.svg'
import GermanIcon from '../../../base/images/DE_icon.png';
import EnglishIcon from '../../../base/images/EN_icon.png';
import TalsandLogo from '../../../base/images/TalsandLogo.png';
import LinkedInIcon from '../../../base/images/linkedin_icon.png';
import YouTubeIcon from '../../../base/images/youtube_icon.png';
import TranslatedComponent from '../Locale/TranslatedComponent';

import VithroughIcon from '../../../base/images/LandingPage/Vithrough_icon.png'
import Header from '../../../base/images/LandingPage/Header.jpg'
import Why from '../../../base/images/LandingPage/LinkedIn_warum.jpg'
import ThisIsAr from '../../../base/images/LandingPage/LinkedIn_was_ist_AR.jpg'
import ThisIsVithrough from '../../../base/images/LandingPage/LinkedIn_was_ist_Vithrough.jpg'
import HowToVithrough from '../../../base/images/LandingPage/LinkedIn_wie_benutze_ich_Vithrough.jpg'
import BenefitIcon1 from '../../../base/images/LandingPage/portfolio.png'
import BenefitIcon2 from '../../../base/images/LandingPage/Give Time.png'
import BenefitIcon3 from '../../../base/images/LandingPage/surface1.png'
import ArrowL from '../../../base/images/LandingPage/arrow-l.png'
import ArrowR from '../../../base/images/LandingPage/arrow-r.png'

import PrivacyInformation from '../../../base/components/Datenschutz/PrivacyInformation';
import LegalInformation from '../../../base/components/Impressum/LegalInformation';
import { TranslatedTexts } from '../../../base/components/Locale/TranslatedTexts';
import FAQ from '../../../base/components/faq/faq';
import { Routes, Route, Link } from "react-router-dom";
import withRouter from '../../../with-router';
import './LandingPage.less';

import ReactCSSTransitionGroup from 'react-transition-group'; // ES6

class LandingPage extends React.Component {
    static defaultProps = {
        show: false,
        onLoginClick: function(){},
    };

    hasStarted = false;

    constructor(props) {
        super(props);

        this.state = {
            imageId: 0,
            messageSend: false
        };
    }

    componentDidMount(){
        TranslatedComponent.ChangeLocale("de")        
        this.setState({imageId: 0});
    }

    toggleLanguage = () =>{
        if(TranslatedComponent.locale === "en"){
            TranslatedComponent.ChangeLocale("de")
            this.setState({language: "de"});
        }else{
            TranslatedComponent.ChangeLocale("en")
            this.setState({language: "en"});
        }
    }

    handleSubmit(e)
    {        
        const messageData = new FormData();
        messageData.append("message", e.target[0].value);
        messageData.append("Firstname", e.target[1].value);
        messageData.append("Surename", e.target[2].value);
        messageData.append("Tel", e.target[3].value);
        messageData.append("Email", e.target[4].value);
        const self = this;
        axios.post(
            window.config.host.api + "/landingpage/initrequest", messageData
            ).then((res) => {
                self.setState({messageSend: true})
            }).catch((e) => {
                console.error("failed to send", e)                
        })
    }
    
    currentLocation = "/";

    render() {
        if (this.currentLocation != this.props.router.location.pathname)
        {
          this.currentLocation = this.props.router.location.pathname;
          window.scrollTo(0, 0);
          document.body.style.overflowX = "hidden"; document.body.style.overflowY = "auto";
        }
        var currentImage;
        var SlideshowText;
        var SlideshowHeader;
        switch(this.state.imageId)
        {
            case 0:
                currentImage = ThisIsAr;
                SlideshowText = TranslatedTexts.WhatIsAr;
                SlideshowHeader = TranslatedTexts.WhatIsArHeader;
                break;
            case 1:
                currentImage = ThisIsVithrough;
                SlideshowText = TranslatedTexts.WhatIsVithrough;
                SlideshowHeader = TranslatedTexts.WhatIsVithroughHeader;
                break;
            case 2:
                currentImage = HowToVithrough;
                SlideshowText = TranslatedTexts.HowTothrough;
                SlideshowHeader = TranslatedTexts.HowTothroughHeader;
                break;
            case 3:
                currentImage = Why;
                SlideshowText = TranslatedTexts.WhyVithrough;
                SlideshowHeader = TranslatedTexts.WhyVithroughHeader;
            break;
            default:
                currentImage = ThisIsAr;
                SlideshowText = TranslatedTexts.WhatIsAr;
                SlideshowHeader = TranslatedTexts.WhyVithroughHeader;
        }        
        
        return (
            <div className='landing'>
                <div className='landing_header'>
                    <Link to="/"><img alt='logo' src={ModelConverterLogo}/></Link>
                    <div className='landing_header_buttons'>
                        <div className='menu_bar'>
                          <Link to={this.props.router.location.pathname == "/faq" ? "/" : "/faq"} style={{color: this.props.router.location.pathname == "/faq" ? "#000": "#fff"}}>FAQ</Link>
                        </div>                        
                      <button className='language_switch' onClick={() => this.toggleLanguage()} title={TranslatedTexts.Hover_LangSwitch}><img src={TranslatedComponent.locale == "en" ? EnglishIcon : GermanIcon}/></button>                      
                    </div>
                </div>

                <div id="content" className='landing_body'>
                <Routes>
                 <Route index path="/" element={
                    <div>
                    <div className='header_image' src={Header}>
                        <div className='header_button'>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <img src={VithroughIcon}/>
                            </div>
                            
                            <div style={{display: "flex", justifyContent: "center", height: "fit-content"}}>
                                <button onClick={() => window.open("https://vithrough.com/demo/", "_blank")}>{TranslatedTexts.Header}</button>
                            </div>
                        </div>
                    </div>
                    <div className='landing_hr_line'/>
                    <div className='landing_container'>
                        <div className='landing_subcontainer_1'>
                        <div className="login_header">
                                <span className="login_circle"></span>
                                <label className="login_title">{TranslatedTexts.AboutVithroughHeader}</label>                                
                            </div>
                            <p style={{margin: "2em 0"}}>{TranslatedTexts.AboutVithrough}</p>
                            
                        </div>
                        <div className='landing_subcontainer_2'>
                            <div className='landing_containercontent'>
                                <video className='video' src='https://vithrough.com/video/Markenlaunch.mp4' controls></video>
                            </div>
                        </div>
                    </div>
                    <div className='landing_hr_line'/>
                    <div className='landing_container'>
                        <div className='landing_subcontainer_3'>
                            <div key={this.state.imageId} className='landing_test_img'>
                                <img src={currentImage}/>
                            </div>
                        </div>
                        <div className='landing_subcontainer_4'>                                                        
                            <div key={this.state.imageId}  className='slideshow_outer'>
                                <div>
                                    <label className='slideshow_headline'>{SlideshowHeader}</label>
                                    <p className='slideshow_text'>{SlideshowText}</p>
                                </div>
                                <div className='buttons'>
                                    <button disabled={this.state.imageId<1} onClick={ () => this.setState({imageId: this.state.imageId-1})} className='arrowButton'><img src={ArrowL}></img></button>
                                    <button disabled={this.state.imageId==0} onClick={ () => this.setState({imageId: 0})} className='dott_button'></button>
                                    <button disabled={this.state.imageId==1} onClick={ () => this.setState({imageId: 1})} className='dott_button'></button>
                                    <button disabled={this.state.imageId==2} onClick={ () => this.setState({imageId: 2})} className='dott_button'></button>                                    
                                    <button disabled={this.state.imageId==3} onClick={ () => this.setState({imageId: 3})} className='dott_button'></button>
                                    <button disabled={this.state.imageId>2} onClick={ () => this.setState({imageId: this.state.imageId+1})} className='arrowButton'><img src={ArrowR}></img></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='landing_hr_line'/>
                    <div className='landing_container'>
                        <div className='landing_benefits'>
                        <div className="login_header">
                                <span className="login_circle"></span>
                                <label className="login_title">{TranslatedTexts.BenefitsHeader}</label>                                
                            </div>
                            <div className='benefits_container_right'>
                                <div className='benefits_container_icon_left'>
                                    <img src={BenefitIcon1} />
                                </div>
                                <div className='benefits_container_text_right'>
                                    <p>{TranslatedTexts.Benefits1}</p>
                                </div>                            
                            </div>                                                        
                            <div className='benefits_container_left'>
                                    
                                <div className='benefits_container_text_left'>
                                    <p>{TranslatedTexts.Benefits2}</p>
                                </div>   
                                <div className='benefits_container_icon_right'>
                                    <img src={BenefitIcon2} />
                                </div>
                            </div>                                                        
                            <div className='benefits_container_right'>
                            <div className='benefits_container_icon_left'>
                                    <img src={BenefitIcon3} />
                                </div>
                                <div className='benefits_container_text_right'>
                                    <p>{TranslatedTexts.Benefits3}</p>
                                </div>   
                            </div>                                                        
                            
                            </div>
                        
                    </div>
                    <div className='landing_hr_line'/>
                    <div className='landing_container'>
                        <div className='landing_contact'>
                            <div className='contact_form_outer'>
                            <form onSubmit={(e) => { e.preventDefault(); this.handleSubmit(e)}} id="contact-form" width="true">
                                <input name="emptyfield" style={{display: "none"}} id="emptyfield"></input>
                                <div className='contact_form_inner'>
                                    
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <label className='contact_form_inner_headline'>{this.state.messageSend ? TranslatedTexts.InterestHeaderSubmit : TranslatedTexts.InterestHeader}</label>
                                        <label>{this.state.messageSend ? TranslatedTexts.IntererstC2ASubmit : TranslatedTexts.IntererstC2A}</label>
                                    </div>
                                    { !this.state.messageSend && 
                                    <div className='input_wrapper'>
                                        <input required id="firstname" placeholder={TranslatedTexts.InterestName} name="firstname" type="text"></input>
                                        <input required id="lastname" placeholder={TranslatedTexts.InterestSurname} name="lastname" type="text"></input>
                                    </div>
                                    }
                                    { !this.state.messageSend && 
                                    <div className='input_wrapper'>
                                        <input required id="phone" pattern='^\+?\d{0,13}' placeholder={TranslatedTexts.InterestTel} name="phone" type="text"></input>
                                        <input required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" id="email" placeholder={TranslatedTexts.InterestMail} name="email" type="text"></input>
                                    </div>
                                    }
                                    { !this.state.messageSend && 
                                        <div className='consentWrapper'><input required id="consent" name="formTerms" type="checkbox"></input><label style={{textAlign: "left", fontSize: "0.9em", marginTop: "0.4em"}} htmlFor='consent'>{TranslatedTexts.InterestConsent}</label></div>
                                    }
                                    { !this.state.messageSend && 
                                        <button>{TranslatedTexts.InterestSubmit}</button>
                                    }                 
                                </div>
                            </form>
                            </div>
                            
                        </div>                        
                    </div>                    
                    </div>
                  }/>
                                    
                                   
                  <Route path="/faq"
                  element={<FAQ 
                    goToMain={() => this.props.router.navigate("/")}
                  />}
                  />
                  <Route path="/legal" 
                 element={
                  <LegalInformation 
                  />
                }/> 
                 <Route path="/privacy"
                 element={
                  <PrivacyInformation/>
                  }
                  />
                </Routes>
                    
                </div>

                <div className='landing_footer' id='Footer_Height'>
                <div className='landing_hr_line'/>
                <div className='footer_elements'>
                    <div className='footer_element' id='Footer1'>
                        {TranslatedTexts.Product}
                        <a href="https://talsand.eu/" target="_blank"><img src={TalsandLogo}/></a>
                    </div>
                    <div className='footer_element' id='Footer2'>
                        <Link to="/legal">{TranslatedTexts.LegalInformation}</Link>
                        <Link to="/privacy">{TranslatedTexts.ProtectionOfPrivacy}</Link>
                        <Link to="/faq">{TranslatedTexts.FAQ}</Link>
                    </div>
                    <div className='footer_element' id='Footer22'>
                        <label>{TranslatedTexts.Talsand_Adress}</label>
                        <label>{TranslatedTexts.Talsand_Contact} <a href="https://talsand.eu/" target="_blank"><label> | Web: talsand.eu</label></a> </label>
                        <div className='landing_hr_line_impressum'></div>
                        <div className='sm_icons'>
                            <a href="https://www.linkedin.com/company/talsand/" target="_blank"><button title='LinkedIn' className='Footer3'><img src={LinkedInIcon}/></button></a>
                            <a href="https://youtube.com/@Vithrough_AR?si=phF8tmtSK6rq-aQ4" target="_blank"><button title='Youtube' className='Footer3'><img src={YouTubeIcon}/></button></a>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        );
    }
}

export default withRouter(LandingPage);
