
import axios from "axios";

export class KeycloakTalsandAPI {
    static ResetPassword(email, realmHash) {
        const messageData = new FormData();
        messageData.append("UserMail", email);
        messageData.append("RealmHash", `${window.config.host.pre}_` + realmHash);

        return new Promise((resolve, reject) => {
            if(email == null || email === ""){
                reject();
            }

            axios.post(
                window.config.host.api + "/keycloak/postResetPassword", messageData
            ).then((res) => {
                if (res.data.Success) {
                    resolve()
                } else {
                    reject(res.data);
                    console.error(res.data)
                }
            }).catch((e) => {
                console.error("Failed to reset Password.", e)
                reject(e)
            })
        })
    }
}