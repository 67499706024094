import React from 'react';
import axios from 'axios';
import TranslatedComponent from '../Locale/TranslatedComponent';
import FileUploader from '../FilePicker/FilePicker';
import { Uploader } from '../Uploader/Uploader';
import DeleteIcon from '../../images/delete_icon.png';
import TutorialGraphic_DE from '../../images/Tutorial_DE.png';
import TutorialGraphic_EN from '../../images/Tutorial_EN.png';
import TutorialIcon from '../../images/tutorial_icon.png';
import QRDownloadIcon from '../../images/qrDownload_icon.png';
import QRShowIcon from '../../images/QRview_icon.png';
import CopyIcon from '../../images/copyLink_icon.png'
import CloseIcon from '../../images/close_icon.png';
import QRIcon from '../../images/qr-code_icon.png';
import BinIcon from '../../images/bin_icon.png'
import PlusIcon from '../../images/+_icon.png'
import ModelviewerPopup from '../ModelviewerPopup/ModelviewerPopup';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import {QRCodeSVG} from 'qrcode.react';
import Dialog from '../Dialog/Dialog';
import { AdminHandler } from '../AdminHandler/AdminHandler';
import './MainPage.less'
import Checkmark from '../../images/faq_check.png'
import InAppCheck from '../../images/InAppCheck.png'
import InAppCross from '../../images/InAppCross.png'

class MainPage extends React.Component {
    static defaultProps = {
      realm: null,
      companyID: null,
      fileSpaces: 5,
      appAccess: false,
      isAdmin: false,
      canEdit: false,
      isCompanyAdmin: false,
    };

    hasStarted = false;
    closeNoticeTimeout = null;

    constructor(props) {
        super(props);

        this.state = {
          dragOver: false,
          fileSpaces: this.props.fileSpaces,
          appAccess: this.props.appAccess,
          selectedFiles: [],
          userFilesData: [],
          modelsOverlayOpen: false,
          modelviewerModel: null,
          activeQrCode: null,
          users: [],
          companies: [],
          addingUser: false,
          addingCompany: false,
          tempCompanyName: "",
          tempCompanyLimit: 0,
          tempUserEmail: "",
          tempUserName: "",
          tempUserCompany: "",
          tempUserlocale: "de",
          tempUserRole: "Admin",
          editModelName: -1,
          editCompanyLimit: -1,          
          tempFileName: "",
          tempModelLimit: "",
          tutorialOpen: false,
          //debug
          debugItem: null, //if ItemID is set here, only the specified element will be shown in list
          noticeSustain: 1500,
          showNotice: false,
          showQRCodeOverlay: null,
        };
    }
    

    componentDidMount(){
      this.getUserFiles();
      this.getCompanies();      
    }

    SetEditModelname(idx, newname ="")
    {
      if (!this.props.canEdit) return;
      if (newname != "")
      {
        this.state.userFilesData[this.state.editModelName].Description = newname;        
        Uploader.updateJobDescription(this.state.userFilesData[this.state.editModelName].ItemID, newname);
      }
      var tempname = "";
      if (this.state.userFilesData && this.state.userFilesData[idx])
        tempname = this.state.userFilesData[idx].Description;
      this.setState({editModelName: idx, tempFileName: tempname});
    }

    SetEditCompanyLimit(idx, newLimit ="")
    {            
      if (newLimit != "")
      {        
        this.state.companies[this.state.editCompanyLimit].ModelLimit = newLimit;        
        AdminHandler.updateCompanyLimit(this.state.companies[this.state.editCompanyLimit].CompanyGuid, newLimit);
      }
      var templimit = "";      
      if (this.state.companies && this.state.companies[idx])
      {        
        templimit = this.state.companies[idx].ModelLimit;
      }        
      this.setState({editCompanyLimit: idx, tempModelLimit: templimit});
    }    

    componentDidUpdate(prevProps, prevState){
      if (prevProps.companyID !== this.props.companyID) {          
        this.getUserFiles();
        this.getCompanies();        
      }
      
      if (prevProps.fileSpaces !== this.props.fileSpaces || prevProps.appAccess !== this.props.appAccess)
      {
        var newlimit = this.props.fileSpaces;
        this.setState({fileSpaces: newlimit, appAccess: this.props.appAccess});
      }
    }

    getUserFiles(callback = function(){}){
      if (this.props.companyID == null) return;
      Uploader.getJobsForUser(this.props.realm, this.props.companyID)
      .then((resp) => {
        this.setState({userFilesData: resp.Jobs, tutorialOpen: !resp.Jobs || (resp.Jobs && resp.Jobs.length <= 2)})
        callback();
      })
      .catch((err) => {
        //NOTE:gets in here when no data for user was found as well
        console.error("Failed to get user files " + JSON.stringify(err))
        this.setState({userFilesData: [], tutorialOpen: true})
        callback();
      })
    }

    setCompany(companyID){
      //open new browser tab
      window.open(this.generateUserLink(companyID)).focus();      
    }

    setModel(data){      
      if (this.state.modelviewerModel != null) return;
      document.body.style.overflow = "hidden";
      this.setState({modelviewerModel: data, editModelName: -1, tempFileName: ""});      
    }

    getCompanies(callback = function(){})
    {
      if (!this.props.isAdmin) return;
      const self = this;
      AdminHandler.getAllCompanies("asd")
      .then((res) => {
        res.data.sort((a,b) => {
          return a.CompanyName > b.CompanyName ? 1 : -1;
        });        
        this.getUsers(res.data);
      })
      .catch((err) =>
      {

      })
    }

    generateModelLink(id){
      if(id){        
        if (window.config.host.cdn.includes("vithrough"))
          return "https://viewer.vithrough.com/?id=" + id;
        else
        return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?id=" + id;
      }else{
        return null
      }
    }

    getUsers(newCompanies, callback = function(){})
    {
      const self = this;      
      AdminHandler.getAllUsers("asd")
      .then((res) => {
        if (newCompanies)
        res.data.sort((a,b) => {
          if (a == null || b == null) return 0;
          var companyNameA = newCompanies.filter(company => company.CompanyGuid == a.Company)[0]?.CompanyName;
          var companyNameB = newCompanies.filter(company => company.CompanyGuid == b.Company)[0]?.CompanyName;
          if (companyNameA == companyNameB)
            return a.UserName > b.UserName ? 1 : -1;
          else
          {
            return companyNameA > companyNameB ? 1 : -1;
          }
        });        
        if (newCompanies != null)
          self.setState({users: res.data, companies: newCompanies});
        else
          self.setState({users: res.data});
      })
      .catch((err) =>
      {

      })
    }

    generateUserLink(id){
      if(id){
        if (window.config.host.cdn.includes("vithrough"))
          return "https://viewer.vithrough.com/?userID=" + id;
        else
        return window.config.host.cdn + "/" + this.props.realm + "/modelviewer/?userID=" + id;
      }else{
        return null
      }
    }

    editUser(userid)
    {

    }

    copyToClipboard(id, isCompany = false, generateLink = true){
      let text =  generateLink ? (isCompany ? this.generateUserLink(id) : this.generateModelLink(id)) : id;
      if(text){
        navigator.clipboard.writeText(text).then(() => {
          //this is called when text was successfully copied
          this.setState({showNotice: true})
          this.closeNoticeTimeout = setTimeout(() => {
            this.closeNoticeTimeout = null;
            this.setState({showNotice: false})
          }, this.state.noticeSustain);
        },() => {
          /* Rejected - text failed to copy to the clipboard */
        });
      }
    }

    downloadImage(filename){
      if (filename == null || filename == '') filename = 'qr-code.png';
      let size = 512;
      let canvasSize = 592;
      let svg = document.getElementById("qr_code_renderer");
      svg.setAttribute("width", size);
      svg.setAttribute("height", size);
      if(svg != null){
        var data = new XMLSerializer().serializeToString(svg);
        var win = window.URL || window.webkitURL || window;
        var img = new Image();
        var blob = new Blob([data], { type: 'image/svg+xml' });
        var url = win.createObjectURL(blob);
        img.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = canvasSize;
          canvas.height = canvasSize;
          var context = canvas.getContext("2d");
          context.fillStyle = "white";
          context.fillRect(0, 0, canvas.width, canvas.height);
          context.drawImage(img, (canvasSize - size) / 2, (canvasSize - size) / 2);
          win.revokeObjectURL(url);
          var uri = canvas.toDataURL('image/png').replace('image/png', 'octet/stream');
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = uri
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(uri);
          document.body.removeChild(a);
        };

        img.src = url;
      }
    }

    downloadCompanyQRCode(companyID){
      this.setState({activeQrCode: this.generateUserLink(companyID)}, () => this.downloadImage("models_qr.png"))
    }

    downloadModelQRCode(itemID, filename){
      this.setState({activeQrCode: this.generateModelLink(itemID)}, () => this.downloadImage(filename))
    }

    downloadLinkQRCode(link, filename){
      this.setState({activeQrCode: link}, () => this.downloadImage(filename))
    }

    updateActiveQRCode(link){
      this.setState({activeQrCode: link})
    }

    onUploadEnd(newFiles){
      this.getUserFiles(this.showNewFiles(newFiles))
    }

    deleteModel(itemID)
    {
      const self = this;
      Dialog.Alert({
        title: TranslatedTexts.Delete,
        description: "Wollen Sie die Datei wirklich löschen?",
        options: [
            {label: TranslatedTexts.Delete, className:"dangerConfirm"},
            {label: TranslatedTexts.Cancel, className:""},
        ],
        panelOptions: {className: "messebau"}
        }).then((optionChosen) => {        
          if (optionChosen == 0) {
      
            Uploader.remJob(itemID)
              .then((resp) => {
                self.getUserFiles();        
              })
              .catch((err) => {        
            })
          }
        });
    }

    UpdateUserRole(newVal, userMail)
    {
      var newUsers = this.state.users;
      newUsers.find((e) => e.UserEmail == userMail).UserRole = newVal;
      this.setState({users: newUsers});      
      AdminHandler.updateUserRole(newVal, userMail)
      .then((res) => {})
      .catch((err) =>{})
    }

    getUserTable()
    {
      var elements = [];
      this.state.users.map((elem, indx) => 
        {
          var company = this.state.companies.filter(company => company.CompanyGuid == elem.Company)[0]
          var companyName = "";
          if (company != null)
           companyName = this.state.companies.filter(company => company.CompanyGuid == elem.Company)[0].CompanyName;          
          elements.push(
            <tr className='TableRow' onClick={() => this.editUser(indx)} key={indx}>
                <td>{elem.UserName}</td>
                <td>{elem.UserEmail}</td>
                <td>{elem.UserLocale}</td>
                <td>{companyName}</td>
                <td><select value={elem.UserRole} onChange={(e) => this.UpdateUserRole(e.target.value, elem.UserEmail)} title='UserRole'>
                  <option value="1">Admin</option>
                  <option value="2">Editor</option>
                  <option value="3">User</option>
                  </select></td>
                <td><button className="armodelviewer_button" title={TranslatedTexts.RemUser} style={{fontSize: '0.8em', marginRight: '1em'}} onClick={() => this.remUser(indx)}><img src={BinIcon}/></button></td>                                                    
            </tr>)
          }
        );
        return elements;
    }

    getCompanyOptions()
    {
      var elements = [];
      this.state.companies.map((elem, indx) => 
        (          
          elements.push(
            <option>{elem.CompanyName}</option>)
        ));
        return elements;        
    }

    getCompanyTable()
    {
      var elements = [];
      this.state.companies.map((elem, indx) => 
        (          
          elements.push(
            <tr className='TableRow' onClick={() => this.editUser(indx)} key={indx}>
                <td>{elem.CompanyName}</td>

                { this.state.editCompanyLimit != indx && 
                  <td onClick={()=> this.SetEditCompanyLimit(indx)}><label >{elem.ModelLimit}</label></td>
                }
                {
                  this.state.editCompanyLimit == indx &&
                  <td>
                    <input 
                      type="text" 
                      autoFocus 
                      onKeyDown={(e) => {if(e.keyCode == 13)this.SetEditCompanyLimit(-1,this.state.tempModelLimit)}} 
                      onBlur={() => this.SetEditCompanyLimit(-1, this.state.tempModelLimit)}
                      value={this.state.tempModelLimit}
                      onChange={(e) => this.setState({tempModelLimit: e.target.value})}                      
                      />
                    </td>
                }
                <td><label className="switch">
                                            <input onChange={(e) => this.updateAppAccess(elem.CompanyGuid, e.target.checked)} checked={elem["AppAccess"]} type="checkbox"></input>
                                            <span className="slider round"></span>
                                        </label></td>
                <td><button title={TranslatedTexts.RemCompany} className="armodelviewer_button" style={{fontSize: '0.8em', marginRight: '1em'}} onClick={() => this.remCompany(elem.CompanyGuid)}><img src={BinIcon}/></button></td>
            </tr>)
        ));
        return elements;        
    }

    updateAppAccess(companyId, newAccess)  
    {
      var newCompanies = this.state.companies;
      newCompanies.find((e) => e.CompanyGuid == companyId)["AppAccess"] = newAccess;
      this.setState({companies: newCompanies});
      AdminHandler.updateCompanyAppAccess(companyId, newAccess)
      .then((res) =>
      {
        this.getCompanies();
      })
      .catch((err) =>
      {})      
    }

    addUser()
    {
      var companyId = this.getCompanyIdFromName(this.state.tempUserCompany)

      const self = this;
      AdminHandler.addUser(this.state.tempUserName, this.state.tempUserEmail, this.state.tempUserlocale, this.state.tempUserRole, companyId, this.state.tempUserCompany)
      .then((res) => {
        self.resetInputFields();
        self.getUsers(this.state.companies);
      })
      .catch((err) =>
      {

      })
    }

    resetInputFields()
    {
      this.setState({addingUser: false, addingCompany: false, tempCompanyName: "",tempCompanyLimit: 0, tempUserEmail: "", tempUserName: "", tempUserCompany: "",tempUserlocale: "de", tempUserRole: "Admin"});
    }

    getCompanyIdFromName(companyName)
    {
      var companyid = "";
      this.state.companies.forEach(company => {
        if (company.CompanyName == companyName)
        {          
          companyid = company.CompanyGuid;          
        }
      });
      return companyid;
    }

    remUser(useridx)
    {
      const self = this;
      var username = this.state.users[useridx].UserEmail;
      Dialog.Alert({
        title: TranslatedTexts.RemUser + " " + username,
        description: TranslatedTexts.RemUserSure,
        options: [
          {label: TranslatedTexts.Delete, className:"dangerConfirm"},
          {label: TranslatedTexts.Cancel, className:""},
        ],
        panelOptions: {className: "messebau"}
        }).then((optionChosen) => {
          var userid = this.state.users[useridx].UserGuid;
          if (optionChosen == 0) {
      
            AdminHandler.remUser("userid", userid)
            .then((res) => {              
              self.setState({addingUser: false});
              self.getUsers(this.state.companies);
            })
            .catch((err) =>
            {
              self.getUsers(this.state.companies);
            })     
          }
        });       
    }

    remCompany(guid)
    {
      const self = this;
      var companyName = this.state.companies.filter(company => company.CompanyGuid == guid)[0].CompanyName;
      Dialog.Alert({
        title: TranslatedTexts.RemCompany + " " + companyName,
        description: TranslatedTexts.RemCompanySure,
        options: [
            {label: TranslatedTexts.Delete, className:"dangerConfirm"},
            {label: TranslatedTexts.Cancel, className:""},
        ],
        panelOptions: {className: "messebau"}
        }).then((optionChosen) => {        
          if (optionChosen == 0) {
      
            AdminHandler.remCompany("userid", guid)
            .then((res) => {
              self.setState({addingCompany: false});
              self.getCompanies();
            })
            .catch((err) =>
            {

            })     
          }
        });       
    }

    addCompany()
    {
      const self = this;
      AdminHandler.addCompany("userid", this.state.tempCompanyName, this.state.tempCompanyLimit)
      .then((res) => {
        self.resetInputFields();
        self.getCompanies();
      })
      .catch((err) =>
      {

      })
      
    }

    setModelViewerLink(id, isCompany = false){
      this.setState({modelviewerUrl: isCompany ? this.generateUserLink(id) : this.generateModelLink(id)})
    }

    showNewFiles(newFiles){
      if(newFiles.length > 0){
        let file = newFiles[0]; //currently only showing the first one!!!
        if(file && file.ItemID){
          this.setModel(file)
        }
      }
    }

    updateInAppForModel(ItemID, newVal)
    {
      Uploader.updateJobInApp(ItemID, newVal)
      .then((res)=> 
      {
        this.getUserFiles();  
      })
      .catch((err)=>
      {
        
      })      
    }    
    updateInAppForUser(newVal)
    {
      Uploader.updateInAppForUser(this.props.companyID, newVal)
      .then((res)=> 
      {
        this.getUserFiles();  
      })
      .catch((err)=>
      {
        
      })      
    }    

    render() {
      const userFilesCount = this.state.userFilesData ? this.state.userFilesData.length : 0;
      const filesToRender = []
        for (let index = 0; index < this.state.fileSpaces; index++) {
          if(this.state.userFilesData && this.state.userFilesData[index]){
            filesToRender.push(this.state.userFilesData[index]);
          }else{
            filesToRender.push({});
          }
        }
      
       if(this.state.debugItem){
        filesToRender.length = 0;
        if(this.state.userFilesData){
          this.state.userFilesData.forEach(file => {
            if(file.ItemID && file.ItemID == this.state.debugItem){
              filesToRender.push(file);
            }
          });
        }
      }
      filesToRender.push({ItemID: -1}); //"do you need more space?" element
      var allFilesNotInApp = filesToRender.find((e) => e.InApp == true) == null;
      var allFilesInApp = filesToRender.find((e) => e.InApp == false) == null;
      var spaceLeft = this.state.fileSpaces - userFilesCount;
        return (
            <div className='main_page'>
              <QRCodeSVG className='qr_code_renderer' id='qr_code_renderer' value={this.state.activeQrCode}/>
              {this.state.showQRCodeOverlay && <div className='qr_code_overlay'>
                <div className='qr_code_container'>
                  <button className='qr_code_overlay_button' onClick={() => this.setState({showQRCodeOverlay: null})}><img src={CloseIcon}/></button>
                  <QRCodeSVG className='qr_code_overlay_code' value={this.state.showQRCodeOverlay}/>
                </div>
              </div>}
              {  this.state.showNotice && <div className='copied_notice'>
                <div className='copied_notice_left'><img src={CopyIcon}/></div>
                <div className='copied_notice_right'>{TranslatedTexts.LinkWasCopied}</div>
                </div>}
                <FileUploader
                  onUploadEnd = {(newFiles) => this.onUploadEnd(newFiles)}
                  realm={this.props.realm}
                  canEdit={this.props.canEdit}
                  companyID={this.props.companyID}
                  deleteModel={(itemID) => this.deleteModel(itemID)}
                  spaceLeft= {spaceLeft}
                  />

                   { this.props.isAdmin && 
                <div className='user_files_container'>
                  <div className='user_files_list_header'>
                    <div className='user_files_list_header_left'>
                      <div id='user_list_circle' className='user_list_circle'/>
                      <label id='Models'>{TranslatedTexts.Companies}</label>                      
                    </div>
                  </div>
                </div>
                }
                { this.props.isAdmin && 
                <div className='user_files_list_container'style={{backgroundColor: "rgba(255, 255, 255, 0.1)", width: "83%", padding: '1%'}}>
                    <div className='user_files_list'>
                      <table style={{color: "rgba(255, 255, 255, 0.87)", width: "80vw"}}>
                        <thead style={{textAlign: "left"}}>
                          <tr>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Name</th>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Limit</th>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>App</th>
                          <th>
                            { !this.state.addingCompany && <button className="armodelviewer_button" title={TranslatedTexts.AddCompany} style={{fontSize: '0.8em', marginRight: '1em'}} onClick={() => this.setState({addingCompany: true})}><img src={PlusIcon}></img></button>}
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                          { this.state.addingCompany &&
                          <tr>
                          <td>
                            <input 
                              type='text'
                              tempCompanyLimit
                              value={this.state.tempCompanyName}
                              onChange={(e) => this.setState({tempCompanyName: e.target.value})}>
                            </input>
                          </td>
                          <td>
                            <input 
                            type='text'
                            pattern="[1-9]{1}[0-9]{3}"
                            value={this.state.tempCompanyLimit}
                            onChange={(e) => this.setState({tempCompanyLimit: e.target.value})}>

                            </input>
                            </td>
                            <td><label className="switch">
                                            <input type="checkbox"></input>
                                            <span className="slider round"></span>
                                        </label></td>
                            <td>
                              <div style={{display: "flex", flexDirection: "row"}}>
                              <button onClick={() => this.addCompany()} className="armodelviewer_button" title={TranslatedTexts.AddCompany} style={{fontSize: '0.8em', marginRight: '1em'}}><img style={{height: "16px", width: "16px"}} src={Checkmark}/></button><button title={TranslatedTexts.Cancel} onClick={() => this.resetInputFields()} className="armodelviewer_button" style={{fontSize: '0.8em', marginRight: '1em'}}><img style={{height: "16px", width: "16px", transform: "rotate(45deg)"}} src={PlusIcon}/></button>
                              </div>
                            </td>
                          </tr>
                          }
                          {this.getCompanyTable()}
                        </tbody>
                      </table>
                    </div>
                  </div>
    }
                { this.props.isAdmin && 
                <div className='user_files_container'>
                  <div className='user_files_list_header'>
                    <div className='user_files_list_header_left'>
                      <div id='user_list_circle' className='user_list_circle'/>
                      <label id='Models'>{TranslatedTexts.Users}</label>                      
                    </div>
                  </div>
                </div>
    }
                { this.props.isAdmin && 
                <div className='user_files_list_container' style={{backgroundColor: "rgba(255, 255, 255, 0.1)", width: "83%", padding: '1%'}}>
                    <div className='user_files_list'>
                      <table style={{color: "rgba(255, 255, 255, 0.87)", width: "80vw"}}>
                        <thead style={{textAlign: "left"}}>
                          <tr>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Name</th>                            
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Email</th>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>{TranslatedTexts.Language}</th>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Company</th>
                            <th style={{color: "rgba(255, 255, 255, 0.5)"}}>Role</th>
                          <th>
                          { !this.state.addingUser && 
                          <button className="armodelviewer_button admin_buttons" title={TranslatedTexts.AddUser} style={{fontSize: '0.8em', marginRight: '1em'}} onClick={() => this.setState({addingUser: true})}><img src={PlusIcon}></img></button>}                          
                          </th>
                          </tr>
                        </thead>
                        <tbody>
                        { this.state.addingUser &&
                          <tr>
                          <td>
                            <input 
                              type='text'
                              value={this.state.tempUserName}
                              onChange={(e) => this.setState({tempUserName: e.target.value})}
                              >
                            </input>                                                                                    
                          </td>
                          <td>
                            <input 
                            type='text'                                                                                    
                            value={this.state.tempUserEmail}
                            onChange={(e) => this.setState({tempUserEmail: e.target.value})}
                            >                            
                            </input>
                            </td>
                            <td>
                              <select
                              value={this.state.tempUserlocale}
                              onChange={(e) => this.setState({tempUserlocale: e.target.value})}
                              >
                                <option>de</option>
                                <option>en</option>
                              </select>                                  
                            </td>                            
                            <td>
                              <select
                              value={this.state.tempUserCompany}
                              onChange={(e) => this.setState({tempUserCompany: e.target.value})}
                              >
                                <option></option>
                              {
                                this.getCompanyOptions()
                              }
                              </select>                                  
                            </td>
                            <td>
                              <select
                              value={this.state.tempUserRole}
                              onChange={(e) => this.setState({tempUserRole: e.target.value})}
                              >
                                <option value="1">Admin</option>
                                <option value="2">Editor</option>
                                <option value="3">User</option>
                              </select>                                  
                            </td>
                            <td>
                              <button title={TranslatedTexts.AddUser} onClick={() => this.addUser()} style={{fontSize: '0.8em', marginRight: '1em'}} className="armodelviewer_button"><img style={{height: "16px", width: "16px"}} src={Checkmark}></img></button><button title={TranslatedTexts.Cancel} onClick={() => this.resetInputFields()} style={{fontSize: '0.8em', marginRight: '1em'}} className="armodelviewer_button"><img  style={{height: "16px", width: "16px", transform: "rotate(45deg)"}} src={PlusIcon}></img></button>
                            </td>
                          </tr>
                          }
                          {this.getUserTable()}
                        </tbody>
                      </table>
                    </div>
                  </div>
              }
              
                <div className='tutorial_container'>
                    <button onClick={() => this.setState({tutorialOpen: !this.state.tutorialOpen})}><img style={{width: '3em', height: 'auto'}} src={TutorialIcon}/></button>
                    {this.state.tutorialOpen && <img src={TranslatedComponent.locale === "en" ? TutorialGraphic_EN : TutorialGraphic_DE}/>}
                </div>
                <div className='user_files_container'>
                  <div className='user_files_list_header'>
                  <div className='user_files_list_header_left'>
                    <div id='user_list_circle' className='user_list_circle'/>
                    <label id='Models'>{TranslatedTexts.YourModels}</label>
                  </div>
                  { this.state.appAccess == "1" && this.props.canEdit &&
                  <div className='user_files_list_header_right' id="user_files_list_header_center">
                    <button title={TranslatedTexts.AllModelsInAppHover} disabled={allFilesInApp} id='view_all' className='armodelviewer_button' onClick={() => this.updateInAppForUser(true)}>
                      <img src={InAppCheck}/>{TranslatedTexts.AllModelsInApp}</button>
                    <button title={TranslatedTexts.AllModelsOutAppHover} disabled={allFilesNotInApp} id='view_all' className='armodelviewer_button' onClick={() => this.updateInAppForUser(false)}>
                      <img src={InAppCross}/>{TranslatedTexts.AllModelsOutApp}</button>
                  </div>
                  }
                  <div className='user_files_list_header_right'>
                    <label id='Space'>{userFilesCount + "/" + this.state.fileSpaces + " " + TranslatedTexts.SpacesTaken}</label>
                    <button title={TranslatedTexts.ViewQRCode} id='view_all' className='armodelviewer_button' onClick={() => {this.setState({modelsOverlayOpen: !this.state.modelsOverlayOpen})}}>
                      <img src={QRIcon}/>{TranslatedTexts.AllModels}</button>
                    {this.state.modelsOverlayOpen && 
                      <div className="all_files_popup">
                        <label className='all_models_info'>{TranslatedTexts.AllModelsInfo}</label>
                        <button title={TranslatedTexts.Close} className='close_button' onClick={() => this.setState({modelsOverlayOpen: false})}><img src={CloseIcon}/></button>
                        <div className='all_models_container'>
                          <div className='qr_code_container'>
                            <QRCodeSVG className='qr_code' id='company_qr_code' value={this.generateUserLink(this.props.companyID)}/>
                          </div>
                          <div className='button_column'>
                            <button onClick={() => this.setCompany(this.props.companyID)} title={TranslatedTexts.OpenWebsite} className='armodelviewer_button'>{TranslatedTexts.OpenWebsite}</button>
                            <button onClick={()=>this.downloadCompanyQRCode(this.props.companyID)} title={TranslatedTexts.DownloadImage} className='armodelviewer_button'>{TranslatedTexts.DownloadImage}</button>
                          </div>
                        </div>                        
                      </div>}
                  </div>
                  </div>
                  <div className='user_files_list_container'>
                    <div className='user_files_list'>
                      {userFilesCount > 0 ? 
                      filesToRender.map((data, idx) => {
                          if(data.ItemID){
                            if(data.ItemID != -1){
                              return (
                                <div className='user_file_element'  key={"UploadedFileData-" + idx}>
                                  <div className='user_file_element_section'>
                                    {
                                      this.state.editModelName != idx &&
                                      <label onClick={() => this.SetEditModelname(idx)}>{data.Description}</label>
                                    }
                                    {
                                      this.state.editModelName == idx &&                                      
                                        <input 
                                        type="text" 
                                        autoFocus 
                                        onKeyDown={(e) => {if(e.keyCode == 13)this.SetEditModelname(-1,this.state.tempFileName)}} 
                                        onBlur={() => this.SetEditModelname(-1, this.state.tempFileName)}
                                        value={this.state.tempFileName}
                                        onChange={(e) => this.setState({tempFileName: e.target.value})}
                                        >                                        
                                        </input>                                      
                                    }
                                    
                                    <label className='small_text'>{data.FileName + data.FileType + " (" + (new Date(data.Updated) == null ? "" : new Date(data.Updated).toLocaleDateString("de-DE")) + ")"}</label>
                                      <div className='user_file_element_row'>
                                        <div className='user_file_element_row_element'>
                                         <label>{TranslatedTexts.ModelLink}</label>
                                          <button style={{padding: "0.3em 0.3em 0.1em 0.3em"}} title={TranslatedTexts.ModelLinkTitle} onClick={() => this.copyToClipboard(data.ItemID)}><img src={CopyIcon}></img></button>
                                        </div>
                                        <div className='user_file_element_row_element'>
                                          <label>{TranslatedTexts.QRCode}</label>
                                          <button style={{padding: "0.3em 0.3em 0.1em 0.3em"}} title={TranslatedTexts.ViewQRCode} onClick={() => this.setState({showQRCodeOverlay: this.generateModelLink(data.ItemID)})}><img src={QRShowIcon}></img></button>
                                          <button style={{padding: "0.3em 0.3em 0.1em 0.3em"}} title={TranslatedTexts.Download} onClick={() => this.downloadModelQRCode(data.ItemID, data.Description+"_qr.png")}><img src={QRDownloadIcon}></img></button>
                                       </div>
                                    </div>
                                  </div>
                                  <div className='user_file_element_section_right'>
                                    <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: (this.state.appAccess == "1" && this.props.canEdit)?"space-between":"flex-end"}}>
                                      {
                                        this.state.appAccess == "1" && this.props.canEdit &&
                                      
                                        <div style={{display: "flex", flexDirection: "row"}}><p>In App:  </p><label style={{display: "flex", margin: "3px 0 0 5px"}} className="switch">
                                            <input checked={data.InApp} onChange={(e) => this.updateInAppForModel(data.ItemID,e.target.checked)} type="checkbox"></input>
                                            <span className="slider round"></span>
                                        </label></div>
                                        }
                                      <button className='section_delete' disabled={!this.props.canEdit} title={TranslatedTexts.Delete} onClick={() => this.deleteModel(data.ItemID)}><img src={DeleteIcon}></img></button>
                                    </div>
                                    <button title={TranslatedTexts.OpenFile} onClick={() => this.setModel(data)} className='armodelviewer_button armodelviewer_button_fileopen'>{TranslatedTexts.OpenFile}</button>
                                  </div>
                                </div>
                              )
                            }else{
                              return(
                                filesToRender.length - userFilesCount < 3 &&
                                <div className='user_file_element_getMoreSpace'  key={"UploadedFileData-" + idx}>
                                  <label id='more_space'>{TranslatedTexts.MoreSpace}</label>
                                </div>
                              )
                            }
                          }else{
                            return( 
                              userFilesCount + 2 > idx &&
                              <div className='user_file_element_empty'  key={"UploadedFileData-" + idx}>
                                <label>{TranslatedTexts.EmptySlot}</label>
                              </div>
                            )
                          }
                      })
                      :<label>{TranslatedTexts.NoModel}</label>}
                    </div>
                  </div>
              </div>
              
              <ModelviewerPopup 
                modelviewerModel={this.state.modelviewerModel} 
                canEdit={this.props.canEdit}
                onClose={() => {this.setState({ modelviewerModel: null}); document.body.style.overflowX = "hidden"; document.body.style.overflowY = "auto";}} 
                onModelUpdate={() => this.getUserFiles()}
                downloadQRCode={(link,filename) => this.downloadLinkQRCode(link,filename)} 
                copyLink={(id) => this.copyToClipboard(id, false, false)}
                realm={this.props.realm}/>
          </div>
        );
    }
}

export default MainPage;
