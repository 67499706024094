import React from 'react';
import './LoginForm.less'
import { TranslatedTexts } from '../Locale/TranslatedTexts';
import Dialog from '../Dialog/Dialog';
import {KeycloakTalsandAPI} from '../Keycloak/KeycloakTalsandAPI';
import { HelperFunctions } from '../../../configurations/armodelviewer/HelpferFunctions';

class LoginForm extends React.Component {
    
    reg = null;
    
    static defaultProps = {
        buttonText: 'Start the Experience',
        canRegister: true,
        onShowRegistration: function(){},
        onDiscard: function(){},
        restrictInputWithRegEx: null,
        Realmhash: "1ee5270c",
    };

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            wantsToReset: false,
            failedCheck: false,
            failedRegExCheck: [],
            invalidFieldStyle: {border: "5px dotted red"},
            resetmail: ""
        };

        if(this.props.restrictInputWithRegEx != null){
            this.reg = new RegExp(this.props.restrictInputWithRegEx)
        }

    }

    handleChange(event, parameter) {
        let temp = this.state.failedRegExCheck;
        if(this.props.restrictInputWithRegEx != null){
            let index = this.state.failedRegExCheck.indexOf(parameter);
            let text = event.target.value;
            if(this.reg.test(text) || text == ""){
                if(index !== -1){
                    temp.splice(index, 1);
                }
            }else{
                if(index === -1){
                    temp.push(parameter);
                }
            }
            this.setState({failedRegExCheck: temp});
        }
        this.updateInput(event, parameter);
    }


    updateInput(event, parameter){
        event.persist();
        this.setState({
            [parameter]: event.target.value,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.loggedIn && !this.state.wantsToReset) {
            this.loggedIn = true;
            const username = this.state.username;
            const password = this.state.password;            
            this.props.onLogin(username, password).then((res) => {                
                this.props.onStart(username);                
            }).catch((err) => {
                this.setState({failedCheck: true})
                this.loggedIn = false
                console.log(err)
            });
        }
        else if (!this.loggedIn)
        {
            Dialog.Alert({
                title: TranslatedTexts.ResetPassword,
                description: TranslatedTexts.ResetPasswordSure,
                options: [
                    {label: TranslatedTexts.Reset, className:"dangerConfirm"},
                    {label: TranslatedTexts.Cancel, className:""},
                ],
                panelOptions: {className: "messebau"},
            }).then((optionChosen) => {
                if (optionChosen == 0) {                    
                        KeycloakTalsandAPI.ResetPassword(this.state.resetmail, this.props.Realmhash)
                        .then(() => {
                            this.setState({wantsToReset: false, resetmail: ""});
                            Dialog.Alert({
                                title: TranslatedTexts.ResetPassword,
                                description: TranslatedTexts.ResetMailMessage,
                                options: [
                                    {label: TranslatedTexts.OK, className:""},
                                ],
                                panelOptions: {className: "messebau oneButtonDialog"},
                            })
                        }).catch((err) => {
                            Dialog.Alert({
                                title: TranslatedTexts.SomethingWrong,
                                description: err.Code === 404 ? TranslatedTexts.EmailNotFound : HelperFunctions.getHTTPStatusCodeErrorMessage(err.Code),
                                options: [
                                    {label: "OK", className:""},
                                    ],
                                panelOptions: {className: "messebau oneButtonDialog"},
                        })
                    })
                }
            })
        }
    }

    close(event, self) {
        /*
        event.preventDefault();
        this.props.onDiscard();
        self.setState({ show: false });*/
    }

    render() {
        return (
            <form className="login" onSubmit={(e) => { e.preventDefault(); this.handleSubmit(e)}}>
                <div className="login_form_wrapper">
                    <div className="login_header">
                        <span className="login_circle"></span>
                        <label className="login_title">{ this.state.wantsToReset ? TranslatedTexts.ResetPassword : TranslatedTexts.Login}</label>
                    </div>
                    {
                        !this.state.wantsToReset &&
                        <input
                        style = {this.state.failedRegExCheck.indexOf("username") !== -1 ? this.state.invalidFieldStyle : null}
                        type="text"
                        id="username"
                        name="username"
                        required
                        placeholder= {TranslatedTexts.Username}
                        value={this.state.username}
                        onChange={(e) => this.handleChange(e, 'username')}
                        />
                        
                    }
                    {
                        !this.state.wantsToReset &&
                        <input
                        style = {this.state.failedRegExCheck.indexOf("password") !== -1 ? this.state.invalidFieldStyle : null}
                        type="password"
                        id="password"
                        name="password"
                        required
                        placeholder= {TranslatedTexts.Password}
                        value={this.state.password}
                        onChange={(e) => this.handleChange(e, 'password')}
                    />
                    }
                    {
                        this.state.wantsToReset &&
                        <input
                        style = {this.state.failedRegExCheck.indexOf("username") !== -1 ? this.state.invalidFieldStyle : null}
                        type="text"
                        id="resetmail"
                        name="resetmail"
                        required
                        placeholder= {"EMail"}
                        value={this.state.resetmail}
                        onChange={(e) => this.handleChange(e, 'resetmail')}
                        />
                        
                    }                    
                    <a href='#' onClick={() => this.setState({failedCheck: false, wantsToReset: !this.state.wantsToReset})}>{ this.state.wantsToReset ? TranslatedTexts.BackToLogin :  TranslatedTexts.ForgotPw}</a>
                    <div style={{display: this.state.failedCheck || this.state.failedRegExCheck.length !== 0 ? 'block' : 'none'}}>
                        <div style={{foreground: "#ff0000ff"}}>
                            {this.state.failedCheck ?  TranslatedTexts.LoginFailed : null}
                            {this.props.restrictInputWithRegEx != null && this.state.failedCheck ?  <br/> : null}
                            {this.state.failedRegExCheck.length !== 0 ? "Illegal character spotted in one of the fields." : null}
                        </div>
                    </div>
                    <button className='login-button armodelviewer_button' type="submit" >
                        { this.state.wantsToReset ? TranslatedTexts.ResetPassword : TranslatedTexts.Login}
                    </button>
                    <div className="hr_line"></div>
                    
                        <label>{TranslatedTexts.NotRegistered}</label><a href="mailto:contact@vithrough.com">contact@vithrough.com</a>
                    {
                        /*
                        
                        <button className='login-button armodelviewer_button' type="submit" >
                                {TranslatedTexts.Register}
                        </button>
                        */
                    }
                    
                </div>
            </form>
        );
    }
}

export default LoginForm;
