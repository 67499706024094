import axios from "axios";

export class HelperFunctions {
    static getKeycloakErrorMessage(errorCode){
        if(errorCode){
            let errorMessage = "";
            switch(errorCode){
                case 400:
                    errorMessage = "Die angegebenen Werte können nicht verarbeitet werden. Bitte überprüfen Sie Ihre Angaben und versuchen es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei";
                    break;
                case 401:
                    errorMessage = "Der Nutzer kan nicht authorisiert werden. Bitte loggen Sie sich erneut ein und versuchen es dann nochmal. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                    break;
                case 403: 
                    errorMessage = "Der aktuelle Nutzer hat nicht die Berechtigung zur Anwendung dieser Funktion.";
                    break;
                case 404:
                    errorMessage = "Die angefragte Ressource existiert nicht.";
                    break;
                case 405:
                    errorMessage = "Die angefragte Ressource kann nicht abgerufen werden, da die Art des Zugriffes nicht unterstützt wird. Bitte kontaktieren Sie die zuständige Partei.";
                    break;
                case 409:
                    errorMessage = "Eines oder mehrere der übergebenen Werte existieren bereits. Bitte ändern Sie Ihre Angaben und versuchen es erneut.";
                    break;
                case 415:
                    errorMessage = "Der angeforderte Medien-Typ wird nicht unterstützt. Bitte kontaktieren Sie die zuständige Partei.";
                    break;
                case 500:
                    errorMessage = "Die Anfrage konnte aufgrund eines Fehlers auf Seiten des Servers nicht erfüllt werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                    break;
                default:
                    errorMessage = "Die Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es erneut. Falls das Problem weiterhin besteht, kontaktieren Sie die zuständige Partei.";
                    break;
            }
            
            return errorMessage + " Fehler-Code: " + errorCode;
        }

        return "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
    }

    static getHTTPStatusCodeErrorMessage(errorCode){
        if(errorCode){
            let errorMessage = "";
            if(errorCode >= 400 && errorCode < 500){
                switch(errorCode){
                    case 400: 
                        errorMessage = "Fehlerhafter Aufbau der Anfrage an den Server. Bestimmte Werte konnten nicht an den Server übermittelt werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                    case 401, 407: 
                        errorMessage = "Die Authentifizierung der Anfrage ist schief gelaufen. Bitte versuchen Sie sich erneut einzuloggen. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                    case 402:
                        errorMessage = "Eine Bezahlung für die angeforderte Aktion wird benötigt.";
                        break;
                    case 403: 
                        errorMessage = "Der aktuelle Nutzer ist nicht dazu berechtigt, diese Aktion auszuführen. Bitte versuchen Sie sich erneut einzuloggen. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                    case 404:
                        errorMessage = "Die angeforderte Ressource konnte nicht gefunden werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                    case 406:
                        errorMessage = "Die angeforderte Ressource steht nicht in der gewünschten Form zur Verfügung. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                    case 408:
                        errorMessage = "Die Anfrage konnte nicht vom Server bearbeitet werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                    case 410: 
                        errorMessage = "Die angeforderte Ressource wird nicht länger bereitgestellt. Sie wurde dauerhaft entfernt. Bitte kontaktieren Sie die zuständige Partei.";
                        break;
                    case 429:
                        errorMessage = "Es wurden zu viele Anfragen an den Server gesendet. Bitte warten Sie kurz und versuchen es dann erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte die zuständige Partei.";
                        break;
                    default: 
                        errorMessage = "Ein Fehler auf Seiten der lokalen Anwendung ist aufgetreten. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                }
            }else if(errorCode >= 500 && errorCode < 600){
                switch(errorCode){
                    case 500: 
                        errorMessage = "Ein unerwarteter Serverfehler ist aufgetreten. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                    case 501:
                        errorMessage = "Die Funktionalität, die zur Bearbeitung der Anfrage nötig ist wird nicht vom Server bereitgestellt. Bitte kontaktieren Sie die zuständige Partei.";
                        break;
                    case 502, 504:
                        errorMessage = "Der Server hat eine ungültige Antwort erhalten und kann diese nicht weiterleiten. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                    case 503:
                        errorMessage = "Der Server steht temporär nicht zur Verfügung. Bitte achten Sie auf mögliche Wartungsarbeiten-Ankündigungen des Anbieters und versuchen es später erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                    case 505:
                        errorMessage = "Die genutzte HTTP Version wird nicht vom Server unterstützt. Bitte kontaktieren Sie die zuständige Partei.";
                        break;
                    case 507:
                        errorMessage = "Die Anfrage konnte nicht bearbeitet werden, weil der Speicherplatz des Servers dazu derzeit nicht mehr ausreicht. Bitte kontaktieren Sie die zuständige Partei.";
                        break;
                    case 511:
                        errorMessage = "Der Nutzer konnte nicht authentifiziert werden. Bitte gehen Sie sicher, dass die Authentifizierung des Nutzers bereits erfolgt ist. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                    default: 
                        errorMessage = "Ein Fehler auf Seiten des Servers ist aufgetreten. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
                        break;
                }
            }
            
            return errorMessage + " Fehler-Code: " + errorCode;
        }
        
        return "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie die zuständige Partei.";
    }
}